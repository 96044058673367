import {
  Tabs,
  Table,
  Tag,
  Button,
  Card,
  Col,
  Row,
  Typography,
  Input,
  Select,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { v4 } from "uuid";
import LoadingComponent from "../components/Loading";
import {
  numberOfSpecimenConfirmed,
  numberOfSpecimensTested,
  numberOfSpecimentReceived,
  numberOfSpecimentReferred,
  overallData,
} from "../controllers/dashboard_admin";
import { getOrders } from "../controllers/orders.";
import { Pie, Column as BarColumn } from "@ant-design/plots";
import { FilterOutlined } from "@ant-design/icons";
import { filterByDate } from "../utils/filter_by_dte";
import moment from "moment";

const { Column } = Table;

const convertToMMHHNew = (secs) => {
  if (!secs) {
    return "N/A";
  }
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

const Dashboard = () => {
  const onChange = (value, dateString) => {
    //console.log(dateString[0] === "");
    if (dateString[0] === "" || dateString[1] === "") {
      setFromDate(null);
      setToDate(null);
      return;
    }
    setFromDate(new Date(dateString[0]));
    setToDate(new Date(dateString[1]));
  };

  const onOk = (value) => {
    //console.log("onOk: ", value);
  };

  const [dateFilter, setDateFilter] = useState("ALL");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadedOrders, setLoadedOrders] = useState(false);

  const [specimensReferred, setSpecimensReferred] = useState();
  const [specimensConfirmed, setSpecimensConfirmed] = useState();
  const [specimensReceived, setSpecimensReceived] = useState();
  const [testedSpecimens, setTestedSpecimens] = useState();
  const [orders, setOrders] = useState();

  const [overallDataa, setOverAllData] = useState();
  const [orderMonitoringData, setOrderMonitoringData] = useState([]);
  const [filterdData, setFilteredData] = useState([]);

  const getPatientDataFromOrder = (order) => {
    let data = [];
    order.forEach((p) => {
      //   console.log(p["status"]);
      let ps = (p && p["patients"]) || [];
      ps.forEach((s) => {
        let ss = (s && s["specimens"]) || [];
        //getting the order information order level
        data = [...data, {...p, ...s, order_status : p['status']}]

        //getting the order information specimen level
        // ss.forEach((spmen) => {
        //   data = [...data, { ...p, ...s, ...spmen, order_status: p["status"] }];
        // });
      });
    });
    const mapped = data.map((o) => {
      return {
        ...o,
        order_no: o["key"],
      };
    });
    mapped.forEach((m) => {
      m["key"] = v4();
    });

    return mapped;
  };

  useEffect(() => {
    loadOrders();
    loadSpecimenReferred();
    loadSpecimenReceived();
    loadSpecimenConfirmed();
    loadSpecimensTested();
  }, []);

  const ordersForCSV = (orders) => {
    return orders.map((order) => {
      return {
        "Order ID": order.key,
        "Sender Name": order.sender_name || "NA",
        "Courier Name": order.courier_name || "NA",
        "Tester Name": order.tester_name || "NA",
        // "Zone": order.zone || 'NA',
        // 'Woreda': order.woreda || 'NA',
        Patients: (order && order.patients && order.patients.length) || 0,
        "Order Crateds":
          order.order_created && dateFromString(order.order_created),
        Status: order.status,
        Region: order.region && order.region.name,
        Zone: order.zone && order.zone.name,
      };
    });
  };

  const patientInfoForCSV = (data) => {
    return data.map((order) => {
      return {
        "Order ID": order.key,
        "Sender Name": order.sender_name || "NA",
        "Courier Name": order.courier_name || "NA",
        "Tester Name": order.tester_name || "NA",
        "Order Created":
          order.order_created && dateFromString(order.order_created),
        Name: order.name,
        MR: order.MR,
        Sex: order.sex,
        Age: order.age,
        "Age in Months": order.age_months,
        Phone: order.phone,
        Zone: order.zone_name,
        Woreda: order.woreda_name,
        type: order.type,
        "Anatomic Location": order.anatomic_location,
        "Requested Test": order.requested_test,
        "Reason for Test": order.reason_for_test,
        "Registration Group": order.registration_group,
        "Order Status": order.order_status,
        Region: order.region && order.region.name,
        // 'Zone': order.zone && order.zone.name,
      };
    });
  };

  const shipmentReportCSV = (data) => {
    return data.map((val) => {
      return {
        "Order ID": val.key,
        "Sender Name": val.sender_name,
        "Courier Name": val.courier_name,
        "Tester Name": val.tester_name,
        Patients: (val.patients && val.patients.length) || 0,
        "Order Crated":
          (val.order_created && dateFromString(val.order_created)) || "NA",
        "Order Accepted":
          (val.order_confirmed && dateFromString(val.order_confirmed)) || "NA",
        Region: val.region && val.region.name,
        Zone: val.zone && val.zone.name,
      };
    });
  };

  const loadOrders = async () => {
    setLoadingOrders(true);
    let os = await getOrders();
    let ps = [];
    const mapped = os.map((o) => {
      return {
        ...o,
        order_no: o["key"],
      };
    });
    mapped.forEach((m) => {
      m["key"] = v4();
    });
    //console.log("mapped=========", mapped);
    setOrders(mapped);
    setOrderMonitoringData(getPatientDataFromOrder(os));
    os.forEach((val) => {
      ps = [...ps, ...(val["patients"] || [])];
    });


    setLoadedOrders(true);
    setLoadingOrders(false);

    let data = [];
    os.forEach((p) => {
      let ps = (p && p["patients"]) || [];
      ps.forEach((s) => {
        let ss = (s && s["specimens"]) || [];
        ss.forEach((spmen) => {
          data = [...data, { ...p, ...s, ...spmen, order_status: p["status"] }];
        });
      });
      
    });
    // console.log(ps);
    setOverAllData(overallData(data, ps,os));

  };

  const loadSpecimenReferred = async () => {
    let sr = await numberOfSpecimentReferred();
    setSpecimensReferred(sr);
  };

  const loadSpecimenConfirmed = async () => {
    let sr = await numberOfSpecimenConfirmed();
    setSpecimensConfirmed(sr);
  };

  const loadSpecimenReceived = async () => {
    let sr = await numberOfSpecimentReceived();
    setSpecimensReceived(sr);
  };

  const loadSpecimensTested = async () => {
    let sr = await numberOfSpecimensTested();
    setTestedSpecimens(sr);
  };

  const getOrderLevelData = (orders) =>{
    var purifiedOrders = [];
    var keyContainer = {};

    for (const obj of orders) {
       const key = obj.order_no;
       if(!keyContainer[key]){
         purifiedOrders.push(obj);
         keyContainer[key] = true;
       }
    }
   
    return purifiedOrders;
  }

  const TableComponent = ({ overalldata }) => {
    const columns = [
      {
        title: "Hour",
        dataIndex: "hour",
        width: "20%",
      },
      {
        title: "Stool",
        dataIndex: "stool",
        width: "20%",
      },
      {
        title: "Sputum",
        dataIndex: "sputum",
        width: "20%",
      },
      {
        title: "Urine",
        dataIndex: "urine",
        width: "20%",
      },

      {
        title: "Blood",
        dataIndex: "blood",
        width: "20%",
      },
    ];

    const data = [
      {
        key: "1",
        hour: "0-6",
        stool: overalldata.zeroToSix["Stool"],
        sputum: overalldata.zeroToSix["Sputum"],
        urine: overalldata.zeroToSix["Urine"],
        blood: overalldata.zeroToSix["Blood"],
      },
      {
        key: "2",
        hour: "6-12",
        stool: overalldata.sixToTwelve["Stool"],
        sputum: overalldata.sixToTwelve["Sputum"],
        urine: overalldata.sixToTwelve["Urine"],
        blood: overalldata.sixToTwelve["Blood"],
      },
      {
        key: "3",
        hour: "12-24",
        stool: overalldata.twelveToTwentyFour["Stool"],
        sputum: overalldata.twelveToTwentyFour["Sputum"],
        urine: overalldata.twelveToTwentyFour["Urine"],
        blood: overalldata.twelveToTwentyFour["Blood"],
      },
      {
        key: "4",
        hour: "24-48",
        stool: overalldata.twentyFourToFourtyEight["Stool"],
        sputum: overalldata.twentyFourToFourtyEight["Sputum"],
        urine: overalldata.twentyFourToFourtyEight["Urine"],
        blood: overalldata.twentyFourToFourtyEight["Blood"],
      },
      {
        key: "5",
        hour: ">48",
        stool: overalldata.aboveFourtyEight["Stool"],
        sputum: overalldata.aboveFourtyEight["Sputum"],
        urine: overalldata.aboveFourtyEight["Urine"],
        blood: overalldata.aboveFourtyEight["Blood"],
      },
    ];

    return <Table columns={columns} dataSource={data} style={{marginRight : 10}}/>;
  };

  const BarChartComponent = ({ overalldata }) => {
    
    const data = [
      {
        name: "<= 15",
        x: "Sputum.",
        y: overalldata.sputumUnder15 || 0,
      },
      {
        name: "<= 15",
        x: "Stool.",
        y: overalldata.stoolUnder15 || 0,
      },
      {
        name: "<= 15",
        x: "Urine.",
        y: overalldata.urineUnder15 || 0,
      },
      {
        name: "<= 15",
        x: "Blood.",
        y: overalldata.bloodUnder15 || 0,
      },
      {
        name: "> 15",
        x: "Sputum.",
        y: overalldata.sputumAbove15 || 0,
      },
      {
        name: "> 15",
        x: "Stool.",
        y: overalldata.stoolAbove15 || 0,
      },
      {
        name: "> 15",
        x: "Urine.",
        y: overalldata.urineAbove15 || 0,
      },
      {
        name: "> 15",
        x: "Blood.",
        y: overalldata.bloodAbove15 || 0,
      },
    ];
    const config = {
      data,
      isGroup: true,
      xField: "x",
      yField: "y",
      seriesField: "name",
      label: {
        position: "middle",

        layout: [
          {
            type: "interval-adjust-position",
          },
          {
            type: "interval-hide-overlap",
          },
          {
            type: "adjust-color",
          },
        ],
      },
    };
    return (
      <BarColumn
        style={{ padding: 10, margin: 10, height: 200, width: "80%" }}
        {...config}
      />
    );
  };

  const ChartComponent = ({ overalldata }) => {
    // console.log(overalldata)

    const mtbData = [
      {
        type: "MTB Detected",
        value:
          overalldata.rifResDetected +
          overalldata.rifiResNotDetected +
          overalldata.rifResIndeterminate,
      },
      {
        type: "MTB Not Detected",
        value: overalldata.mtbNotDetected,
      },
    ];

    const rifResData = [
      {
        type: "Rif Res Detected",
        value: overalldata.rifResDetected,
      },
      {
        type: "Rif Res Not Detected",
        value: overalldata.rifiResNotDetected,
      },
      {
        type: "Rif Res Indeterminate",
        value: overalldata.rifResIndeterminate,
      },
    ];

    const genderSpecimenData = [
      {
        type: "Male Sputum",
        value: overalldata.femaleSputum,
      },
      {
        type: "Female Sputum",
        value: overalldata.maleSputum,
      },
      {
        type: "Male Stool",
        value: overalldata.maleStool,
      },
      {
        type: "Female Stool",
        value: overalldata.femaleStool,
      },
    ];

    const rifResConfig = {
      appendPadding: 10,
      data: rifResData,
      angleField: "value",
      colorField: "type",
      radius: 0.8,
      label: {
        type: "outer",
        content: "{name} {percentage}",
      },
      interactions: [
        {
          type: "pie-legend-active",
        },
        {
          type: "element-active",
        },
      ],
    };

    const mtbConfig = {
      appendPadding: 10,
      data: mtbData,
      angleField: "value",
      colorField: "type", 
      radius: 0.8,
      label: {
        type: "outer",
        content: "{name} {percentage}",
      },
      interactions: [
        {
          type: "pie-legend-active",
        },
        {
          type: "element-active",
        },
      ],
    };

    const genderSpecimenConfig = {
      appendPadding: 10,
      data: genderSpecimenData,
      angleField: "value",
      colorField: "type",
      radius: 0.8,
      label: {
        type: "outer",
        content: "{name} {percentage}",
      },
      interactions: [
        {
          type: "pie-legend-active",
        },
        {
          type: "element-active",
        },
      ],
    };
    return (
      <>
        <Row justify="space-around" align="middle">
          <Col style={{ margin: 2 }}>
            <Pie {...rifResConfig} />
          </Col>
          <Col style={{ margin: 2 }}>
            <Pie {...genderSpecimenConfig} />
          </Col>
          <Col style={{ margin: 2 }}>
            <Pie {...mtbConfig} />
          </Col>

          <Col style={{ width: 500 }}>
            <BarChartComponent overalldata={overalldata} />
          </Col>
        </Row>

        {/* <BarChartComponent overalldata={overalldata} /> */}
      </>
    );
  };

  const dateFromString = (date) => {
    // return date.toDate().toLocaleDateString();
    return new Date(date).toLocaleDateString();
  };

  useEffect(() => {
    const fd = filterByDate(orderMonitoringData, dateFilter, fromDate, toDate);
    // console.log(fd);
    setFilteredData(fd);
  }, [orderMonitoringData, dateFilter, fromDate, toDate]);

  return (
    <>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Overall Status" key="1">
          <>
            {loadingOrders && <LoadingComponent />}
            {loadedOrders && !loadingOrders && (
              <>
                {/* <Button type="primary" style={{ marginLeft: 20, marginBottom: 10 }}>
                                    Print Status
                                </Button> */}

                <Row justify="space-around">
                  <Col style={{ margin: 3, width: 230 }}>
                    <Card
                      title="Specimens Referred"
                      bordered={true}
                      style={{ textAlign: "center" }}
                    >
                      <Typography
                        style={{
                          fontSize: 35,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {specimensReferred}
                      </Typography>
                    </Card>
                  </Col>
                  <Col style={{ margin: 3, width: 230 }}>
                    <Card
                      title="Specimens Picked Up"
                      bordered={false}
                      style={{ textAlign: "center" }}
                    >
                      <Typography
                        style={{
                          fontSize: 35,
                          textAlign: "center",
                          fontWeight: "bold",
                          wordWrap: "break-word",
                        }}
                      >
                        {specimensConfirmed}
                      </Typography>
                    </Card>
                  </Col>
                  <Col style={{ margin: 3, width: 230 }}>
                    <Card
                      title="Specimens Received"
                      bordered={false}
                      style={{ textAlign: "center" }}
                    >
                      <Typography
                        style={{
                          fontSize: 35,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {specimensReceived}
                      </Typography>
                    </Card>
                  </Col>

                  {overallDataa && (
                    <Col style={{ margin: 3, width: 230 }}>
                      <Card
                        title="Tested Specimens"
                        bordered={false}
                        style={{ textAlign: "center" }}
                      >
                        <Typography
                          style={{
                            fontSize: 35,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {testedSpecimens}
                        </Typography>
                      </Card>
                    </Col>
                  )}
                </Row>
              </>
            )}

            {loadedOrders && !loadingOrders && overallDataa && (
              <>
                <ChartComponent overalldata={overallDataa} />
                <TableComponent overalldata={overallDataa} />
              </>
            )}
          </>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Order Monitoring" key="2">
          <>
            {loadingOrders && <LoadingComponent />}
            {loadedOrders && !loadingOrders && (
              <>
                <Button type="primary" style={{ marginBottom: 20 }}>
                  <CSVLink
                    filename={"Order Monitoring.csv"}
                    data={ordersForCSV(orders)}
                    className="btn btn-primary"
                    onClick={() => {
                      // console.log("The file is downloading")
                    }}
                  >
                    <Typography style={{ color: "white" }}>
                      Export to CSV
                    </Typography>
                  </CSVLink>
                </Button>

                <Select
                  value={dateFilter}
                  style={{ width: 200, marginLeft: 20 }}
                  onChange={(val) => {
                    if (val !== "CUSTOME_DATE") {
                      setFromDate(null);
                      setToDate(null);
                    }
                    setDateFilter(val);
                  }}
                >
                  <Select.Option value="ALL">All</Select.Option>
                  <Select.Option value="TODAY">Today</Select.Option>
                  <Select.Option value="THIS_WEEK">This Week</Select.Option>
                  <Select.Option value="THIS_MONTH">This Month</Select.Option>
                  <Select.Option value="THIS_YEAR">This Year</Select.Option>
                  <Select.Option value="CUSTOM_DATE">Custom Date</Select.Option>
                </Select>

                {dateFilter === "CUSTOM_DATE" && (
                  <DatePicker.RangePicker
                    value={
                      fromDate && toDate
                        ? [moment(fromDate), moment(toDate)]
                        : null
                    }
                    onChange={onChange}
                    onOk={onOk}
                    style={{ marginLeft: 10 }}
                  />
                )}

                <Table
                  scroll={{ x: 1400 }}
                  style={{marginRight : 10}}
                  width={"80%"}
                  dataSource={getOrderLevelData(filterdData)}
                >
                  <Column
                    title="Order No"
                    dataIndex="order_no"
                    key={v4()}
                    render={(val, record, index) => {
                      // console.log(record)
                      return <Typography>{val}</Typography>;
                    }}
                  />

                  <Column
                    key={v4()}
                    title="Referring Health Facility"
                    dataIndex="sender_name"
                    // key="sender_name"
                    render={(val) => <Typography>{val}</Typography>}
                  />

                  <Column
                    key={v4()}
                    title="Courier Name"
                    dataIndex="courier_name"
                    // key="courier_name"
                    render={(val) => <Typography>{val}</Typography>}
                  />

                  <Column
                    key={v4()}
                    title="Testing Health Facility"
                    dataIndex="tester_name"
                    // key="tester_name"
                    render={(val) => <Typography>{val}</Typography>}
                  />

                  {/* <Column
                    key={v4()}
                    filterDropdown={({
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                    }) => {
                      return (
                        <>
                          <Row style={{ padding: 5 }}>
                            <Col>
                              <Input
                                style={{ margin: 3 }}
                                autoFocus
                                placeholder="Type region name here..."
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  );
                                }}
                                onPressEnter={() => {
                                  confirm();
                                }}
                                onBlur={() => {
                                  confirm();
                                }}
                              ></Input>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{ margin: 3 }}
                                onClick={() => {
                                  confirm();
                                }}
                              >
                                Search
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="primary"
                                danger
                                style={{ margin: 3 }}
                                onClick={() => {
                                  clearFilters();
                                  confirm();
                                }}
                              >
                                Clear
                              </Button>
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                    filterIcon={<FilterOutlined />}
                    onFilter={(value, record) => {
                      // console.log(record);
                      return record.region != null
                        ? record.region.name
                          .toLowerCase()
                          .includes(value.toLocaleLowerCase())
                        : false;
                    }}
                    title="Region"
                    dataIndex="region"
                    // key="region"
                    render={(val) => (
                      <Typography>{(val && val.name) || ""}</Typography>
                    )}
                  />
                  <Column
                    key={v4()}
                    filterDropdown={({
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                    }) => {
                      return (
                        <>
                          <Row style={{ padding: 5 }}>
                            <Col>
                              <Input
                                style={{ margin: 3 }}
                                autoFocus
                                placeholder="Type region name here..."
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  );
                                }}
                                onPressEnter={() => {
                                  confirm();
                                }}
                                onBlur={() => {
                                  confirm();
                                }}
                              ></Input>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{ margin: 3 }}
                                onClick={() => {
                                  confirm();
                                }}
                              >
                                Search
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="primary"
                                danger
                                style={{ margin: 3 }}
                                onClick={() => {
                                  clearFilters();
                                  confirm();
                                }}
                              >
                                Clear
                              </Button>
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                    filterIcon={<FilterOutlined />}
                    onFilter={(value, record) => {
                      // console.log(record)
                      return record.zone_name != null
                        ? record.zone_name
                          .toLowerCase()
                          .includes(value.toLocaleLowerCase())
                        : false;
                    }}
                    title="Zone"
                    dataIndex="zone_name"
                  // key="zone_name"
                  />

                  <Column
                    key={v4()}
                    filterDropdown={({
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                    }) => {
                      return (
                        <>
                          <Row style={{ padding: 5 }}>
                            <Col>
                              <Input
                                style={{ margin: 3 }}
                                autoFocus
                                placeholder="Type woreda name here..."
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  );
                                }}
                                onPressEnter={() => {
                                  confirm();
                                }}
                                onBlur={() => {
                                  confirm();
                                }}
                              ></Input>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{ margin: 3 }}
                                onClick={() => {
                                  confirm();
                                }}
                              >
                                Search
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="primary"
                                danger
                                style={{ margin: 3 }}
                                onClick={() => {
                                  clearFilters();
                                  confirm();
                                }}
                              >
                                Clear
                              </Button>
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                    filterIcon={<FilterOutlined />}
                    onFilter={(value, record) => {
                      // console.log(record)
                      return record.woreda_name != null
                        ? record.woreda_name
                          .toLowerCase()
                          .includes(value.toLocaleLowerCase())
                        : false;
                    }}
                    title="Woreda"
                    dataIndex="woreda_name"
                  // key="woreda_name"
                  /> */}

                  <Column
                    key={v4()}
                    title="Number of Patients"
                    dataIndex="patients"
                    // key="patients"
                    render={(val) => (
                      <Typography>{(val && val.length) || 0}</Typography>
                    )}
                  />

                  <Column
                    key={v4()}
                    title="Order Created"
                    dataIndex={['order_created']['seconds']}
                    sorter= {(a, b) => ((a === undefined || a == null || b === undefined || b === null) ? 1 :  a.order_created.seconds - b.order_created.seconds)}
                    defaultSortOrder={'descend'}
                    render={(_,order) => {  
                      let date = moment.unix(order.order_created.seconds).format("MMMM DD, YYYY hh:mm");
                      return (
                        <Typography>
                          {order ? date : "NA"}
                        </Typography>
                      );
                    }}
                  />

                  <Column
                    key={v4()}
                    title="Order Status"
                    dataIndex="order_status"
                    // key="order_status"
                    render={(delivery_status) => (
                      <>
                        <Tag
                          color={
                            delivery_status === "Delivered" ||
                              delivery_status === "Accepted"
                              ? "green"
                              : delivery_status === "Ongoing"
                                ? "yellow"
                                : "red"
                          }
                        // key={delivery_status}
                        >
                          <Typography> {delivery_status}</Typography>
                        </Tag>
                      </>
                    )}
                  />
                </Table>
              </>
            )}
          </>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Specimen Referral Report" key="3">
          <>
            {loadingOrders && <LoadingComponent />}
            {loadedOrders && !loadingOrders && (
              <>
                <Button type="primary" style={{ marginBottom: 20 }}>
                  <CSVLink
                    filename={"Specimen Referral Report.csv"}
                    data={patientInfoForCSV(orderMonitoringData)}
                    className="btn btn-primary"
                    onClick={() => {
                      // console.log("The file is downloading")
                    }}
                  >
                    Export to CSV
                  </CSVLink>
                </Button>

                <Select
                  value={dateFilter}
                  style={{ width: 200, marginLeft: 20 }}
                  onChange={(val) => {
                    setDateFilter(val);
                  }}
                >
                  <Select.Option value="ALL">All</Select.Option>
                  <Select.Option value="TODAY">Today</Select.Option>
                  <Select.Option value="THIS_WEEK">This Week</Select.Option>
                  <Select.Option value="THIS_MONTH">This Month</Select.Option>
                  <Select.Option value="THIS_YEAR">This Year</Select.Option>
                  <Select.Option value="CUSTOM_DATE">Custom Date</Select.Option>
                </Select>

                {dateFilter === "CUSTOM_DATE" && (
                  <DatePicker.RangePicker
                    value={
                      fromDate && toDate
                        ? [moment(fromDate), moment(toDate)]
                        : null
                    }
                    onChange={onChange}
                    onOk={onOk}
                    style={{ marginLeft: 10 }}
                  />
                )}

                <Table scroll={{ x: 4500 }} dataSource={filterdData} style={{marginRight : 10, overflow : 'scroll'}}>
                  <Column title="Order ID" dataIndex="order_no" key={v4()}/>

                  <Column
                    key={v4()}
                    title="Courier Name"
                    dataIndex="courier_name"
                  // key="courier_name"
                  />
                  <Column
                    key={v4()}
                    title="Refering Health Facility"
                    dataIndex="sender_name"
                  // key="sender_name"
                  />
                  <Column
                    key={v4()}
                    title="Testing Health Facility"
                    dataIndex="tester_name"
                  // key="tester_name"
                  />
                  <Column
                    key={v4()}
                    title="Order Created"
                    dataIndex={['order_created']['seconds']}
                    sorter= {(a, b) => ((a === undefined || a == null || b === undefined || b === null) ? 1 :  a.order_created.seconds - b.order_created.seconds)}
                    defaultSortOrder={'descend'}
                    render={(_,order) => {  
                      let date = moment.unix(order.order_created.seconds).format("MMMM DD, YYYY hh:mm");
                      return (
                        <Typography>
                          {order ? date : "NA"}
                        </Typography>
                      );
                    }}
                  />
                  <Column title="Patient's Name" dataIndex="name" key={v4()} />
                  <Column title="MRN" dataIndex="MR" key={v4()} />

                  <Column title="Sex" dataIndex="sex" key={v4()} />
                  <Column title="Age" dataIndex="age" key={v4()} />
                  {/* <Column
                    key={v4()}
                    title="Age(Months)"
                    dataIndex="age_months"
                    render={(val, record)=>{ 
                      console.log(record);                     
                      return (<Typography>{val ? val : "N/A"}</Typography>)
                    }
                  }
                  /> */}
                  <Column title="Phone" dataIndex="phone" key={v4()} />
                  <Column
                    key={v4()}
                    filterDropdown={({
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                    }) => {
                      return (
                        <>
                          <Row style={{ padding: 5 }}>
                            <Col>
                              <Input
                                style={{ margin: 3 }}
                                autoFocus
                                placeholder="Type region name here..."
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  );
                                }}
                                onPressEnter={() => {
                                  confirm();
                                }}
                                onBlur={() => {
                                  confirm();
                                }}
                              ></Input>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{ margin: 3 }}
                                onClick={() => {
                                  confirm();
                                }}
                              >
                                Search
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="primary"
                                danger
                                style={{ margin: 3 }}
                                onClick={() => {
                                  clearFilters();
                                  confirm();
                                }}
                              >
                                Clear
                              </Button>
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                    filterIcon={<FilterOutlined />}
                    onFilter={(value, record) => {
                      // console.log(record)
                      return record.region != null
                        ? record.region.name
                          .toLowerCase()
                          .includes(value.toLocaleLowerCase())
                        : false;
                    }}
                    title="Region"
                    dataIndex="region"
                    // key="region"
                    render={(val) => {
                      return <Typography>{val ? val.name : "--"}</Typography>;
                    }}
                  />
                  <Column
                    key={v4()}
                    filterDropdown={({
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                    }) => {
                      return (
                        <>
                          <Row style={{ padding: 5 }}>
                            <Col>
                              <Input
                                style={{ margin: 3 }}
                                autoFocus
                                placeholder="Type region name here..."
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  );
                                }}
                                onPressEnter={() => {
                                  confirm();
                                }}
                                onBlur={() => {
                                  confirm();
                                }}
                              ></Input>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{ margin: 3 }}
                                onClick={() => {
                                  confirm();
                                }}
                              >
                                Search
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="primary"
                                danger
                                style={{ margin: 3 }}
                                onClick={() => {
                                  clearFilters();
                                  confirm();
                                }}
                              >
                                Clear
                              </Button>
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                    filterIcon={<FilterOutlined />}
                    onFilter={(value, record) => {
                      // console.log(record)
                      return record.zone_name != null
                        ? record.zone_name
                          .toLowerCase()
                          .includes(value.toLocaleLowerCase())
                        : false;
                    }}
                    title="Zone"
                    dataIndex="zone_name"
                  // key="zone_name"
                  />
                  <Column
                    key={v4()}
                    filterDropdown={({
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                    }) => {
                      return (
                        <>
                          <Row style={{ padding: 5 }}>
                            <Col>
                              <Input
                                style={{ margin: 3 }}
                                autoFocus
                                placeholder="Type woreda name here..."
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  );
                                }}
                                onPressEnter={() => {
                                  confirm();
                                }}
                                onBlur={() => {
                                  confirm();
                                }}
                              ></Input>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{ margin: 3 }}
                                onClick={() => {
                                  confirm();
                                }}
                              >
                                Search
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="primary"
                                danger
                                style={{ margin: 3 }}
                                onClick={() => {
                                  clearFilters();
                                  confirm();
                                }}
                              >
                                Clear
                              </Button>
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                    filterIcon={<FilterOutlined />}
                    onFilter={(value, record) => {
                      // console.log(record)
                      return record.woreda_name != null
                        ? record.woreda_name
                          .toLowerCase()
                          .includes(value.toLocaleLowerCase())
                        : false;
                    }}
                    title="Woreda"
                    dataIndex="woreda_name"
                  // key="woreda_name"
                  />

                  <Column title="Specimen Type" dataIndex="specimens" key={v4()} render={(_, specimens) =>{
                    return _.map((specimen) =>{
                      return <div>
                           {specimen.type}, {" "}
                      </div>
                    })
                  }}/>

                  <Column
                    key={v4()}
                    title="Site of Test"
                    dataIndex="anatomic_location"
                  // key="anatomic_location"
                  />
                  <Column
                    key={v4()}
                    title="Requested Test"
                    dataIndex="requested_test"
                  // key="requested_test"
                  />
                  <Column
                    key={v4()}
                    title="Reason for Test"
                    dataIndex="reason_for_test"
                  // key="reason_for_test"
                  />
                  <Column
                    key={v4()}
                    title="Registration Group"
                    dataIndex="registration_group"
                  // key="registration_group"
                  />

                  <Column
                    key={v4()}
                    title="Delivery Status"
                    dataIndex="order_status"
                    // key="order_status"
                    render={(val) => <p>{val}</p>}
                  /> 

                  <Column
                    key={v4()}
                    title="Turn around time"
                    render={(val, record) => {
                      if(record["test_result_added"]){
                      if(record["test_result_added"].hasOwnProperty("seconds") && record["test_result_added"].hasOwnProperty("nanoseconds") && !record["test_result_added"].hasOwnProperty("month")) {
                        let temp1 = record.test_result_added ? moment.unix(record.test_result_added.seconds).format("YYYY-MM-DD HH:mm") : "unknown";
                   
                        const test_result_added_at =  moment(temp1.toString(), "YYYY-MM-DD HH:mm") ;
                        // let temp2 = val ? moment.unix(record.order_confirmed.seconds).format("YYYY-MM-DD HH:mm")  : 'unknown';
                        let temp2 = (record["order_confirmed"].hasOwnProperty("year")) ? moment((record["order_confirmed"].year) + "-" +moment(record["order_confirmed"].monthValue) + "-" + moment(record["order_confirmed"].dayOfMonth) + ", " + moment(record["order_confirmed"].hour) + ":" + moment(record["order_confirmed"].second)).format("YYYY-MM-DD HH:mm")  :  moment.unix(record.order_confirmed.seconds).format("YYYY-MM-DD HH:mm");
                        
                        const order_placed_date =  moment(temp2.toString(), "YYYY-MM-DD HH:mm");
                        const duration = ( test_result_added_at && order_placed_date ) ?   moment.duration(test_result_added_at.diff(order_placed_date)) : "unkonwn";
                
                        return (
                          <Typography>
                           { (duration.hours() < 10 ? "0" + duration.hours() : duration.hours()) + " : " + (duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()) + " : " + (duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds())}  
                          </Typography>
                        );
                       
                      } else {
                        let testResultAddedTime = moment(moment(record["test_result_added"]).format('YYYY-MM-DD, hh:mm'));
                        // console.log(testResultAddedTime);
                        let order_confirmed_time = (record["order_confirmed"].hasOwnProperty("year")) ? moment((record["order_confirmed"].year) + "-" +moment(record["order_confirmed"].monthValue) + "-" + moment(record["order_confirmed"].dayOfMonth) + ", " + moment(record["order_confirmed"].hour) + ":" + moment(record["order_confirmed"].second))  :  moment(moment.unix(record.order_confirmed.seconds).format("MMMM DD,  YYYY hh:mm"));
                        // console.log(order_confirmed_time);
                        const duration = ( testResultAddedTime && order_confirmed_time ) ?   moment.duration(testResultAddedTime.diff(order_confirmed_time)) : "unkonwn";
                        return (
                          <Typography>
                           { (duration.hours() < 10 ? "0" + duration.hours() : duration.hours()) + " : " + (duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()) + " : " + (duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds())}  
                          </Typography>
                        );
                      }
                    }

                    }}
                  />

                  <Column
                    key={v4()}
                    title="MTB Result"
                    dataIndex="result"
                    // key="result"
                    render={(val) => {
                      return (
                        <Typography>
                          {val ? val["mtb_result"] : "N/A"}
                        </Typography>
                      );
                    }}
                  />

                  <Column
                    key={v4()}
                    title="Result RR"
                    dataIndex="result"
                    // key="result"
                    render={(val) => {
                      return (
                        <Typography>
                          {val ? val["result_rr"] : "N/A"}
                        </Typography>
                      );
                    }}
                  />
                  <Column
                    key={v4()}
                    title="Lab Registration Number"
                    dataIndex="result"
                    // key="result"
                    render={(val) => {
                      return (
                        <Typography>
                          {val ? val["lab_registratin_number"] : "N/A"}
                        </Typography>
                      );
                    }}
                  />

                  <Column
                    key={v4()}
                    title="Specimen Condition"
                    dataIndex="specimenCondition"
                    // key="specimenCondition"
                    render={(val) => {
                      return <Typography>{val}</Typography>;
                    }}
                  />
                  <Column
                    key={v4()}
                    title="Transport Mode"
                    dataIndex="transportMode"
                    // key="transportMode"
                    render={(val) => {
                      return <Typography>{val}</Typography>;
                    }}
                  />
                </Table>
              </>
            )}
          </>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Shipment Report" key="4">
          <>
            {loadingOrders && <LoadingComponent />}
            {loadedOrders && !loadingOrders && (
              <>
                <Button type="primary" style={{ marginBottom: 20 }}>
                  <CSVLink
                    filename={"Specimen Report.csv"}
                    data={shipmentReportCSV(orders)}
                    className="btn btn-primary"
                    onClick={() => {
                      // console.log("The file is downloading")
                    }}
                  >
                    Export to CSV
                  </CSVLink>
                </Button>

                <Select
                  value={dateFilter}
                  style={{ width: 200, marginLeft: 20 }}
                  onChange={(val) => {
                    setDateFilter(val);
                  }}
                >
                  <Select.Option value="ALL">All</Select.Option>
                  <Select.Option value="TODAY">Today</Select.Option>
                  <Select.Option value="THIS_WEEK">This Week</Select.Option>
                  <Select.Option value="THIS_MONTH">This Month</Select.Option>
                  <Select.Option value="THIS_YEAR">This Year</Select.Option>
                  <Select.Option value="CUSTOM_DATE">Custom Date</Select.Option>
                </Select>

                {dateFilter === "CUSTOM_DATE" && (
                  <DatePicker.RangePicker
                    value={
                      fromDate && toDate
                        ? [moment(fromDate), moment(toDate)]
                        : null
                    }
                    onChange={onChange}
                    onOk={onOk}
                    style={{ marginLeft: 10 }}
                  />
                )}

                <Table scroll={{ x: 1400 }}  dataSource={filterdData} style={{marginRight : 10, overflow : 'scroll'}}>
                  <Column title="Order ID" dataIndex="order_no" key={v4()} />
                  <Column
                    key={v4()}
                    title="Pick up Site"
                    dataIndex="sender_name"
                  // key="sender_name"
                  />

                  {/* <Column
                    key={v4()}
                    filterDropdown={({
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                    }) => {
                      return (
                        <>
                          <Row style={{ padding: 5 }}>
                            <Col>
                              <Input
                                style={{ margin: 3 }}
                                autoFocus
                                placeholder="Type region name here..."
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  );
                                }}
                                onPressEnter={() => {
                                  confirm();
                                }}
                                onBlur={() => {
                                  confirm();
                                }}
                              ></Input>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{ margin: 3 }}
                                onClick={() => {
                                  confirm();
                                }}
                              >
                                Search
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="primary"
                                danger
                                style={{ margin: 3 }}
                                onClick={() => {
                                  clearFilters();
                                  confirm();
                                }}
                              >
                                Clear
                              </Button>
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                    filterIcon={<FilterOutlined />}
                    onFilter={(value, record) => {
                      // console.log(record)
                      return record.region != null
                        ? record.region.name
                          .toLowerCase()
                          .includes(value.toLocaleLowerCase())
                        : false;
                    }}
                    title="Region"
                    dataIndex="region"
                    // key="region"
                    render={(val) => <p>{(val && val.name) || "--"}</p>}
                  />
                  <Column
                    key={v4()}
                    filterDropdown={({
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                    }) => {
                      return (
                        <>
                          <Row style={{ padding: 5 }}>
                            <Col>
                              <Input
                                style={{ margin: 3 }}
                                autoFocus
                                placeholder="Type region name here..."
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  );
                                }}
                                onPressEnter={() => {
                                  confirm();
                                }}
                                onBlur={() => {
                                  confirm();
                                }}
                              ></Input>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{ margin: 3 }}
                                onClick={() => {
                                  confirm();
                                }}
                              >
                                Search
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="primary"
                                danger
                                style={{ margin: 3 }}
                                onClick={() => {
                                  clearFilters();
                                  confirm();
                                }}
                              >
                                Clear
                              </Button>
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                    filterIcon={<FilterOutlined />}
                    onFilter={(value, record) => {
                      // console.log(record)
                      return record.zone_name != null
                        ? record.zone_name
                          .toLowerCase()
                          .includes(value.toLocaleLowerCase())
                        : false;
                    }}
                    title="Zone"
                    dataIndex="zone_name"
                  // key="zone_name"
                  />

                  <Column
                    key={v4()}
                    filterDropdown={({
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                    }) => {
                      return (
                        <>
                          <Row style={{ padding: 5 }}>
                            <Col>
                              <Input
                                style={{ margin: 3 }}
                                autoFocus
                                placeholder="Type woreda name here..."
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  );
                                }}
                                onPressEnter={() => {
                                  confirm();
                                }}
                                onBlur={() => {
                                  confirm();
                                }}
                              ></Input>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{ margin: 3 }}
                                onClick={() => {
                                  confirm();
                                }}
                              >
                                Search
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="primary"
                                danger
                                style={{ margin: 3 }}
                                onClick={() => {
                                  clearFilters();
                                  confirm();
                                }}
                              >
                                Clear
                              </Button>
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                    filterIcon={<FilterOutlined />}
                    onFilter={(value, record) => {
                      // console.log(record)
                      return record.woreda_name != null
                        ? record.woreda_name
                          .toLowerCase()
                          .includes(value.toLocaleLowerCase())
                        : false;
                    }}
                    title="Woreda"
                    dataIndex="woreda_name"
                  // key="woreda_name"
                  /> */}

                  <Column
                    key={v4()}
                    title="Courier Name"
                    dataIndex="courier_name"
                  // key="courier_name"
                  />
                  <Column
                    key={v4()}
                    title="Recipient Site"
                    dataIndex="tester_name"
                  // key="tester_name"
                  />
                  <Column
                    key={v4()}
                    title="Number of Patients"
                    dataIndex="patients"
                    // key="patients"
                    render={(val) => (
                      <Typography>{(val && val.length) || 0}</Typography>
                    )}
                  />

                  <Column
                    key={v4()}
                    title="Order Created"
                    dataIndex={['order_created']['seconds']}
                    sorter= {(a, b) => ((a === undefined || a == null || b === undefined || b === null) ? 1 :  a.order_created.seconds - b.order_created.seconds)}
                    defaultSortOrder={'descend'}
                    render={(_,time) => {
                      let date = moment.unix(time.order_created.seconds).format("MMMM DD,  YYYY hh:mm");
                      return <p>{time ? date : "N/A"}</p>;
                    }}
                  />

                  <Column
                    key={v4()}
                    title="Order Accepted"
                    dataIndex="order_confirmed"
                    // key="order_confirmed"
                    render={(val, record) => {
                       const order_confirmed = record["order_confirmed"] ?? {"not" : "not"};
                       if(order_confirmed.hasOwnProperty("seconds") && order_confirmed.hasOwnProperty("nanoseconds") && !order_confirmed.hasOwnProperty("month")) {
                        let date = moment.unix(record.order_confirmed.seconds).format("MMMM DD,  YYYY hh:mm");
                        return <p>{date ? date : "N/A"}</p>;
                      }
                      else if(record["order_confirmed"]){
                        let month = record["order_confirmed"]["month"];
                        let date = record["order_confirmed"]["dayOfMonth"];
                        let year = record["order_confirmed"]["year"];
                        let hour = record["order_confirmed"]["hour"];
                        let minute = record["order_confirmed"]["minute"];
                        return (
                          <Typography>
                            {month + " " + date + ", " + year  + "  " + hour + ":" + minute}
                          </Typography>
                        );
                      } else{
                        return (
                          <Typography>
                            {"N/A"}
                          </Typography>
                        );
                      }
                    }}
                  />

                  <Column
                    key={v4()}
                    title="Shipment Duration"
                    dataIndex="order_pickedup"
                    // key="order_confirmed"
                    render={(val, record) => {
                      //console.log(record);
                      const order_pickedup = record["order_pickedup"] ?? {"NA" : "NA"};
                      if(order_pickedup.hasOwnProperty("seconds") && order_pickedup.hasOwnProperty("nanoseconds") && !order_pickedup.hasOwnProperty("month")) {                       
                       let order_pickedup_formated_time = record.order_pickedup ? moment(moment.unix(record.order_pickedup.seconds).format("YYYY-MM-DD HH:mm")) : "N/A";
                       let order_received_formated_time = ( record.order_received && record.order_received.hasOwnProperty("year") ) ? moment(record.order_received.year + "-" + (record.order_received.monthValue < 10 ? "0" + record.order_received.monthValue : record.order_received.monthValue) + "-" + 
                       ( record.order_received.dayOfMonth) + " " + (record.order_received.hour) + ":" + (record.order_received.minute))  :  (record.order_received && record.order_received.hasOwnProperty("seconds") ? moment(moment.unix(record.order_received.seconds).format("YYYY-MM-DD HH:mm")) : "N/A");
                       const duration = ( order_received_formated_time !== "N/A" &&  order_pickedup_formated_time !== "N/A" ) ?   moment.duration(order_received_formated_time.diff(order_pickedup_formated_time)) : null;
                       return <p>{duration  ?  ((duration.hours() <= 10 ? "0" + duration.hours() : duration.hours()) + " : " + (duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()) + " : " + (duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds()))
                       : "N/A"}</p>;
                     } else if(order_pickedup.hasOwnProperty("month")){
                       let order_received_formated_time = ( record.order_received && record.order_received.hasOwnProperty("year") ) ? moment(record.order_received.year + "-" + (record.order_received.monthValue) + "-" + 
                       ( record.order_received.dayOfMonth) + " " + ( record.order_received.hour) + ":" + ( record.order_received.minute))  :  (record.order_received && record.order_received.hasOwnProperty("seconds") ? moment(moment.unix(record.order_received.seconds).format("YYYY-MM-DD HH:mm")) : "N/A");
                       let order_pickedup_formated_time =  moment((record["order_pickedup"].year) + "-" +moment(record["order_pickedup"].monthValue) + "-" + moment(record["order_pickedup"].dayOfMonth) + ", " + moment(record["order_pickedup"].hour) + ":" + moment(record["order_pickedup"].minute));
                       const duration = ( order_received_formated_time !== "N/A" &&  order_pickedup_formated_time !== "N/A" ) ?   moment.duration(order_received_formated_time.diff(order_pickedup_formated_time)) : null;
                       return <p>{duration  ?  ((duration.hours() < 10 ? "0" + duration.hours() : duration.hours()) + " : " + (duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()) + " : " + (duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds()))
                       : "N/A"}</p>;
                     } else {
                      return <p>N/A</p>
                     }
                   }}
                  />

                  <Column
                    key={v4()}
                    title="Pickup Duration"
                    dataIndex="order_pickedup"
                    // key="order_pickedup"
                    render={(val, record) => {
                      const order_pickedup = record["order_pickedup"] ?? {"not" : "not"};
                      if(order_pickedup.hasOwnProperty("seconds") && order_pickedup.hasOwnProperty("nanoseconds") && !order_pickedup.hasOwnProperty("month")) {
                        let order_confirmed_formated_time = (record["order_confirmed"].hasOwnProperty("year")) ? moment((record["order_confirmed"].year) + "-" +moment(record["order_confirmed"].monthValue) + "-" + moment(record["order_confirmed"].dayOfMonth) + ", " + moment(record["order_confirmed"].hour) + ":" + moment(record["order_confirmed"].second))  :  moment(moment.unix(record.order_confirmed.seconds));
                        // let order_confirmed_formated_time = record.order_confirmed ? moment.unix(record.order_confirmed.seconds) : "N/A";
                        let order_pickedup_formated_time = record.order_pickedup ? moment.unix(record.order_pickedup.seconds)  : 'N/A';
                        const duration = ( order_confirmed_formated_time !== "N/A" && order_pickedup_formated_time !== "N/A" ) ?   moment.duration(order_pickedup_formated_time.diff(order_confirmed_formated_time)) : null;
                        return <p>{duration ?  ((duration.hours() < 10 ? "0" + duration.hours() : duration.hours()) + " : " + (duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()) + " : " + (duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds()))
                        : "N/A"}</p>;
                     
                     } else if(order_pickedup.hasOwnProperty("month")){
                      let order_confirmed_formated_time = (record["order_confirmed"].hasOwnProperty("year")) ? moment((record["order_confirmed"].year) + "-" + moment(record["order_confirmed"].monthValue) + "-" + moment(record["order_confirmed"].dayOfMonth) + ", " + moment(record["order_confirmed"].hour) + ":" + moment(record["order_confirmed"].second))  :  moment(moment.unix(record.order_confirmed.seconds));
                      let order_pickedup_formated_time =  moment((record["order_pickedup"].year) + "-" +moment(record["order_pickedup"].monthValue) + "-" + moment(record["order_pickedup"].dayOfMonth) + ", " + moment(record["order_pickedup"].hour) + ":" + moment(record["order_pickedup"].second));
                      const duration = ( order_confirmed_formated_time !== "N/A" && order_pickedup_formated_time !== "N/A" ) ?   moment.duration(order_pickedup_formated_time.diff(order_confirmed_formated_time)) : null;
                      return <p>{duration ?  ((duration.hours() < 10 ? "0" + duration.hours() : duration.hours()) + " : " + (duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()) + " : " + (duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds()))
                        : "N/A"}</p>;
                    } else{
                       return (
                         <Typography>
                           {"N/A"}
                         </Typography>
                       );
                     }
                   }}
                  />

                  <Column
                    key={v4()}
                    title="Drop off Date/Time"
                    dataIndex="order_received"
                    // key="order_received"
                    render={(_,record) => {
                      const order_received = record["order_received"] ?? {"not" : "not"};
                      if(order_received.hasOwnProperty("seconds") && order_received.hasOwnProperty("nanoseconds") && !order_received.hasOwnProperty("month")) {
                       let date = moment.unix(record.order_received.seconds).format("MMMM DD,  YYYY hh:mm");
                       return <p>{date ? date : "N/A"}</p>;
                     }
                     else if(record["order_received"]){
                       let month = record["order_received"]["month"];
                       let date = record["order_received"]["dayOfMonth"];
                       let year = record["order_received"]["year"];
                       let hour = record["order_received"]["hour"];
                       let minute = record["order_received"]["minute"] === 0 ? "00" : record["order_received"]["minute"];
                       return (
                         <Typography>
                           {month + " " + date + ", " + year  + "  " + hour + ":" + minute}
                         </Typography>
                       );
                     } else{
                       return (
                         <Typography>
                           {"N/A"}
                         </Typography>
                       );
                     }
                    }}
                  />
                </Table>
              </>
            )}
          </>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default Dashboard;
