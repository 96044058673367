import { addDoc, getDocs, collection, doc, deleteDoc, setDoc, where, query } from 'firebase/firestore'
import { firestoreDatabase } from '../firebase';

const db = firestoreDatabase
/*
Description : adds new region to the database 
@params [code, name]
*/
export const addInstitution = async ({ code, name, type, region, phone_number, zone, woreda }) => {
    code = code.toUpperCase()
    const q = query(collection(firestoreDatabase, "institutions"), where("code", '==', code));

    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length !== 0) {
        return { success: false, message: 'Institutional Code already registerd!' };
    }
    try {
        await addDoc(collection(db, "institutions"), {
            code, name, type, region, phone_number, zone, woreda, phone: phone_number
        });
        return { success: true };
    } catch {
        return { success: false, message: 'Error on adding region!' };
    }
}

/*
Description : delelte institution
@params [region_id]
*/
export const deleteInstitution = async (institution_id) => {
    try {
        let q = query(collection(firestoreDatabase, "users"), where("institution_id", '==', institution_id));
        let querySnapshot = await getDocs(q);
        let institutionAdminUserIds = querySnapshot.docs.map(d => d.data()['user_id'])
        // console.log(institutionAdminUserIds)
        q = query(collection(firestoreDatabase, "orders"), where("sender_id", 'in', institutionAdminUserIds));
        querySnapshot = await getDocs(q)
        if (querySnapshot.docs.length !== 0) {
            return { success: false, message: 'There are orders under these health center!' };
        }
        await deleteDoc(doc(firestoreDatabase, "institutions", institution_id));
        return { success: true };
    } catch (error) {
        // console.log(error)
        return { success: false, message: 'Error deleting health center!' };
    }
}

/*
Description : gets all added institutions
*/
export const getInstitutions = async () => {
    const querySnapshot = await getDocs(collection(db, "institutions"));
    let institutions = []
    querySnapshot.forEach((doc) => {
        institutions = [...institutions, { ...doc.data(), key: doc.id }]
    });
    return institutions
}

/*
}/*
Description : edit specific region
@params [xone_name and region_code]
*/
export const editInstitutions = async ({ id, name, code, type, phone_number, extra }) => {
    code = code && code.toUpperCase()
    try {
        const regionRef = doc(firestoreDatabase, 'institutions', id);
        await setDoc(regionRef, { code, name, type, phone_number, phone: phone_number, ...extra }, { merge: true });
        return true

    } catch (error) {
        return false
    }
}

