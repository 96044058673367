import {
  addDoc,
  getDocs,
  collection,
  doc,
  deleteDoc,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import { firestoreDatabase } from "../firebase";

const db = firestoreDatabase;
/*
Description : adds new region to the database 
@params [code, name]
*/
export const addTestCenters = async ({
  code,
  name,
  region,
  phone_number,
  zone,
  woreda,
}) => {
  code = code.toUpperCase();
  const q = query(
    collection(firestoreDatabase, "test_centers"),
    where("code", "==", code)
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.docs.length !== 0) {
    return { success: false, message: "Institutional Code already registerd!" };
  }

  //Todo check if the zone_id not exists
  try {
    await addDoc(collection(db, "test_centers"), {
      code,
      name,
      region,
      phone_number,
      zone,
      woreda,
      phone: phone_number,
    });
    return { success: true };
  } catch {
    return { success: false, message: "Error on adding region!" };
  }
};

/*
Description : delelte institution
@params [region_id]
*/
export const deleteTestCenters = async (test_center_id) => {
  try {
    const q = query(
      collection(firestoreDatabase, "orders"),
      where("tester_id", "==", test_center_id)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length !== 0) {
      return {
        success: false,
        message: "There are some orders under these test center!",
      };
    }

    await deleteDoc(doc(firestoreDatabase, "test_centers", test_center_id));
    return { success: true };
  } catch (error) {
    return { success: false, message: "Error deleting test center!" };
  }
};

/*
Description : gets all added institutions
*/
export const getTestCenters = async () => {
  const querySnapshot = await getDocs(collection(db, "test_centers"));
  let test_centers = [];
  querySnapshot.forEach((doc) => {
    test_centers = [...test_centers, { ...doc.data(), key: doc.id }];
  });
  return test_centers;
};

/*
}/*
Description : edit specific region
@params [xone_name and region_code]
*/
export const editTestCenter = async ({
  id,
  name,
  code,
  phone_number,
  extra,
}) => {
  code = code && code.toUpperCase();
  //Todo check if the zone id does not exist before
  try {
    const regionRef = doc(firestoreDatabase, "test_centers", id);
    setDoc(
      regionRef,
      { code, name, phone_number, phone: phone_number, ...extra },
      { merge: true }
    );
    return true;
  } catch (error) {
    return false;
  }
};
