import { getDocs, collection, doc, query, where, setDoc, deleteDoc } from 'firebase/firestore'
import { signInWithEmailAndPassword } from 'firebase/auth'

import { auth, firestoreDatabase } from '../firebase';
import { signUp } from './auth';

const db = firestoreDatabase

export const addInstitutionalAdmin = async ({ name, phone, username, institution, password, institution_id, permission }) => {
    //{ name, phone, username, institution, institution_id: institution.key }
    //Todo check if the zone_id not exists
    try {
        const status = await signUp(username, password)

        if (status.success) {

            let user = doc(db, "users", status.user.uid)
            await setDoc(user, {
                username, name, password, phone, phone_number: phone, institution, institution_id, type: "INSTITUTIONAL_ADMIN", disabled: false, user_id: status.user.uid, permission
            })

            // const docRef = await addDoc(collection(db, "users"), {
            //     username, name, password, phone, institution, institution_id, type: "INSTITUTIONAL_ADMIN", disabled: false, user_id: status.user.uid
            // });
            return { success: true };
        }
        else {
            return { success: false, message: status.message }
        }
    } catch (err) {

        // console.log(err)
        return { success: false, message: 'Error on adding user!' };
    }
}

export const addZonalAdmin = async ({ name, phone, username, region, password, region_id }) => {
    //{ name, phone, username, institution, institution_id: institution.key }
    //Todo check if the zone_id not exists
    try {
        const status = await signUp(username, password)

        if (status.success) {
            // console.log(typeof status.user)

            let user = doc(db, "users", status.user.uid)
            await setDoc(user, {
                username, name, password, phone, phone_number: phone, region, region_id, type: "ZONAL_ADMIN", disabled: false, user_id: status.user.uid,
            })

            // const docRef = await addDoc(collection(db, "users"), {
            //     username, name, password, phone, region, region_id, type: "ZONAL_ADMIN", disabled: false, user_id: status.user.uid,
            // });
            return { success: true };
        }
        else {
            return { success: false, message: status.message }
        }
    } catch {
        return { success: false, message: 'Error on adding user!' };
    }
}


export const addCourierAdmin = async ({ name, phone, username, region, password, region_id, zone, woreda, permission }) => {
    //{ name, phone, username, institution, institution_id: institution.key }
    //Todo check if the zone_id not exists
    try {
        // console.log({ name, phone, password, username, region, region_id, zone, woreda })
        const status = await signUp(username, password)
        // console.log(status)
        if (status.success) {

            let user = doc(db, "users", status.user.uid)
            await setDoc(user, {
                username, name, phone, phone_number: phone, region_id, region, type: "COURIER_ADMIN", disabled: false, user_id: status.user.uid, zone, woreda, permission, password
            })

            // const docRef = await addDoc(collection(db, "users"), {
            //     username, name, phone, region_id, region, type: "COURIER_ADMIN", disabled: false, user_id: status.user.uid,
            // });
            return { success: true };
        }
        else {
            return { success: false, message: status.message }
        }
    } catch {
        return { success: false, message: 'Error on adding user!' };
    }
}

export const addTestCenterAdmin = async ({ name, phone, username, test_center, password, test_center_id, permission }) => {
    //{ name, phone, username, institution, institution_id: institution.key }
    //Todo check if the zone_id not exists
    try {
        const status = await signUp(username, password)
        if (status.success) {

            let user = doc(db, "users", status.user.uid)
            await setDoc(user, {
                username, name, password, phone_number: phone, phone, test_center, test_center_id, type: "TEST_CENTER_ADMIN", disabled: false, user_id: status.user.uid, permission
            })


            // const docRef = await addDoc(collection(db, "users"), {
            //     username, name, password, phone, test_center, test_center_id, type: "TEST_CENTER_ADMIN", disabled: false, user_id: status.user.uid,
            // });
            return { success: true };
        }
        else {
            return { success: false, message: status.message }
        }
    } catch {
        return { success: false, message: 'Error on adding user!' };
    }
}

/*
Description : delelte user
@params [user_id]
*/
export const deleteUserFromAuth = async (user_id, username, password) => {
    try {
        const userRef = doc(firestoreDatabase, 'users', user_id);

        const u = await signInWithEmailAndPassword(auth, username, password)
        //TODO: delete user from firebase authentication 
        // console.log(user)
        await u.user.delete()
        await deleteDoc(userRef)
        // await setDoc(userRef, { /disabled: true }, { merge: true });
        return true

    } catch (error) {
        // console.log(error)
        return false
    }
}

/*
Description : gets all added users
*/
export const getAllActiveUsers = async () => {

    let users = []
    const q = query(collection(db, "users"), where("disabled", "==", false));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        users = [...users, { ...doc.data(), key: doc.id }]
    });
    return users
}


export const editUser = async ({ id, name, phone_number, extra, permission }) => {
    //Todo check if the zone id does not exist before
    try {
        const userRef = doc(firestoreDatabase, 'users', id);
        setDoc(userRef, { name, phone_number, phone: phone_number, ...extra, permission }, { merge: true });
        return true

    } catch (error) {
        // console.log(error)
        return false
    }
}

export const userRegisteredWithPhoneNumber = async (phone) => {
    const q = query(collection(db, "users"), where("phone_number", "==", phone));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length !== 0
}