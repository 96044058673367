import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, firestoreDatabase } from "../firebase";

export const numberOfSpecimentReferredReferring = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Waiting for Confirmation',
        'Confirmed',
        'Picked Up',
        'Arrived',
        'Confirmed',
        'Received',
        'Delivered',
        'Accepted',
        'Inspected',
        'Delivered',
    ]), where('tester_id', '==', await getUserId()));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimensCount = patient.specimens != null ? patient.specimens.length : 0
            count += specimensCount
        })
    })

    return count
}

export const numberOfSpecimenConfirmedRefferring = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Picked Up',
        'Confirmed',
        'Arrived',
        'Confirmed',
        'Received',
        'Delivered',
        'Tested',
        'Accepted',
        'Inspected',
        'Delivered',
    ]), where('user_id', '==', await getUserId()));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimensCount = patient.specimens != null ? patient.specimens.length : 0
            count += specimensCount
        })
    })

    return count
}

export const convertToMMHH = (MINUTES) => {

    let m = MINUTES % 60;

    let h = (MINUTES - m) / 60;

    m = Math.floor(m)
    h = Math.floor(h)

    return h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
}

export const numberOfSpecimentReceivedRefferring = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Received',
        'Delivered',
        'Tested',
        'Accepted',
        'Inspected',
        'Delivered',
    ]), where('user_id', '==', await getUserId()));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimensCount = patient.specimens != null ? patient.specimens.length : 0
            count += specimensCount
        })
    })

    return count
}

export const numberOfSpecimensTestedReferring = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Received',
        'Delivered',
        'Tested',
        'Accepted',
        'Inspected',
        'Delivered',
    ]), where('user_id', '==', await getUserId()));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimens = patient.specimens || []
            specimens.forEach(specimen => {
                if (specimen['result']) {
                    count += 1
                }
            })
        })
    })

    return count
}



// const getAgeCategory = (data) => {
//     if (data['age'] >= 0 && data['age'] < 6) {
//         return '0-6'
//     }
//     else if (data['age'] >= 6 && data['age'] < 12) {
//         return '6-12'
//     }
//     else if (data['age'] >= 12 && data['age'] < 24) {
//         return '12-24'
//     }
//     else if (data['age'] >= 24 && data['age'] < 48) {
//         return '24-48'
//     }
//     else if (data['age'] >= 48) {
//         return '24-48'
//     }
//     return ''
// }

const getUserId = async () => {
    let id = (auth.currentUser && auth.currentUser.uid) || ''
    return id
}
