import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from "date-fns";

export const filterByDate = (
  orders,
  filterType = "All",
  startDate = null,
  endDate = null
) => {
  const today = new Date();
  const todayStart = startOfDay(today);
  const todayEnd = endOfDay(today);

  const weekStart = startOfWeek(today);
  const weekEnd = endOfWeek(today);

  const monthStart = startOfMonth(today);
  const monthEnd = endOfMonth(today);

  const yearStart = startOfYear(today);
  const yearEnd = endOfYear(today);

  switch (filterType) {
    case "TODAY":
      orders = orders.filter(
        (order) =>
          order.order_created.toDate() >= todayStart &&
          order.order_created.toDate() < todayEnd
      );
      return orders;
    case "THIS_WEEK":
      orders = orders.filter(
        (order) =>
          order.order_created.toDate() >= weekStart &&
          order.order_created.toDate() < weekEnd
      );
      return orders;
    case "THIS_MONTH":
      orders = orders.filter(
        (order) =>
          order.order_created.toDate() >= monthStart &&
          order.order_created.toDate() < monthEnd
      );
      return orders;
    case "THIS_YEAR":
      orders = orders.filter(
        (order) =>
          order.order_created.toDate() >= yearStart &&
          order.order_created.toDate() < yearEnd
      );
      return orders;

    case "CUSTOM_DATE":
      if (startDate && endDate) {
        orders = orders.filter(
          (order) =>
            order.order_created.toDate() >= startOfDay(startDate) &&
            order.order_created.toDate() < endOfDay(endDate)
        );
        return orders;
      }
      orders = orders.filter(
        (order) =>
          order.order_created.toDate() >= yearStart &&
          order.order_created.toDate() < yearEnd
      );
      return orders;
    default:
      return orders;
  }
};
