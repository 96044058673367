import { Table, Card, Col, Row, Typography, } from 'antd'
import React, { useEffect, useState } from 'react'
import LoadingComponent from '../components/Loading';
import { overallData } from '../controllers/dashboard_admin';
import { getOrdersInstitution, getOrdersTestCenter } from '../controllers/orders.';
import { Pie, Column as BarColumn } from '@ant-design/plots';
import { numberOfSpecimenConfirmedRefferring, numberOfSpecimensTestedReferring, numberOfSpecimentReceivedRefferring, numberOfSpecimentReferredReferring } from '../controllers/dasboard_referring';



const SenderDashboard = () => {

    const [loadingOrders, setLoadingOrders] = useState(true)
    const [loadedOrders, setLoadedOrders] = useState(false)

    const [specimensReferred, setSpecimensReferred] = useState()
    const [specimensConfirmed, setSpecimensConfirmed] = useState()
    const [specimensReceived, setSpecimensReceived] = useState()
    const [testedSpecimens, setTestedSpecimens] = useState()
    const [orders, setOrders] = useState()

    const [overallDataa, setOverAllData] = useState()


    // const getPatientDataFromOrder = (order) => {
    //     let data = []
    //     order.forEach(p => {
    //         console.log(p['status'])
    //         let ps = (p && p['patients']) || []
    //         ps.forEach(s => {
    //             let ss = (s && s['specimens']) || []
    //             ss.forEach((spmen) => {
    //                 data = [...data, { ...p, ...s, ...spmen, order_status: p['status'] }]
    //             })

    //         })
    //     })

    //     return data
    // }

    // const getOverallData = (order) => {
    //     let data = []
    //     order.forEach(p => {
    //         console.log(p['status'])
    //         let ps = (p && p['patients']) || []
    //         ps.forEach(s => {
    //             let ss = (s && s['specimens']) || []
    //             ss.forEach((spmen) => {
    //                 data = [...data, { ...p, ...s, ...spmen, order_status: p['status'] }]
    //             })

    //         })
    //     })

    //     setOverAllData(overallData(data))
    // }

    // const getZoneAndWoreda = (region, zone, woreda) => {
    //     let pzone = ''
    //     let pworeda = ''
    //     region && region['zones'].forEach(z => {
    //         console.log(z['code'], zone)
    //         if (z['code'] === zone) {
    //             pzone = z
    //         }
    //     })
    //     if (pzone && pzone['woredas']) {
    //         pzone['woredas'].forEach(w => {
    //             if (w['code'] === woreda) {
    //                 pworeda = w
    //             }
    //         })
    //     }
    //     console.log(pzone)
    //     return { zone: pzone['name'], woreda: pworeda['woreda'] }
    // }




    useEffect(() => {
        loadOrders()
        loadSpecimenReferred()
        loadSpecimenReceived()
        loadSpecimenConfirmed()
        loadSpecimensTested()
    }, [])

    const ordersForCSV = (orders) => {
        return orders.map(order => {
            return {
                "Order ID": order.key,
                "Sender Name": order.sender_name || 'NA',
                "Courier Name": order.courier_name || 'NA',
                "Tester Name": order.tester_name || "NA",
                // "Zone": order.zone || 'NA',
                // 'Woreda': order.woreda || 'NA',
                'Patients': (order && order.patients && order.patients.length) || 0,
                "Order Crateds": order.order_created && dateFromString(order.order_created),
                "Status": order.status,
                'Region': order.region && order.region.name,
                'Zone': order.zone && order.zone.name,
            }
        })
    }

    const patientInfoForCSV = (data) => {
        return data.map(order => {
            return {
                "Order ID": order.key,
                "Sender Name": order.sender_name || 'NA',
                "Courier Name": order.courier_name || 'NA',
                "Tester Name": order.tester_name || "NA",
                "Order Created": order.order_created && dateFromString(order.order_created),
                'Name': order.name,
                'MR': order.MR,
                'Sex': order.sex,
                'Age': order.age,
                'Age in Months': order.age_months,
                'Phone': order.phone,
                'Zone': order.zone_name,
                'Woreda': order.woreda_name,
                'type': order.type,
                'Anatomic Location': order.anatomic_location,
                'Requested Test': order.requested_test,
                'Reason for Test': order.reason_for_test,
                "Registration Group": order.registration_group,
                'Order Status': order.order_status,
                'Region': order.region && order.region.name,
                'Zone': order.zone && order.zone.name,
            }
        })
    }

    const shipmentReportCSV = (data) => {
        return data.map(val => {
            return {
                'Order ID': val.key,
                'Sender Name': val.sender_name,
                'Courier Name': val.courier_name,
                'Tester Name': val.tester_name,
                'Patients': (val.patients && val.patients.length) || 0,
                'Order Crated': (val.order_created && dateFromString(val.order_created)) || 'NA',
                'Order Accepted': (val.order_confirmed && dateFromString(val.order_confirmed)) || 'NA',
                'Region': val.region && val.region.name,
                'Zone': val.zone && val.zone.name,
            }
        })
    }

    const loadOrders = async () => {
        setLoadingOrders(true)
        let os = await getOrdersTestCenter()
        let ps = []
        setOrders(os)
        os.forEach(val => {
            ps = [...ps, ...(val['patients'] || [])]
        })

        setLoadedOrders(true)
        setLoadingOrders(false)

        let data = []
        os.forEach(p => {
            let ps = (p && p['patients']) || []
            ps.forEach(s => {
                let ss = (s && s['specimens']) || []
                ss.forEach((spmen) => {
                    data = [...data, { ...p, ...s, ...spmen, order_status: p['status'] }]
                })

            })
        })

        setOverAllData(overallData(data))

        // console.log(data)
    }

    const loadSpecimenReferred = async () => {
        let sr = await numberOfSpecimentReferredReferring()
        setSpecimensReferred(sr)
    }

    const loadSpecimenConfirmed = async () => {
        let sr = await numberOfSpecimenConfirmedRefferring()
        setSpecimensConfirmed(sr)
    }

    const loadSpecimenReceived = async () => {
        let sr = await numberOfSpecimentReceivedRefferring()
        setSpecimensReceived(sr)
    }

    const loadSpecimensTested = async () => {
        let sr = await numberOfSpecimensTestedReferring()
        setTestedSpecimens(sr)
    }

    const TableComponent = ({ overalldata }) => {
        const columns = [
            {
                title: 'Hour',
                dataIndex: 'hour',
                width: '20%',
            },
            {
                title: 'Stool',
                dataIndex: 'stool',
                width: '20%',
            },
            {
                title: 'Sputum',
                dataIndex: 'sputum',
                width: '20%',
            },
            {
                title: 'Urine',
                dataIndex: 'urine',
                width: '20%',
            },

            {
                title: 'Blood',
                dataIndex: 'blood',
                width: '20%',
            },
        ];

        const data = [
            {
                key: '1',
                hour: '0-6',
                stool: overalldata.zeroToSix['Stool'],
                sputum: overalldata.zeroToSix['Sputum'],
                urine: overalldata.zeroToSix['Urine'],
                blood: overalldata.zeroToSix['Blood'],
            },
            {
                key: '2',
                hour: '6-12',
                stool: overalldata.sixToTwelve['Stool'],
                sputum: overalldata.sixToTwelve['Sputum'],
                urine: overalldata.sixToTwelve['Urine'],
                blood: overalldata.sixToTwelve['Blood'],
            },
            {
                key: '3',
                hour: '12-24',
                stool: overalldata.twelveToTwentyFour['Stool'],
                sputum: overalldata.twelveToTwentyFour['Sputum'],
                urine: overalldata.twelveToTwentyFour['Urine'],
                blood: overalldata.twelveToTwentyFour['Blood'],
            },
            {
                key: '4',
                hour: '24-48',
                stool: overalldata.twentyFourToFourtyEight['Stool'],
                sputum: overalldata.twentyFourToFourtyEight['Sputum'],
                urine: overalldata.twentyFourToFourtyEight['Urine'],
                blood: overalldata.twentyFourToFourtyEight['Blood'],
            },
            {
                key: '5',
                hour: '>48',
                stool: overalldata.aboveFourtyEight['Stool'],
                sputum: overalldata.aboveFourtyEight['Sputum'],
                urine: overalldata.aboveFourtyEight['Urine'],
                blood: overalldata.aboveFourtyEight['Blood'],
            },
        ];

        return <Table columns={columns} dataSource={data} />
    }



    const BarChartComponent = ({ overalldata }) => {
        const data = [
            {
                name: '<= 15',
                x: 'Sputum.',
                y: overalldata.sputumUnder15 + 20 || 0,
            },
            {
                name: '<= 15',
                x: 'Stool.',
                y: overalldata.stoolUnder15 + 20 || 0,
            },
            {
                name: '<= 15',
                x: 'Urine.',
                y: overalldata.urineUnder15 + 20 || 0,
            },
            {
                name: '<= 15',
                x: 'Blood.',
                y: overalldata.bloodUnder15 + 20 || 0,
            },
            {
                name: '> 15',
                x: 'Sputum.',
                y: overalldata.sputumAbove15 + 10 || 0,
            },
            {
                name: '> 15',
                x: 'Stool.',
                y: overalldata.stoolAbove15 + 10 || 0,
            },
            {
                name: '> 15',
                x: 'Urine.',
                y: overalldata.urineAbove15 + 10 || 0,
            },
            {
                name: '> 15',
                x: 'Blood.',
                y: (overalldata.bloodAbove15 + 10) || 0,
            },
        ];
        const config = {
            data,
            isGroup: true,
            xField: 'x',
            yField: 'y',
            seriesField: 'name',
            label: {
                position: 'middle',

                layout: [

                    {
                        type: 'interval-adjust-position',
                    },
                    {
                        type: 'interval-hide-overlap',
                    },
                    {
                        type: 'adjust-color',
                    },
                ],
            },
        };
        return <BarColumn style={{ padding: 10, margin: 10, height: 200, width: '80%' }} {...config} />;
    };


    const ChartComponent = ({ overalldata }) => {
        // console.log(overalldata)

        const mtbData = [
            {
                type: 'MTB Detected',
                value: overalldata.rifResDetected + overalldata.rifiResNotDetected + overalldata.rifResIndeterminate,
            },
            {
                type: 'MTB Not Detected',
                value: overalldata.mtbNotDetected,
            }
        ];

        const rifResData = [
            {
                type: 'Rif Res Detected',
                value: overalldata.rifResDetected,
            },
            {
                type: 'Rif Res Not Detected',
                value: overalldata.rifiResNotDetected,
            },
            {
                type: 'Rif Res Indeterminate',
                value: overalldata.rifResIndeterminate,
            },
        ]

        const genderSpecimenData = [
            {
                type: 'Male Sputum',
                value: overalldata.femaleSputum
            },
            {
                type: 'Female Sputum',
                value: overalldata.maleSputum,
            },
            {
                type: 'Male Stool',
                value: overalldata.maleStool
            },
            {
                type: 'Female Stool',
                value: overalldata.femaleStool,
            },
        ];


        const rifResConfig = {
            appendPadding: 10,
            data: rifResData,
            angleField: 'value',
            colorField: 'type',
            radius: 0.8,
            label: {
                type: 'outer',
                content: '{name} {percentage}',

            },
            interactions: [
                {
                    type: 'pie-legend-active',
                },
                {
                    type: 'element-active',
                },
            ],
        };

        const mtbConfig = {
            appendPadding: 10,
            data: mtbData,
            angleField: 'value',
            colorField: 'type',
            radius: 0.8,
            label: {
                type: 'outer',
                content: '{name} {percentage}',

            },
            interactions: [
                {
                    type: 'pie-legend-active',
                },
                {
                    type: 'element-active',
                },
            ],
        };

        const genderSpecimenConfig = {
            appendPadding: 10,
            data: genderSpecimenData,
            angleField: 'value',
            colorField: 'type',
            radius: 0.8,
            label: {
                type: 'outer',
                content: '{name} {percentage}',

            },
            interactions: [
                {
                    type: 'pie-legend-active',
                },
                {
                    type: 'element-active',
                },
            ],
        };
        return (
            <>
                <Row justify='space-around' align='middle'>
                    <Col style={{ margin: 2, }}>
                        <Pie {...rifResConfig} />
                    </Col>
                    <Col style={{ margin: 2, }}>
                        <Pie {...genderSpecimenConfig} />

                    </Col>
                    <Col style={{ margin: 2, }}>
                        <Pie {...mtbConfig} />
                    </Col>

                    <Col style={{ width: 500 }}>
                        <BarChartComponent overalldata={overalldata} />
                    </Col>

                </Row>

                {/* <BarChartComponent overalldata={overalldata} /> */}


            </>
        )
    };



    const dateFromString = (date) => {
        return date.toDate().toLocaleString()
    }

    return (

        <>
            {loadingOrders && <LoadingComponent />}
            {loadedOrders && !loadingOrders &&
                <>
                    <Row justify='space-around'>
                        <Col style={{ margin: 3, width: 250 }}>
                            <Card title="Specimens Referred" bordered={true} style={{ textAlign: 'center' }}>
                                <Typography style={{ fontSize: 35, textAlign: 'center', fontWeight: 'bold' }}>{specimensReferred}</Typography>
                            </Card>
                        </Col>
                        <Col style={{ margin: 3, width: 250 }}>
                            <Card title="Specimens Picked Up" bordered={false} style={{ textAlign: 'center' }}>
                                <Typography style={{ fontSize: 35, textAlign: 'center', fontWeight: 'bold', wordWrap: 'break-word' }}>{specimensConfirmed}</Typography>
                            </Card>
                        </Col>
                        <Col style={{ margin: 3, width: 250 }}>
                            <Card title="Specimens Received" bordered={false} style={{ textAlign: 'center' }}>
                                <Typography style={{ fontSize: 35, textAlign: 'center', fontWeight: 'bold' }}>{specimensReceived}</Typography>
                            </Card>
                        </Col>

                        {
                            overallDataa && <Col style={{ margin: 3, width: 250 }}>
                                <Card title="Tested Specimens" bordered={false} style={{ textAlign: 'center' }}>
                                    <Typography style={{ fontSize: 35, textAlign: 'center', fontWeight: 'bold' }}>{testedSpecimens}</Typography>
                                </Card>
                            </Col>
                        }
                    </Row>
                </>
            }

            {loadedOrders && !loadingOrders && overallDataa &&
                <>

                    <ChartComponent overalldata={overallDataa} />
                    <TableComponent overalldata={overallDataa} />
                </>
            }


        </>
    )
}

export default SenderDashboard