import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Space,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
  ExclamationCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { getregions } from "../controllers/location";
import LoadingComponent from "../components/Loading";
import {
  addTestCenters,
  deleteTestCenters,
  editTestCenter,
  getTestCenters,
} from "../controllers/test_centers";
import openNotificationWithIcon from "../notification";
import Highlight from "react-syntax-highlighter/dist/esm/highlight";

const { Option } = Select;
const { Column } = Table;

const TestCenters = () => {
  const [testCentersAddFormVisible, setTestCentersAddFormVisible] =
    useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState();
  const [testCenterId, setTestCenterId] = useState();

  const [loadingregions, setLoadingregions] = useState(true);
  const [loadedregions, setLoadedregions] = useState(false);
  const [regions, setregions] = useState([]);
  const [zones, setzones] = useState([]);
  const [woredas, setworedas] = useState([]);
  const [testCenters, setTestCenters] = useState([]);

  const [zone, setzone] = useState({});
  const [woreda, setworeda] = useState({});
  const [region, setregion] = useState({});

  const [selectedInstitution, setSelectedInstitution] = useState({});

  const [loadingTestCenters, setLoadingTestCenters] = useState(true);
  const [loadedTestCenters, setLoadedTestCenters] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const [newTestCenterCode, setNewTestCenterCode] = useState("");
  const [newTestCenterName, setNewTestCenterName] = useState("");
  const [newTestCenterPhone, setNewTestCenterPhone] = useState("");
  const [newTestCenterregion, setNewTestCenterregion] = useState("");
  const [newTestCenterzones, setNewTestCenterzones] = useState([]);
  const [newTestCenterzone, setNewTestCenterszone] = useState([]);

  const [newTestCenterworedas, setNewTestCenterworedas] = useState([]);
  const [newTestCenterworeda, setNewTestCenterworeda] = useState("");

  // const [selectedzones, setSelectedzones] = useState([])

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

 
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              borderRadius : 5,
              marginTop : 5
            }}
          >
            Search
          </Button>
          <Button
              onClick={() =>{
                clearFilters && handleReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex)
              }}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          {/*
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
    (record[dataIndex] === null || record[dataIndex] === undefined || record[dataIndex] === "") ? false :  record[dataIndex].toString().trim().toLowerCase().includes(value.toString().trim().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlight
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleChangeInstitutionRegionOnAdd = (e) => {
    setregion(JSON.parse(e));
    setzones(JSON.parse(e).zones);

    editTestCenterFormRef.resetFields(["woreda", "zone"]);
    addTestCenterFormRef.resetFields(["woreda", "zone"]);
  };

  const handleChangeInstitutionZoneOnAdd = (e) => {
    setzone(JSON.parse(e));
    setworedas(JSON.parse(e).woredas);

    editTestCenterFormRef.resetFields(["woreda"]);
    addTestCenterFormRef.resetFields(["woreda"]);
  };

  const handleChangeInstitutionWoredaChange = (e) => {
    setworeda(JSON.parse(e));
  };

  useEffect(() => {
    loadregions();
    loadTestCenters();
    return () => {
      // console.log('clean up')
    };
  }, []);

  const loadregions = async () => {
    setLoadedregions(false);
    setLoadingregions(true);
    let regionsData = await getregions();
    setregions(regionsData);
    // setSelectedzones(regionsData.zones)
    setLoadedregions(true);
    setLoadingregions(false);
  };

  const loadTestCenters = async () => {
    setLoadingTestCenters(false);
    setLoadingTestCenters(true);
    let testCenterData = await getTestCenters();
    setTestCenters(testCenterData);
    // setSelectedzones(regionsData.zones)
    setLoadedTestCenters(true);
    setLoadingTestCenters(false);
  };

  const handleChangeTestCenterCodeOnAdd = (e) => {
    setNewTestCenterCode(e.target.value);
  };

  const handleChangeTestCenterNameOnAdd = (e) => {
    setNewTestCenterName(e.target.value);
  };

  const handleChangeTestCenterPhoneOnAdd = (e) => {
    setNewTestCenterPhone(e.target.value);
  };

  const handleChangeTestCenterregionOnAdd = (e) => {
    setNewTestCenterregion(JSON.parse(e));
    setNewTestCenterzones(JSON.parse(e).zones);
  };

  const handleChangeTestCenterzoneOnAdd = (e) => {
    setNewTestCenterszone(JSON.parse(e));
    setNewTestCenterworedas(JSON.parse(e).woredas);
  };

  const handleChangeTestCenterworedaChange = (e) => {
    setNewTestCenterworeda(JSON.parse(e));
  };

  const [addTestCenterFormRef] = Form.useForm();
  const [editTestCenterFormRef] = Form.useForm();

  const showAddTestCenterModal = () => {
    setTestCentersAddFormVisible(true);
  };

  const confirmDeletion = (record) => {
    Modal.confirm({
      title: `Delete`,
      icon: <ExclamationCircleOutlined />,
      content: "Delete Testing Health Facility?",
      onOk: () => {
        deleteTestCenters(record.key)
          .then((val) => {
            if (val.success) {
              openNotificationWithIcon(
                "success",
                "Deleted!",
                "Deleted Test Facility Successfuly!"
              );
              loadTestCenters();
            } else {
              openNotificationWithIcon("warning", "Error!", val.message);
            }
          })
          .catch((err) => {
            openNotificationWithIcon(
              "error",
              "Error!",
              "Error Deleted Test Healyh Facility!"
            );
          });
      },
      onCancel() {},
    });
  };

  const handleSubmitAddTestCenter = async () => {
    await addTestCenterFormRef.validateFields();
    setConfirmLoading(true);
    // console.log({ newInstututionCode, newInstututionName, newInstututionPhone, newInstututionType, newInstututionzoneID: newInstututionzone, newInstututionregion })
    let result = await addTestCenters({
      code: newTestCenterCode,
      name: newTestCenterName,
      region: newTestCenterregion,
      phone_number: newTestCenterPhone,
      zone: newTestCenterzone,
      woreda: newTestCenterworeda,
    });
    if (result.success) {
      setTestCentersAddFormVisible(false);
      setConfirmLoading(false);
      addTestCenterFormRef.resetFields();
      loadTestCenters();
      openNotificationWithIcon(
        "success",
        "Added Facility!",
        "Added New Testing Health Facility Successfuly!"
      );
    } else {
      openNotificationWithIcon("error", "Duplicate Code!", result.message);
      setConfirmLoading(false);
    }
  };

  const handleSubmitEditTestCenter = async () => {
    await editTestCenterFormRef.validateFields();
    setConfirmLoading(true);
    let extra = {};
    extra.region =
      Object.keys(region).length > 0 ? region : selectedInstitution.region;
    extra.zone = Object.keys(zone).length > 0 ? zone : selectedInstitution.zone;
    extra.woreda =
      Object.keys(woreda).length > 0 ? woreda : selectedInstitution.woreda;

    if (
      testCenters.some(
        (tc) => tc.code.toUpperCase() === newTestCenterCode.toUpperCase()
      ) &&
      selectedInstitution.code.toUpperCase() !== newTestCenterCode.toUpperCase()
    ) {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Test center with this code is already added."
      );
      setConfirmLoading(false);
      return;
    }
    // return;

    // if(testCenters.some(testc => testc.code.toUpperCase() === newTestCenterCode.toUpperCase()) && ) {}

    // console.log(extra)
    // console.log({ newInstututionCode, newInstututionName, newInstututionPhone, newInstututionType, newInstututionzoneID: newInstututionzone, newInstututionregion })
    let success = await editTestCenter({
      id: testCenterId,
      code: newTestCenterCode,
      name: newTestCenterName,
      phone_number: newTestCenterPhone,
      extra,
    });
    if (success) {
      setEditModalVisible(false);
      setConfirmLoading(false);
      editTestCenterFormRef.resetFields();
      loadTestCenters();
      openNotificationWithIcon(
        "success",
        "Edited Testing Facility!",
        "Edited Test Facility Successfuly!"
      );
    } else {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Error on Editing Testing Facility. Pleaset try Again!"
      );
    }
  };

  const showEditModal = (record) => {
    editTestCenterFormRef.setFieldsValue({
      name: record.name,
      code: record.code,
      phone: record.phone_number,
    });
    // console.log(record)
    setNewTestCenterName(record.name);
    setNewTestCenterCode(record.code);
    setNewTestCenterPhone(record.phone_number);
    setEditModalVisible(true);
    setTestCenterId(record.key);

    setSelectedInstitution(record);
  };

  const cancelAddTestCenters = () => {
    addTestCenterFormRef.resetFields();
    setTestCentersAddFormVisible(false);
  };

  const cancelEditTestCenter = () => {
    editTestCenterFormRef.resetFields();
    setEditModalVisible(false);

    editTestCenterFormRef.resetFields();
    setEditModalVisible(false);

    setzone({});
    setregion({});
    setworeda({});

    addTestCenterFormRef.resetFields();
    editTestCenterFormRef.resetFields(["woreda", "zone", "region"]);
  };

  return (
    <>
      {errorLoading && <p>Error Loading...</p>}
      {loadingTestCenters && <LoadingComponent />}
      {loadedTestCenters && !loadingTestCenters && (
        <>
          <Button
            onClick={showAddTestCenterModal}
            className="add_btn"
            type="primary"
            size="large"
            shape="round"
            icon={<UserAddOutlined />}
          >
            Add New Testing Facility
          </Button>
          <Table dataSource={testCenters} style={{marginRight : 10}}>
            <Column
              title="Code"
              dataIndex="code"
              key="code"
              render={(code) => {
                return <Typography style={{ fontSize: 12 }}>{code}</Typography>;
              }}
            />
            <Column
              title="Name"
              dataIndex="name"
              {...getColumnSearchProps('name')}
              key="name"
              render={(code) => {
                return <Typography style={{ fontSize: 12 }}>{code}</Typography>;
              }}
            />
            <Column
              title="Region"
              dataIndex="region"
              // key="region"
              render={(region) => (
                <Typography style={{ fontSize: 12 }}>{region.name}</Typography>
              )}
            />

            <Column
              title="Zone"
              dataIndex="zone"
              key="zone"
              render={(zone) => (
                <Typography style={{ fontSize: 12 }}>
                  {zone.name !== undefined ? zone.name : ""}
                </Typography>
              )}
            />

            <Column
              title="Woreda"
              dataIndex="woreda"
              key="woreda"
              render={(woreda) => (
                <Typography style={{ fontSize: 12 }}>
                  {woreda && woreda.name !== undefined ? woreda.name : ""}
                </Typography>
              )}
            />

            {/* <Column title="region" dataIndex="region" key="region" /> */}
            {/* <Column title="zone" dataIndex="zone" key="zone" /> */}
            <Column
              title="Phone Number"
              dataIndex="phone_number"
              {...getColumnSearchProps('phone_number')}
              key="phone_number"
              render={(phone) => {
                return (
                  <Typography style={{ fontSize: 12 }}>{phone}</Typography>
                );
              }}
            />

            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <Space size="small">
                  <Button
                    style={{ fontSize: 12 }}
                    onClick={() => confirmDeletion(record)}
                    type="primary"
                    shape="round"
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                  >
                    Delete
                  </Button>

                  <Button
                    style={{ fontSize: 12 }}
                    onClick={() => showEditModal(record)}
                    type="primary"
                    shape="round"
                    size="small"
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Button>
                </Space>
              )}
            />
          </Table>

          <Modal
            title="Add Testing Health Facility"
            visible={testCentersAddFormVisible}
            onOk={handleSubmitAddTestCenter}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? cancelAddTestCenters : () => {}}
            closable={!confirmLoading}
          >
            <Form form={addTestCenterFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Code  "
                name="code"
                rules={[
                  { required: true, message: "Please enter facility code!" },
                ]}
              >
                <Input onChange={handleChangeTestCenterCodeOnAdd} />
              </Form.Item>

              <Form.Item
                label="Name  "
                name="name"
                rules={[
                  { required: true, message: "Please enter facility name!" },
                ]}
              >
                <Input onChange={handleChangeTestCenterNameOnAdd} />
              </Form.Item>

              <Form.Item
                label="Phone "
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number!",
                    validator: (_, value) => {
                      if (
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                          value
                        ) &&
                        value.startsWith("09") &&
                        value.length === 10
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid phone number"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input onChange={handleChangeTestCenterPhoneOnAdd} />
              </Form.Item>

              <Form.Item
                label="Region  "
                name="region"
                rules={[
                  {
                    required: true,
                    message: "Please enter where the facility region!",
                  },
                ]}
              >
                <Select
                  onChange={handleChangeTestCenterregionOnAdd}
                  style={{ width: 200 }}
                >
                  {regions.map((region) => {
                    return (
                      <Option value={JSON.stringify(region)}>
                        {region.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item label="Zone" name="zones">
                <Select
                  onChange={handleChangeTestCenterzoneOnAdd}
                  style={{ width: 200 }}
                >
                  {newTestCenterzones.map((zone) => {
                    return (
                      <Option value={JSON.stringify(zone)}>{zone.name}</Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item label="Woreda" name="woreda">
                <Select
                  onChange={handleChangeTestCenterworedaChange}
                  style={{ width: 200 }}
                >
                  {newTestCenterworedas.map((woreda) => {
                    return (
                      <Option value={JSON.stringify(woreda)}>
                        {woreda.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Edit Testing Health Facility"
            visible={editModalVisible}
            onOk={handleSubmitEditTestCenter}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? cancelEditTestCenter : () => {}}
            closable={!confirmLoading}
          >
            <Form form={editTestCenterFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Code  "
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please enter testing facility code!",
                  },
                ]}
              >
                <Input onChange={handleChangeTestCenterCodeOnAdd} />
              </Form.Item>

              <Form.Item
                label="Name  "
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter testing facility name!",
                  },
                ]}
              >
                <Input onChange={handleChangeTestCenterNameOnAdd} />
              </Form.Item>

              <Form.Item
                label="Phone "
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number!",
                    validator: (_, value) => {
                      if (
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                          value
                        ) &&
                        ( value.startsWith("09") || value.startsWith("07") ) &&
                        value.length === 10
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid phone number"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input onChange={handleChangeTestCenterPhoneOnAdd} />
              </Form.Item>

              <Form.Item
                label="Region"
                name="region"
                // rules={[{ required: true, message: 'Please select region!' }]}
              >
                <Select
                  onChange={handleChangeInstitutionRegionOnAdd}
                  style={{ width: 150 }}
                >
                  {regions.map((r) => {
                    return <Option value={JSON.stringify(r)}>{r.name}</Option>;
                  })}
                </Select>
                {selectedInstitution.region &&
                  `   ${selectedInstitution.region.name}`}
              </Form.Item>

              <Form.Item
                label="Zone"
                name="zone"
                // rules={[{ required: true, message: 'Please select Zone!' }]}
              >
                <Select
                  onChange={handleChangeInstitutionZoneOnAdd}
                  style={{ width: 150 }}
                >
                  {zones.map((zone) => {
                    return (
                      <Option value={JSON.stringify(zone)}>{zone.name}</Option>
                    );
                  })}
                </Select>
                {selectedInstitution.zone &&
                  `   ${selectedInstitution.zone.name}`}
              </Form.Item>

              <Form.Item
                label="Woreda"
                name="woreda"
                // rules={[{ required: true, message: 'Please select woreda!' }]}
              >
                <Select
                  onChange={handleChangeInstitutionWoredaChange}
                  style={{ width: 150 }}
                >
                  {woredas.map((woreda) => {
                    return (
                      <Option value={JSON.stringify(woreda)}>
                        {woreda.name}
                      </Option>
                    );
                  })}
                </Select>
                {selectedInstitution.woreda &&
                  `   ${selectedInstitution.woreda.name}`}
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};

export default TestCenters;
