import { getDocs, collection, query, where } from 'firebase/firestore'
import { auth, firestoreDatabase } from '../firebase';

const db = firestoreDatabase


export const getOrders = async () => {
    const querySnapshot = await getDocs(collection(db, "orders"));
    let orders = []
    querySnapshot.forEach((doc) => {
        orders = [...orders, { ...doc.data(), key: doc.id }]
    });
    // console.log(orders)
    return orders
}

export const getOrdersTestCenter = async () => {
    const q = query(collection(db, "orders"), where("tester_id", "==", await getTestCenterByAdminUID()));

    const querySnapshot = await getDocs(q);
    let orders = []
    querySnapshot.forEach((doc) => {
        orders = [...orders, { ...doc.data(), key: doc.id }]
    });
    // console.log(orders)
    return orders
}

export const getOrdersInstitution = async () => {
    // const q = query(collection(db, "orders"), where("sender_id", "==", auth.currentUser && auth.currentUser.uid));
    const querySnapshot = await getDocs(collection(db, "orders"));
    let orders = []
    querySnapshot.forEach((doc) => {
        orders = [...orders, { ...doc.data(), key: doc.id }]
    });
    // console.log(orders)
    return orders
}


const getTestCenterByAdminUID = async () => {
    let id = (auth.currentUser && auth.currentUser.uid) || ''
    const q = query(collection(firestoreDatabase, "users"), where("user_id", "==", id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
        let testCenterId = querySnapshot.docs && querySnapshot.docs[0] && querySnapshot.docs[0].data() && querySnapshot.docs[0].data()
        return testCenterId
    }
    return null
}
