import { Layout } from "antd";
import TopicMenu from "../components/TopMenu";
import "../App.css";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import React, { useState } from 'react'
// import { AuthContext } from "../contexts/auth_context";
import { logOut } from "../controllers/auth";
import { useNavigate } from "react-router-dom";
// import { auth } from "../firebase";
import TestCenterDashboard from "./TestCenterDashBoard";



const InstitutionHomepage = () => {
    // const [authData, setAuthData] = useContext(AuthContext);
    const navigate = useNavigate()

    // useEffect(() => {
    //     let unsubscribe = auth.onAuthStateChanged(() => {
    //         if (!auth.currentUser) {
    //             navigate('/')
    //         }
    //         else {

    //         }
    //         return () => {
    //             unsubscribe()
    //         }
    //     })

    // }, [])

    const elements = [
        {
            name: 'Dashboard',
            component: <TestCenterDashboard />,
            isPage: true,
            // onClick: () => { console.log('dashboard') }
        },

        {
            name: 'Sign Out',
            isPage: false,
            onClick: async () => {
                let status = await logOut()
                // console.log(status)
                // setAuthData({ authenticated: false, user: null })
                navigate('/')
            }
        }
    ]
    const [contentIndex, setContentIndex] = useState(0);
    const [selectedKey, setSelectedKey] = useState("0");
    const changeSelectedKey = (event) => {
        const key = event.key;
        setSelectedKey(key);
        setContentIndex(+key);
    };
    const Menu = (
        <TopicMenu
            elements={elements}
            selectedKey={selectedKey}
            changeSelectedKey={changeSelectedKey}
        />
    );
    return (
        <>
            <NavBar menu={Menu} />
            <Layout >
                <SideBar menu={Menu} />
                <Layout.Content style={{ maxWidth: '1100px', overflowX: 'auto' }} className="content">
                    {elements[contentIndex].isPage && elements[contentIndex].component}
                </Layout.Content>
            </Layout>
        </>
    )
}


export default InstitutionHomepage