import { Button, Result } from 'antd';
import { useNavigation } from 'react-router-dom';
const PageNotFound = () =>{
    return (
        <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        />
    );
}

export default PageNotFound;