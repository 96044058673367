// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'


// export const firebaseConfig = {
//     apiKey: "AIzaSyAo62ZimMRMjmkwjhzwM-Ux_cpiOPAGT7A",
//     authDomain: "kncv-360.firebaseapp.com",
//     projectId: "kncv-360",
//     storageBucket: "kncv-360.appspot.com",
//     messagingSenderId: "669099784203",
//     appId: "1:669099784203:web:b84c3187f51436382f2f0e",
//     measurementId: "${config.measurementId}"
// };


const firebaseConfig = {
    apiKey: "AIzaSyAo62ZimMRMjmkwjhzwM-Ux_cpiOPAGT7A",
    authDomain: "kncv-360.firebaseapp.com",
    projectId: "kncv-360",
    storageBucket: "kncv-360.appspot.com",
    messagingSenderId: "669099784203",
    appId: "1:669099784203:web:b84c3187f51436382f2f0e",
    //eslint-disable-next-line
    measurementId: "${config.measurementId}"
};

let app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
export const firestoreDatabase = getFirestore(app)
