import { Layout, Modal } from "antd";
import TopicMenu from "../components/TopMenu";
import "../App.css";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Dashboard from "../screens/Dashboard";
import Institutions from "../screens/Institutions";
import Users from "../screens/Users";
import TestCenters from "../screens/TestCenters";
import Locations from "../screens/Locations";
import React, { useState } from 'react'
// import { AuthContext } from "../contexts/auth_context";
import { logOut } from "../controllers/auth";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { USER_LOCAL_STORAGE_KEY } from "../config/AppConfig";
import { useMediaQuery } from 'react-responsive';

// import Modal from "antd/lib/modal/Modal";

const HomePage = () => {
  // const [authData, setAuthData] = useContext(AuthContext);
  const navigate = useNavigate()

  const isMobileOrTablet = useMediaQuery({ maxWidth: 1020 });

  const elements = [
    {
      name: 'Dashboard',
      component: <Dashboard />,
      isPage: true,
      // onClick: () => { console.log('dashboard') }
    },
    {
      name: 'Locations',
      isPage: true,
      component: <Locations />,
      // onClick: () => { console.log('dashboard') }
    },
    {
      name: 'Referring Health Facility',
      component: <Institutions />,
      isPage: true,
      // onClick: () => { console.log('dashboard') }
    },
    {
      name: 'Testing Health Facility',
      component: <TestCenters />,
      isPage: true,
      // onClick: () => { console.log('dashboard') }
    },
    {
      name: 'Users',
      component: <Users />,
      isPage: true,
      // onClick: () => { console.log('dashboard') }
    },

    // {
    //   name: 'Sign Out',
    //   isPage: false,
    //   onClick: async () => {

    //     Modal.confirm({
    //       title: `Sign Out`,
    //       icon: <ExclamationCircleOutlined />,
    //       content: 'Are you sure you want to sign out?',
    //       onOk: async () => {
    //         await logOut()
    //         localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    //         // console.log(status)
    //         // setAuthData({ authenticated: false, user: null })
    //         window.location.reload();
    //       },
    //       onCancel() {

    //       },

    //     });
    //   }
    // }
  ]
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");
  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
  };
  const Menu = (
    <TopicMenu
      elements={elements}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );
  return (
    <>
      <NavBar menu={Menu} />
      <Layout>
        <SideBar menu={Menu} />
       {
        isMobileOrTablet ? <Layout.Content style={{overflowX: 'auto', marginTop : 80}} className="content">
        {elements[contentIndex].isPage && elements[contentIndex].component}
      </Layout.Content> : <Layout.Content style={{minHeight : '100vh', overflowX: 'auto',  marginLeft : 210, marginTop : 80}} className="content">
          {elements[contentIndex].isPage && elements[contentIndex].component}
        </Layout.Content>
       } 
      </Layout>
    </>
  )
}


export default HomePage