import {notification} from 'antd'

const openNotificationWithIcon = (type, messsage, description) => {
    notification[type]({
        message: messsage,
        description:
            description,
    });
};

export default openNotificationWithIcon