import { auth, firestoreDatabase } from "../firebase";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, deleteUser, updatePassword } from 'firebase/auth'
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";

const db = firestoreDatabase

export const signUp = async (email, password) => {
    let user = await createUserWithEmailAndPassword(auth, email, password)
    if (user) {
        return { success: true, user: user.user }
    }
    return { success: false, message: 'Error!' }

}

export const signIn = async (username, password) => {
    let user = await signInWithEmailAndPassword(auth, username, password)
    if (user) {
        // console.log(user)
        return { success: true, user }
    }
    return { success: false }
}

export const changePassword = async (username, password, newPassword) => {

    // console.log({ username, password, newPassword })

    let user
    try {
        user = await signInWithEmailAndPassword(auth, username + '@kncv.com', password)
        // console.log(user)
    } catch (error) {
        // console.log(error)
        return { success: false, messahe: 'No user found with this credential!' }
    }
    if (user) {
        try {

            const q = query(collection(db, "users"), where("username", "==", username + '@kncv.com'));
            const userDocs = await getDocs(q)
            // console.log(userDocs.docs.map(doc => doc.data()))
            if (userDocs.docs.length > 0) {
                await updatePassword(user.user, newPassword)
                let u = doc(db, "users", userDocs.docs[0].id)

                await setDoc(u, { password: newPassword }, { merge: true })
                return { success: true }
            }
            else {
                return { success: false, messahe: 'No user found with this credential!' }
            }


        } catch (error) {
            // console.log(error)
            return { success: false, messahe: 'Something went wrong please try again.' }
        }
    }
    else {
        return { success: false, messahe: 'No user found with this credential!' }
    }
}

export const getUser = async (id) => {
    let userRef = doc(db, 'users', id)
    let user = await getDoc(userRef)
    return user.data()
}

export const deleteRegisteredUser = async (user) => {
    await deleteUser(user)
    return true
}

export const logOut = async () => {
    // console.log(auth.currentUser)
    await signOut(auth)
    return true

}