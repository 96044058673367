import React, { useState } from "react";
import { Drawer, Button, Image, Modal } from "antd";
import { LogoutOutlined, MenuOutlined, ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import "../styles/navbar.css";
import BegizeLogo from '../assets/Begize.svg';
import { logOut } from "../controllers/auth";
import { USER_LOCAL_STORAGE_KEY } from "../config/AppConfig";
import { Avatar, Dropdown, } from 'antd';
import { getCurrentUserFullName } from "../utils/CurrentUserUtil";
const NavBar = ({ menu }) => {
    const [visible, setVisible] = useState(false);
    const currentUserFullName = getCurrentUserFullName();
    const items = [
        {
          label: currentUserFullName.replace("+", " "),
          key: '1',
          icon: <UserOutlined />,
        },
        {
            label : "Logout",
            key : '2',
            icon : <LogoutOutlined/>
        }
      ];

      const menuProps = {
        items,
        onClick: handleMenuClick,
      };

      function handleMenuClick(item){
        if(item.key == 2){
            Modal.confirm({
                title: `Sign Out`,
                icon: <ExclamationCircleOutlined />,
                content: 'Are you sure you want to sign out?',
                onOk: async () => {
                  await logOut()
                  localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
                  // console.log(status)
                  // setAuthData({ authenticated: false, user: null })
                  window.location.reload();
                },
                onCancel() {
      
                },
      
              });
        }
      }
    return (
        <nav className="navbar" style={{ position: 'fixed', zIndex: 1, width: '100%'}}>
            <Dropdown menu={menuProps}>
                <Avatar  style={{float : 'right', marginRight : 40, cursor : 'pointer'}} src={<img src={'https://ui-avatars.com/api/?name=' + currentUserFullName} alt="avatar"/>}/>
            </Dropdown>
            <Button
                className="menu"
                type="primary"
                icon={<MenuOutlined />}
                onClick={() => setVisible(true)}
            />
            <Drawer
                title="Menu"
                placement="left"
                onClick={() => setVisible(false)}
                onClose={() => setVisible(false)}
                visible={visible}
            >
                {menu}
            </Drawer>
            {/* <Title level={3} >KNCV Logo  <span><GlobalOutlined color='#444444' /></span></Title> */}
            {/* <Title level={3} ><KncvLogo />  <span><GlobalOutlined color='#444444' /></span></Title> */}
            {/* <KncvLogo /> */}
            <Image src={BegizeLogo} preview={false} />
        </nav>
    );
};
export default NavBar;