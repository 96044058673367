import React, { useState, useEffect,useRef } from "react";
import {
  Table,
  Space,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
  ExclamationCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { getregions } from "../controllers/location";
import {
  addInstitution,
  deleteInstitution,
  editInstitutions,
  getInstitutions,
} from "../controllers/institutions";
import LoadingComponent from "../components/Loading";
import openNotificationWithIcon from "../notification";
import Highlight from "react-syntax-highlighter/dist/esm/highlight";

const { Option } = Select;
const { Column } = Table;

const Institution = () => {
  const [instututionFormvisible, setInstututionModalvisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [institutionId, setInstitutionId] = useState(false);

  const [loadingregions, setLoadingregions] = useState(true);
  const [loadedregions, setLoadedregions] = useState(false);
  const [regions, setregions] = useState([]);
  const [institutions, setInstitutions] = useState([]);

  const [selectedInstitution, setSelectedInstitution] = useState({});

  const [loadingInstitutions, setLoadingInstitutions] = useState(true);
  const [loadedInstitutions, setLoadedInstitutions] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const [newInstututionCode, setNewInstitutionCode] = useState("");
  const [newInstututionName, setNewInstitutionName] = useState("");
  const [newInstututionPhone, setNewInstitutionPhone] = useState("");
  const [newInstututionType, setNewInstitutionType] = useState("");
  const [newInstututionregion, setNewInstitutionregion] = useState("");
  const [newInstututionzones, setNewInstitutionzones] = useState([]);
  const [newInstututionzone, setNewInstitutionzone] = useState("");
  const [newInstututionworedas, setNewInstitutionworedas] = useState([]);
  const [newInstututionworeda, setNewInstitutionworeda] = useState("");

  const [zone, setzone] = useState({});
  const [woreda, setworeda] = useState({});
  const [region, setregion] = useState({});
  const [zones, setzones] = useState([]);
  const [woredas, setworedas] = useState([]);

  const [editInstitutinModalVisible, setEditInstitutinModalVisible] =
    useState(false);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
  
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText("");
    };
  
   
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
                borderRadius : 5,
                marginTop : 5
              }}
            >
              Search
            </Button>
            <Button
              onClick={() =>{
                clearFilters && handleReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex)
              }}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            {/*
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button> */}
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
      (record[dataIndex] === null || record[dataIndex] === undefined || record[dataIndex] === "") ? false :  record[dataIndex].toString().trim().toLowerCase().includes(value.toString().trim().toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlight
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });


  const handleChangeInstitutionRegionOnAdd = (e) => {
    setregion(JSON.parse(e));
    setzones(JSON.parse(e).zones);

    editInstitutionFormRef.resetFields(["woreda", "zone"]);
    addInstitutionFormRef.resetFields(["woreda", "zone"]);
  };

  const handleChangeInstitutionZoneOnAdd = (e) => {
    setzone(JSON.parse(e));
    setworedas(JSON.parse(e).woredas);

    editInstitutionFormRef.resetFields(["woreda"]);
    addInstitutionFormRef.resetFields(["woreda"]);
  };

  const handleChangeInstitutionWoredaChange = (e) => {
    setworeda(JSON.parse(e));
  };

  // const [selectedzones, setSelectedzones] = useState([])

  useEffect(() => {
    loadregions();
    loadInstitutions();
    return () => {
      // console.log('clean up')
    };
  }, []);

  const loadregions = async () => {
    setLoadedregions(false);
    setLoadingregions(true);
    let regionsData = await getregions();
    setregions(regionsData);
    // setSelectedzones(regionsData.zones)
    setLoadedregions(true);
    setLoadingregions(false);
  };

  const loadInstitutions = async () => {
    setLoadedInstitutions(false);
    setLoadingInstitutions(true);
    let institutionsData = await getInstitutions();
    setInstitutions(institutionsData);
    // setSelectedzones(regionsData.zones)
    setLoadedInstitutions(true);
    setLoadingInstitutions(false);
  };

  const handleChangeInstitutionCodeOnAdd = (e) => {
    setNewInstitutionCode(e.target.value);
  };

  const handleChangeInstitutionNameOnAdd = (e) => {
    setNewInstitutionName(e.target.value);
  };

  const handleChangeInstitutionPhoneOnAdd = (e) => {
    setNewInstitutionPhone(e.target.value);
  };

  const handleChangeInstitutionTypeOnAdd = (e) => {
    setNewInstitutionType(e);
  };

  const handleChangeInstitutionregionOnAdd = (e) => {
    setNewInstitutionregion(JSON.parse(e));
    setNewInstitutionzones(JSON.parse(e).zones);
  };

  const handleChangeInstitutionzoneOnAdd = (e) => {
    setNewInstitutionzone(JSON.parse(e));
    setNewInstitutionworedas(JSON.parse(e).woredas);
  };

  const handleChangeInstitutionworedaChange = (e) => {
    setNewInstitutionworeda(JSON.parse(e));
  };

  const [addInstitutionFormRef] = Form.useForm();
  const [editInstitutionFormRef] = Form.useForm();

  const showAddInstitutionModal = () => {
    setInstututionModalvisible(true);
  };

  const showEditModal = (record) => {
    editInstitutionFormRef.setFieldsValue({
      name: record.name,
      code: record.code,
      type: record.type,
      phone: record.phone_number,
    });
    // console.log(record)
    setNewInstitutionName(record.name);
    setNewInstitutionCode(record.code);
    setNewInstitutionPhone(record.phone_number);
    setNewInstitutionType(record.type);
    setEditInstitutinModalVisible(true);
    setInstitutionId(record.key);

    setSelectedInstitution(record);
  };

  const confirmDeletion = (record) => {
    Modal.confirm({
      title: `Delete`,
      icon: <ExclamationCircleOutlined />,
      content: "Delete institution?",
      onOk: () => {
        deleteInstitution(record.key)
          .then((val) => {
            if (val.success) {
              loadInstitutions();
              openNotificationWithIcon(
                "success",
                "Success!",
                "Deleted Health Facility Successfuly!"
              );
            } else {
              // console.log('Here......', val)
              openNotificationWithIcon("warning", "Can't Delete!", val.message);
            }
          })
          .catch((err) => {
            // console.log(err)
            openNotificationWithIcon(
              "error",
              "Error!",
              "Error Deleting Institution!"
            );
          });
      },
      onCancel() {},
    });
  };

  const handleSubmitAddInstitution = async () => {
    await addInstitutionFormRef.validateFields();
    setConfirmLoading(true);
    let result = await addInstitution({
      code: newInstututionCode,
      name: newInstututionName,
      type: newInstututionType,
      region: newInstututionregion,
      phone_number: newInstututionPhone,
      zone: newInstututionzone,
      woreda: newInstututionworeda,
    });
    if (result.success) {
      setInstututionModalvisible(false);
      setConfirmLoading(false);
      addInstitutionFormRef.resetFields();
      openNotificationWithIcon(
        "success",
        "Success!",
        "Added New Health Facility Successuly!"
      );
      loadInstitutions();
    } else {
      openNotificationWithIcon("error", "Duplicate Code!", result.message);
      setConfirmLoading(false);
    }
  };

  const handleSubmitEditInstitution = async () => {
    await editInstitutionFormRef.validateFields();
    setConfirmLoading(true);
    // console.log(zone, region, woreda)
    let extra = {};
    extra.region =
      Object.keys(region).length > 0 ? region : selectedInstitution.region;
    extra.zone = Object.keys(zone).length > 0 ? zone : selectedInstitution.zone;
    extra.woreda =
      Object.keys(woreda).length > 0 ? woreda : selectedInstitution.woreda;
    // console.log(extra)

    if (
      institutions.some(
        (tc) => tc.code.toUpperCase() === newInstututionCode.toUpperCase()
      ) &&
      selectedInstitution.code.toUpperCase() !==
        newInstututionCode.toUpperCase()
    ) {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Facility with this code already exists."
      );
      setConfirmLoading(false);
      return;
    }

    // return
    let success = await editInstitutions({
      code: newInstututionCode,
      name: newInstututionName,
      type: newInstututionType,
      phone_number: newInstututionPhone,
      id: institutionId,
      extra,
    });
    if (success) {
      setEditInstitutinModalVisible(false);
      setConfirmLoading(false);
      editInstitutionFormRef.resetFields();
      openNotificationWithIcon(
        "success",
        "Edited!",
        "Edited Health Facility Successuly!"
      );
      loadInstitutions();
    } else {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Error Editing Health Facility. Please try Again!"
      );
    }
  };

  const cancelAddInstitution = () => {
    addInstitutionFormRef.resetFields();
    editInstitutionFormRef.resetFields(["woreda", "zone", "region"]);

    setInstututionModalvisible(false);
  };

  const cancelEditInstitution = () => {
    editInstitutionFormRef.resetFields();
    setEditInstitutinModalVisible(false);

    setzone({});
    setregion({});
    setworeda({});

    addInstitutionFormRef.resetFields();
    editInstitutionFormRef.resetFields(["woreda", "zone", "region"]);
  };

  return (
    <>
      {errorLoading && <p>Error Loading...</p>}
      {loadingInstitutions && <LoadingComponent />}
      {loadedInstitutions && !loadingInstitutions && (
        <>
          <Button
            onClick={showAddInstitutionModal}
            className="add_btn"
            type="primary"
            size="large"
            shape="round"
            icon={<UserAddOutlined />}
          >
            Add New Health Facility
          </Button>
          <Table dataSource={institutions} style={{marginRight : 10}}>
            <Column
              title="Code"
              dataIndex="code"
              key="key"
              render={(code) => {
                return <Typography style={{ fontSize: 12 }}>{code}</Typography>;
              }}
            />
            <Column
              title="Name"
              dataIndex="name"
              {...getColumnSearchProps('name')}
              key="key"
              render={(code) => {
                return <Typography style={{ fontSize: 12 }}>{code}</Typography>;
              }}
            />
            <Column
              title="Type"
              dataIndex="type"
              key="key"
              render={(code) => {
                return <Typography style={{ fontSize: 12 }}>{code}</Typography>;
              }}
            />
            <Column
              title="Region"
              dataIndex="region"
              key="key"
              render={(region) => (
                <Typography style={{ fontSize: 12 }}>{region.name}</Typography>
              )}
            />

            <Column
              title="Zone"
              dataIndex="zone"
              key="key"
              render={(zone) => (
                <Typography style={{ fontSize: 12 }}>
                  {zone && zone.name !== undefined ? zone.name : ""}
                </Typography>
              )}
            />

            <Column
              title="Woreda"
              dataIndex="woreda"
              key="key"
              render={(woreda) => (
                <Typography style={{ fontSize: 12 }}>
                  {woreda && woreda.name !== undefined ? woreda.name : ""}
                </Typography>
              )}
            />

            <Column
              title="Phone Number"
              dataIndex="phone_number"
              {...getColumnSearchProps('phone_number')}
              key="key"
              render={(phone) => (
                <Typography style={{ fontSize: 12 }}>{phone}</Typography>
              )}
            />

            <Column
              title="Action"
              key="key"
              render={(text, record) => (
                <Space size="middle">
                  <Button
                    style={{ fontSize: 12 }}
                    onClick={() => confirmDeletion(record)}
                    type="primary"
                    shape="round"
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                  >
                    Delete
                  </Button>

                  <Button
                    style={{ fontSize: 12 }}
                    onClick={() => showEditModal(record)}
                    type="primary"
                    shape="round"
                    size="small"
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Button>
                </Space>
              )}
            />
          </Table>

          <Modal
            title="Add Referring Health Facility"
            open={instututionFormvisible}
            onOk={handleSubmitAddInstitution}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? cancelAddInstitution : () => {}}
            closable={!confirmLoading}
          >
            <Form form={addInstitutionFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Code  "
                name="code"
                rules={[
                  { required: true, message: "Please enter institution code!" },
                ]}
              >
                <Input onChange={handleChangeInstitutionCodeOnAdd} />
              </Form.Item>

              <Form.Item
                label="Name  "
                name="name"
                rules={[
                  { required: true, message: "Please enter institution name!" },
                ]}
              >
                <Input onChange={handleChangeInstitutionNameOnAdd} />
              </Form.Item>

              <Form.Item
                label="Phone "
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number!",
                    validator: (_, value) => {
                      let a = "";
                      if (
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                          value
                        ) &&
                        value.startsWith("09") &&
                        value.length === 10
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid phone number"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input onChange={handleChangeInstitutionPhoneOnAdd} />
              </Form.Item>

              <Form.Item
                label="Type  "
                name="type"
                rules={[
                  { required: true, message: "Please enter institution type!" },
                ]}
              >
                <Select
                  onChange={handleChangeInstitutionTypeOnAdd}
                  style={{ width: 200 }}
                >
                  <Option value="public_hospital">Public Hospital</Option>
                  <Option value="private_hospital">Private Hospital</Option>
                  <Option value="public_health_center">
                    Public Health Center
                  </Option>
                  <Option value="private_health_center">
                    Private Health Center
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Region  "
                name="region"
                rules={[
                  {
                    required: true,
                    message: "Please enter where the institution resides!",
                  },
                ]}
              >
                <Select
                  onChange={handleChangeInstitutionregionOnAdd}
                  style={{ width: 200 }}
                >
                  {regions.map((region) => {
                    return (
                      <Option value={JSON.stringify(region)}>
                        {region.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Zone "
                name="zones"
                rules={[
                  {
                    required: true,
                    message: "Please select the facility zone!",
                  },
                ]}
              >
                <Select
                  onChange={handleChangeInstitutionzoneOnAdd}
                  style={{ width: 200 }}
                >
                  {newInstututionzones.map((zone) => {
                    return (
                      <Option value={JSON.stringify(zone)}>{zone.name}</Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Woreda "
                name="woreda"
                rules={[
                  {
                    required: true,
                    message: "Please select the facility woreda!",
                  },
                ]}
              >
                <Select
                  onChange={handleChangeInstitutionworedaChange}
                  style={{ width: 200 }}
                >
                  {newInstututionworedas.map((woreda) => {
                    return (
                      <Option value={JSON.stringify(woreda)}>
                        {woreda.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Edit Referring Health Facility"
            visible={editInstitutinModalVisible}
            onOk={handleSubmitEditInstitution}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? cancelEditInstitution : () => {}}
            closable={!confirmLoading}
          >
            <Form form={editInstitutionFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Code  "
                name="code"
                rules={[
                  { required: true, message: "Please enter institution code!" },
                ]}
              >
                <Input onChange={handleChangeInstitutionCodeOnAdd} />
              </Form.Item>

              <Form.Item
                label="Name  "
                name="name"
                rules={[
                  { required: true, message: "Please enter institution name!" },
                ]}
              >
                <Input onChange={handleChangeInstitutionNameOnAdd} />
              </Form.Item>

              <Form.Item
                label="Phone "
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number!",
                    validator: (_, value) => {
                      if (
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                          value
                        ) &&
                        value.startsWith("09") &&
                        value.length === 10
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid phone number"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input onChange={handleChangeInstitutionPhoneOnAdd} />
              </Form.Item>

              <Form.Item
                label="Region"
                name="region"
                // rules={[{ required: true, message: 'Please select region!' }]}
              >
                <Select
                  onChange={handleChangeInstitutionRegionOnAdd}
                  style={{ width: 150 }}
                  defaultValue={region}
                >
                  {regions.map((r) => {
                    return <Option value={JSON.stringify(r)}>{r.name}</Option>;
                  })}
                </Select>
                {selectedInstitution.region &&
                  `   ${selectedInstitution.region.name}`}
              </Form.Item>

              <Form.Item
                label="Zone"
                name="zone"
                // rules={[{ required: true, message: 'Please select Zone!' }]}
              >
                <Select
                  onChange={handleChangeInstitutionZoneOnAdd}
                  style={{ width: 150 }}
                >
                  {zones.map((zone) => {
                    return (
                      <Option value={JSON.stringify(zone)}>{zone.name}</Option>
                    );
                  })}
                </Select>
                {selectedInstitution.zone &&
                  `   ${selectedInstitution.zone.name}`}
              </Form.Item>

              <Form.Item
                label="Woreda"
                name="woreda"
                // rules={[{ required: true, message: 'Please select woreda!' }]}
              >
                <Select
                  onChange={handleChangeInstitutionWoredaChange}
                  style={{ width: 150 }}
                >
                  {woredas.map((woreda) => {
                    return (
                      <Option value={JSON.stringify(woreda)}>
                        {woreda.name}
                      </Option>
                    );
                  })}
                </Select>
                {selectedInstitution.woreda &&
                  `   ${selectedInstitution.woreda.name}`}
              </Form.Item>

              <Form.Item
                label="Type  "
                name="type"
                rules={[
                  { required: true, message: "Please enter institution type!" },
                ]}
              >
                <Select
                  onChange={handleChangeInstitutionTypeOnAdd}
                  style={{ width: 200 }}
                >
                  <Option value="public_hospital">Public Hospital</Option>
                  <Option value="private_hospital">Private Hospital</Option>
                  <Option value="public_health_center">
                    Public Health Center
                  </Option>
                  <Option value="private_health_center">
                    Private Health Center
                  </Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};

export default Institution;
