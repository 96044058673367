import React from "react";
import { Layout } from "antd";
import "../styles/sidebar.css"
const SideBar = ({ menu }) => {
    return (
        <Layout.Sider
            style={{ position: 'fixed', height: '100vh', left: 0 ,marginTop : 75}}
            className="sidebar"
            breakpoint={"lg"}
            theme="light"
            collapsedWidth={0}
            trigger={null}
        >
            {menu}
        </Layout.Sider>
    );
};
export default SideBar;