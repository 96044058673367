import React, { useState, createContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = props => {
    const [authData, setAuthData] = useState({ authenticated: false, user: null });

    return (
        <AuthContext.Provider value={[authData, setAuthData]}>
            {props.children}
        </AuthContext.Provider>
    );
};
