import { collection, getDocs, query, where } from "firebase/firestore";
import { getTimeDifference } from "../config/getMinuteDifference";
import { firestoreDatabase } from "../firebase";
import moment from "moment";

export const numberOfSpecimentReferred = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Waiting for Confirmation',
        'Confirmed',
        'Picked Up',
        'Arrived',
        'Confirmed',
        'Received',
        'Delivered',
        'Accepted',
        'Inspected',
        'Tested'
    ]));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimensCount = patient.specimens != null ? patient.specimens.length : 0
            count += specimensCount
        })
    })

    return count
}

export const numberOfSpecimenConfirmed = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Picked Up',
        // 'Confirmed',
        'Arrived',
        // 'Confirmed',
        'Received',
        'Delivered',
        'Tested',
        'Accepted',
        'Inspected',
        'Delivered',
    ]));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimensCount = patient.specimens != null ? patient.specimens.length : 0
            count += specimensCount
        })
    })

    return count
}

export const convertToMMHH = (MINUTES) => {

    let m = MINUTES % 60;

    let h = (MINUTES - m) / 60;

    m = Math.floor(m)
    h = Math.floor(h)

    return h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
}

export const numberOfSpecimentReceived = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Received',
        'Tested',
        'Accepted',
        'Inspected'
    ]));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimensCount = patient.specimens != null ? patient.specimens.length : 0
            count += specimensCount
        })
    })

    return count
}

export const numberOfSpecimensTested = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Tested',
    ]));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimens = patient.specimens || []
            specimens.forEach(specimen => {
                if (specimen['result'] && patient['status'] === 'Tested') {
                    count += 1
                }
            })
        })
    })

    return count
}



// const getAgeCategory = (data) => {
//     if (data['age'] >= 0 && data['age'] < 6) {
//         return '0-6'
//     }
//     else if (data['age'] >= 6 && data['age'] < 12) {
//         return '6-12'
//     }
//     else if (data['age'] >= 12 && data['age'] < 24) {
//         return '12-24'
//     }
//     else if (data['age'] >= 24 && data['age'] < 48) {
//         return '24-48'
//     }
//     else if (data['age'] >= 48) {
//         return '24-48'
//     }
//     return ''
// }


export const overallData = (data, ps, os) => {
    // console.log('Called overall Data', data)

    if (data) {
        // let maleSputum = data.filter(d => d['sex'] === 'Male' && d['type'] === 'Sputum').length
        // let femaleSputum = data.filter(d => d['sex'] === 'Female' && d['type'] === 'Sputum').length

        // let maleStool = data.filter(d => d['sex'] === 'Male' && d['type'] === 'Stool').length
        // let femaleStool = data.filter(d => d['sex'] === 'Female' && d['type'] === 'Stool').length

        let maleSputum = 0;
        let femaleSputum = 0;
        let maleStool = 0;
        let femaleStool = 0;

        var mtbNotDetected = 0

        var rifResDetected = 0
        var rifiResNotDetected = 0

        var rifResIndeterminate = 0
        
        let stoolUnder15  = 0;
        let stoolAbove15  = 0;
        let sputumUnder15 = 0;
        let sputumAbove15 = 0;
        let urineUnder15  = 0;
        let urineAbove15  = 0;
        let bloodUnder15  = 0;
        let bloodAbove15  = 0;
        
            ps.forEach(patient => {
                let specimens = patient.specimens || []
                specimens.forEach(specimen => {

                        if (specimen['result'] &&  ( patient['age'] ?? 0 ) <= 15 && specimen['type'] === 'Stool') {
                            stoolUnder15 += 1
                        } else if(specimen['result'] && ( patient['age'] ?? 0 ) > 15 && specimen['type'] === 'Stool'){
                            stoolAbove15 += 1
                        }
                        else if(specimen['result'] && ( patient['age'] ?? 0 ) <= 15 && specimen['type'] === 'Sputum'){
                            sputumUnder15 += 1;
                        }
                        else if(specimen['result'] && ( patient['age'] ?? 0 ) > 15 && specimen['type'] === 'Sputum'){
                            sputumAbove15 += 1;
                        }
                        else if(specimen['result'] && ( patient['age'] ?? 0 ) <= 15 && specimen['type'] === 'Urine'){
                            urineUnder15 += 1;
                        }
                        else if(specimen['result'] && ( patient['age'] ?? 0 ) > 15 && specimen['type'] === 'Urine'){
                            urineAbove15 += 1;
                        }
                        else if(specimen['result'] && ( patient['age'] ?? 0 ) <= 15 && specimen['type'] === 'Blood'){
                            bloodUnder15 += 1;
                        }
                        else if(specimen['result'] && ( patient['age'] ?? 0 ) > 15 && specimen['type'] === 'Blood'){
                            bloodAbove15 += 1;
                        }
                        
                        if(patient['sex'] === 'Male' && specimen['type'] === 'Sputum'){
                            maleSputum += 1;
                        } else if(patient['sex'] === 'Female' && specimen['type'] === 'Sputum'){
                            femaleSputum += 1;
                        } else if(patient['sex'] === 'Male' && specimen['type'] === 'Stool'){
                            maleStool += 1;
                        } else if(patient['sex'] === 'Female' && specimen['type'] === 'Stool'){
                            femaleStool += 1;
                        }

                })
            });

        // let zeroToSixStool = data.filter(d => d['age'] > 15 && d['type'] === 'Blood').length

        let zeroToSix = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }
        let sixToTwelve = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }
        let twelveToTwentyFour = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }
        let twentyFourToFourtyEight = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }
        let aboveFourtyEight = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }

        const getTimeCategory = (val) => {
            if (val >= 0 && val < 6) {
                return zeroToSix
            }
            else if (val >= 6 && val < 12) {
                return sixToTwelve
            }
            else if (val >= 12 && val < 24) {
                return twelveToTwentyFour
            }
            else if (val >= 24 && val < 48) {
                return twentyFourToFourtyEight
            }
            else if (data['age'] >= 48) {
                return aboveFourtyEight
            } else {
                return zeroToSix
            }
            return {}
        }


        os.forEach(data => {
            let patients = data['patients'] || []
            patients.forEach(patient => {
                let specimens = patient.specimens || []
                specimens.forEach(specimen => {
                    if (specimen['result'] && patient['status'] === 'Tested') {
                        let specimenCreated = moment.unix(data.order_created.seconds);
        
                        let specimenTested =  moment(specimen["testResultAddedAt"]);
                        let turnArundTime = getTimeDifference(specimenCreated, specimenTested)
                        
                        getTimeCategory(turnArundTime)[specimen['type']] += 1
                
                    }
                })
            })
        })

        // data.forEach(d => {
        //     // console.log('Test Result Added At', d['testResultAddedAt'])
        //     let specimenCreated = d['order_created'].toDate()
        //     if (d['testResultAddedAt']) {
        //         let specimenTested = new Date(d['testResultAddedAt'])
        //         let turnArundTime = getTimeDifference(specimenCreated, specimenTested)
        //         //console.log('turn around time: ' + turnArundTime);
        //         getTimeCategory(turnArundTime)[d['type']] += 1
        //     }
        // })


        let results = []

        data.forEach(ordersData => {
            results = [...results, ...ordersData['specimens']]
        })

        results = [...new Set(results)]
        results = results.map(s => s['result']).filter(val => val)

        results.forEach(result => {

            switch (result['mtb_result']) {
                case 'MTB Detected':
                    if (result['result_rr'] === 'Rif Res Detected') {
                        // console.log(1)
                        rifResDetected += 1
                    }
                    else if (result['result_rr'] === 'Rif Res Not Detected') {
                        rifiResNotDetected += 1
                        // console.log(1)
                    }
                    else if (result['result_rr'] === 'Rif Res Indeterminate') {
                        rifResIndeterminate += 1
                        // console.log(1)
                    }
                    break

                case 'MTB Not Detected':
                    mtbNotDetected += 1
                    break
                default:
                    break
            }
        })

        return {
            maleSputum, femaleSputum, maleStool, femaleStool,
            mtbNotDetected,
            rifResDetected,
            rifiResNotDetected,
            rifResIndeterminate,
            numberOfSpecimens: results.length,
            stoolUnder15, stoolAbove15, sputumUnder15, sputumAbove15, urineAbove15, urineUnder15, bloodUnder15, bloodAbove15,
            zeroToSix, sixToTwelve, twelveToTwentyFour, twentyFourToFourtyEight: twentyFourToFourtyEight, aboveFourtyEight
        }
    }
}

  
// const getTestCenterByAdminUID = async () => {
//     let id = auth.currentUser.uid
//     const q = query(collection(firestoreDatabase, "users"), where("user_id", "==", id));
//     const querySnapshot = await getDocs(q);
//     if (querySnapshot.docs.length > 0) {
//         let testCenterId = querySnapshot.docs && querySnapshot.docs[0] && querySnapshot.docs[0].data() && querySnapshot.docs[0].data()
//         return testCenterId
//     }
//     return null
// }
