import React from "react";
import {Menu} from "antd";

const TopicMenu = ({ elements, selectedKey, changeSelectedKey}) => {
  const styledTopics = [];
  elements.forEach((element, index) =>
    styledTopics.push(
      <Menu.Item   key={index} onClick={ element.isPage ? changeSelectedKey : element.onClick}>
        {element.name}
      </Menu.Item>
    )
  );
  
  return (
    <Menu mode="inline" selectedKeys={[selectedKey]}>
      {styledTopics}
    </Menu>
  );
}
export default TopicMenu;