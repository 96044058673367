import { Form, Input, Button, Checkbox, Typography, Image } from 'antd';
import React, { useState, useContext, useEffect } from 'react'
import { getUser, signIn } from '../controllers/auth';
import '../App.css'
import { AuthContext } from '../contexts/auth_context';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import BegizeLogo from '../assets/Begize.svg';
import { USER_FULLNAME_KEY, USER_LOCAL_STORAGE_KEY, USER_LOGGED_IN_TIME } from '../config/AppConfig';
import moment from 'moment';



const LoginPage = () => {

    const [error, setError] = useState(false)
    const [loadingAuth, setLoadingAuth] = useState(false)
    const [authData, setAuthData] = useContext(AuthContext);
    const navigate = useNavigate()
    const onFinish = async (values) => {
        let { username, password } = values
        if (!username.endsWith('@gmail.com') && !username.endsWith('@kncv.com')) {
            username = username + '@kncv.com'
        }
        setLoadingAuth(true)
        signIn(username, password).then(status => {
            getUser(auth.currentUser.uid).then(user => {
                localStorage.setItem(USER_LOCAL_STORAGE_KEY,user);
                localStorage.setItem(USER_FULLNAME_KEY, user.name);
                localStorage.setItem(USER_LOGGED_IN_TIME, moment().format("YYYY-MM-DD HH:mm"));
                if (user && (user.type === 'superadmin' || user.type === 'ZONAL_ADMIN')) {
                    setError('')
                    setLoadingAuth(false)
                    setAuthData({ authenticated: true, user: status.user })
                    navigate('/home')
                    window.location.reload();
                }
                else if (user && user.type === 'INSTITUTIONAL_ADMIN') {
                    setError('')
                    setLoadingAuth(false)
                    setAuthData({ authenticated: true, user: status.user })
                    navigate('/health-facility')
                    window.location.reload();
                }

                else if (user && user.type === 'TEST_CENTER_ADMIN') {
                    setError('')
                    setLoadingAuth(false)
                    setAuthData({ authenticated: true, user: status.user })
                    navigate('/tester')
                }
                else {
                    setError('Incorrect password or username!')
                    setLoadingAuth(false)
                    window.location.reload();
                }
            })
            setLoadingAuth(false)

        }).catch(err => {
            setError('Incorrect password or username!')
            setLoadingAuth(false)

        })
    };

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    return (
        <div className='login_wrapper'>
            <div className="login_form">
                {/* <Typography style={{ textAlign: 'center', paddingLeft: 150, margin: 30, fontSize: 35, fontWeight: 'bold' }}>KNCV</Typography> */}
                <Image src={BegizeLogo} style={{ textAlign: 'center', paddingLeft: 150, margin: 30 }} preview={false} />
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <p className='error_message'>{error}</p>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button loading={loadingAuth} type="primary" htmlType="submit">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
export default LoginPage