import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Menu,
  Dropdown,
  Row,
  Col,
  AutoComplete,
  Tooltip,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddFilled,
  ExclamationCircleOutlined,
  KeyOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getregions } from "../controllers/location";
import { getInstitutions } from "../controllers/institutions";
import { getTestCenters } from "../controllers/test_centers";
import {
  addCourierAdmin,
  addInstitutionalAdmin,
  addTestCenterAdmin,
  addZonalAdmin,
  deleteUserFromAuth,
  editUser,
  getAllActiveUsers,
  userRegisteredWithPhoneNumber,
} from "../controllers/user";
import LoadingComponent from "../components/Loading";
import openNotificationWithIcon from "../notification";
import { changePassword } from "../controllers/auth";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import Highlight from "react-syntax-highlighter/dist/esm/highlight";
// import { user } from 'firebase-functions/v1/auth';

const { Option } = Select;
const { Column } = Table;

const Users = () => {
  useEffect(() => {
    loadregions();
    loadInstitutions();
    loadTestCenters();
    loadUsers();
    return () => {};
  }, []);

  const [addInstitutionalModalVisible, setAddInstitutionalModalVisible] =
    useState(false);
  const [addCourierModalVisible, setAddCourierModalVisible] = useState(false);
  const [addTestCenterModalVisible, setAddTestCenterModalVisible] =
    useState(false);
  const [addZonalAdminModalVisible, setAddZonalAdminModalVisible] =
    useState(false);
  const [editUserModalVisible, setEditUserModalVisible] = useState(false);
  const [resetPasswordModalVisisble, setResetPasswordModalVisisble] =
    useState(false);
  const [userId, setUserId] = useState();
  const [selectedUser, setSelectedUser] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [regions, setregions] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadedUsers, setLoadedUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [testCenters, setTestCenters] = useState([]);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [region, setregion] = useState({});
  const [institution, setInstitution] = useState({});
  const [testCenter, setTestCenter] = useState({});
  const [woredas, setworedas] = useState([]);
  const [zones, setzones] = useState([]);
  const [zone, setzone] = useState({});
  const [woreda, setworeda] = useState({});
  const [addInstitutionalAdminFormRef] = Form.useForm();
  const [addTestCenterAdminFormRef] = Form.useForm();
  const [addCourierAdminFormRef] = Form.useForm();
  const [addZonalAdminFormRef] = Form.useForm();
  const [editUserFormRef] = Form.useForm();
  const [resetPasswordFormRef] = Form.useForm();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [usernameToReset, setUsernameToReset] = useState("");
  const [passowrdToReset, setPasswordToReset] = useState("");

  //!
  const [permissionType, setPermissionType] = useState("");
  const [permission, setPermission] = useState(null);

  const [permissionRegions, setPermissionRegions] = useState([]);
  const [permissionZones, setPermissionZones] = useState([]);
  const [permissionWoredas, setPermissionWoredas] = useState([]);

  const [filteredInstitutions, setFilteredInstitutions] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

 
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              borderRadius : 5,
              marginTop : 5
            }}
          >
            Search
          </Button>
          <Button
              onClick={() =>{
                clearFilters && handleReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex)
              }}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          {/*
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
    (record[dataIndex] === null || record[dataIndex] === undefined || record[dataIndex] === "") ? false :  record[dataIndex].toString().trim().toLowerCase().includes(value.toString().trim().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlight
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleChangeInstitution = (e) => {
    setInstitution(JSON.parse(e));
  };

  const handleChangeUserRegionOnAdd = (e) => {
    setregion(JSON.parse(e));
    setzones(JSON.parse(e).zones);
    // setPermission(null)
    // setPermissionType(null)

    editUserFormRef.resetFields(["woreda", "zone"]);
    addCourierAdminFormRef.resetFields(["woreda", "zone"]);
  };

  const handleChangeUserZoneOnAdd = (e) => {
    setzone(JSON.parse(e));
    setworedas(JSON.parse(e).woredas);
    // setPermission(null)
    // setPermissionType(null)

    editUserFormRef.resetFields(["woreda"]);
    addCourierAdminFormRef.resetFields(["woreda"]);
  };

  const handleChangeUserWoredaChange = (e) => {
    setworeda(JSON.parse(e));
    // setPermission(null)
    // setPermissionType(null)
  };

  const handleChangeTestCenter = (e) => {
    setTestCenter(JSON.parse(e));
  };

  const handleUsernameChanged = (e) => {
    setUsername(e.target.value);
  };

  const handleNameChanged = (e) => {
    setName(e.target.value);
  };

  const handlePhoneChanged = (e) => {
    setPhone(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const getFormFromSelecteddUser = (user) => {
    if (user.type === "COURIER_ADMIN") {
      // editUserFormRef.setFieldsValue({
      //     region: JSON.parse(user.region)
      // })
      return (
        <>
          <Form.Item
            label="Region"
            name="region"
            // rules={[{ required: true, message: 'Please select region!' }]}
          >
            <Select
              onChange={handleChangeUserRegionOnAdd}
              style={{ width: 150 }}
            >
              {regions.map((r) => {
                return <Option value={JSON.stringify(r)}>{r.name}</Option>;
              })}
            </Select>
            {user.region && `   ${user.region.name}`}
          </Form.Item>

          <Form.Item
            label="Zone"
            name="zone"
            // rules={[{ required: true, message: 'a select Zone!' }]}
          >
            <Select onChange={handleChangeUserZoneOnAdd} style={{ width: 150 }}>
              {zones.map((zone) => {
                return (
                  <Option value={JSON.stringify(zone)}>{zone.name}</Option>
                );
              })}
            </Select>
            {user.zone && `   ${user.zone.name}`}
          </Form.Item>

          <Form.Item
            label="Woreda"
            name="woreda"
            // rules={[{ required: true, message: 'Please select woreda!' }]}
          >
            <Select
              onChange={handleChangeUserWoredaChange}
              style={{ width: 150 }}
            >
              {woredas.map((woreda) => {
                return (
                  <Option value={JSON.stringify(woreda)}>{woreda.name}</Option>
                );
              })}
            </Select>
            {user.woreda && `   ${user.woreda.name}`}
          </Form.Item>

          {/* {(() => {
                        if (user.permission) {
                            let updatedPermissionType = permissionType || user.permission.type;
                            console.log('New Permission Type', updatedPermissionType);
                            switch (updatedPermissionType) {
                                case 'Region':
                                    return <Form.Item
                                        label="Report Permission Region"
                                        name="permissionRegion"
                                    // rules={[{ required: true, message: 'Please select a region for report permission!' }]}
                                    >
                                        <Select onChange={(e) => { setPermission({ 'type': permissionType || user.permission.type, 'region': e }) }} style={{ width: 250 }}>
                                            {
                                                permissionRegions.map(region => {
                                                    // return <Option value={JSON.stringify(region)}>{`${institution.code} - ${institution.name}`}</Option>
                                                    return <Option value={JSON.stringify(region.name)}>{`${region.name}`}</Option>

                                                })
                                            }
                                        </Select>
                                        {user.permission.region && `   ${user.permission.region}`}

                                    </Form.Item>
                                // break;
                                case 'Zone':
                                    return <Form.Item
                                        label="Report Permission Zone"
                                        name="permissionZone"
                                    // rules={[{ required: true, message: 'Please select a zone for report permission!' }]}
                                    >
                                        <Select onChange={(e) => { setPermission({ 'type': permissionType || user.permission.type, 'zone': e }) }} style={{ width: 250 }}>
                                            {
                                                permissionZones.map(zone => {
                                                    // return <Option value={JSON.stringify(region)}>{`${institution.code} - ${institution.name}`}</Option>
                                                    return <Option value={JSON.stringify(zone.name)}>{`${zone.name}`}</Option>

                                                })
                                            }
                                        </Select>
                                        {user.permission.zone && `   ${user.permission.zone}`}

                                    </Form.Item>
                                // break;
                                case 'Woreda':
                                    return <Form.Item
                                        label="Report Permission Woreda"
                                        name="permissionWoreda"
                                    // rules={[{ required: true, message: 'Please select a woreda for report permission!' }]}
                                    >
                                        <Select onChange={(e) => { setPermission({ 'type': permissionType || user.permission.type, 'woreda': e }) }} style={{ width: 250 }}>
                                            {
                                                permissionWoredas.map(woreda => {
                                                    // return <Option value={JSON.stringify(region)}>{`${institution.code} - ${institution.name}`}</Option>
                                                    return <Option value={JSON.stringify(woreda.name)}>{`${woreda.name}`}</Option>

                                                })
                                            }
                                        </Select>
                                        {user.permission.woreda && `   ${user.permission.woreda}`}

                                    </Form.Item>
                                // break;
                                default:
                                    return <></>;

                            }
                        }

                    })()} */}
        </>
      );
    } else if (user.type === "INSTITUTIONAL_ADMIN") {
      return (
        <>
          <Row style={{ marginBottom: 12 }}>
            <Col>
              <Form.Item
                label="Region"
                name="region"
                style={{ margin: 3 }}
                // rules={[{ required: true, message: 'Please select region!' }]}
              >
                <Select
                  onChange={handleChangeInstitutionRegionOnAdd}
                  style={{ width: 150 }}
                >
                  {regions.map((r) => {
                    return <Option value={JSON.stringify(r)}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Zone"
                name="zone"
                style={{ margin: 3 }}
                // rules={[{ required: true, message: 'Please select Zone!' }]}
              >
                <Select
                  onChange={handleChangeInstitutionZoneOnAdd}
                  style={{ width: 150 }}
                >
                  {zones.map((zone) => {
                    return (
                      <Option value={JSON.stringify(zone)}>{zone.name}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Woreda"
                name="woreda"
                style={{ margin: 3 }}
                // rules={[{ required: true, message: 'Please select woreda!' }]}
              >
                <Select
                  onChange={handleChangeInstitutionWoredaChange}
                  style={{ width: 150 }}
                >
                  {woredas.map((woreda) => {
                    return (
                      <Option value={JSON.stringify(woreda)}>
                        {woreda.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Referring Health Facility" name="institution">
            <Select onChange={handleChangeInstitution} style={{ width: 250 }}>
              {institutions &&
                institutions
                  .filter((inst) => {
                    return (
                      inst &&
                      inst.zone &&
                      inst.zone.code === zone.code &&
                      inst &&
                      inst.woreda &&
                      inst.woreda.code === woreda.code &&
                      inst &&
                      inst.region &&
                      inst.region.code === region.code
                    );
                  })
                  .map((institution) => {
                    return (
                      <Option
                        value={JSON.stringify(institution)}
                      >{`${institution.code} - ${institution.name}`}</Option>
                    );
                  })}
            </Select>
            {`   ${user.institution.name}`}
          </Form.Item>

          <Form.Item
            label="Report Permission"
            name="permission"
            // rules={[{ required: true, message: 'Please select permission!' }]}
          >
            <Select
              onChange={(e) => {
                setPermissionType(e);
              }}
              style={{ width: 250 }}
            >
              {/* {
                                    institutions.map(institution => {
                                        return <Option value={JSON.stringify(institution)}>{`${institution.code} - ${institution.name}`}</Option>
                                    })
                                } */}
              <Option value={"Federal"}>Federal</Option>
              <Option value={"Region"}>Region</Option>
              <Option value={"Zone"}>Zone</Option>
              <Option value={"Woreda"}>Woreda</Option>
              <Option value={"Facility"}>Facility Level</Option>
            </Select>
            {`   ${user.permission.type}`}
          </Form.Item>

          {/* {(() => {
                        let updatedPermissionType = permissionType || user.permission.type;
                        switch (updatedPermissionType) {
                            case 'Region':
                                return <Form.Item
                                    label="Report Permission Region"
                                    name="permissionRegion"
                                // rules={[{ required: true, message: 'Please select a region for report permission!' }]}
                                >
                                    <Select onChange={(e) => { setPermission({ 'type': permissionType || user.permission.type, 'region': e }) }} style={{ width: 250 }}>
                                        {
                                            permissionRegions.map(region => {
                                                // return <Option value={JSON.stringify(region)}>{`${institution.code} - ${institution.name}`}</Option>
                                                return <Option value={JSON.stringify(region.name)}>{`${region.name}`}</Option>

                                            })
                                        }
                                    </Select>
                                    {user.permission.region && `   ${user.permission.region}`}

                                </Form.Item>
                            // break;
                            case 'Zone':
                                return <Form.Item
                                    label="Report Permission Zone"
                                    name="permissionZone"
                                // rules={[{ required: true, message: 'Please select a zone for report permission!' }]}
                                >
                                    <Select onChange={(e) => { setPermission({ 'type': permissionType || user.permission.type, 'zone': e }) }} style={{ width: 250 }}>
                                        {
                                            permissionZones.map(zone => {
                                                // return <Option value={JSON.stringify(region)}>{`${institution.code} - ${institution.name}`}</Option>
                                                return <Option value={JSON.stringify(zone.name)}>{`${zone.name}`}</Option>

                                            })
                                        }
                                    </Select>
                                    {user.permission.zone && `   ${user.permission.zone}`}

                                </Form.Item>
                            // break;
                            case 'Woreda':
                                return <Form.Item
                                    label="Report Permission Woreda"
                                    name="permissionWoreda"
                                // rules={[{ required: true, message: 'Please select a woreda for report permission!' }]}
                                >
                                    <Select onChange={(e) => { setPermission({ 'type': permissionType || user.permission.type, 'woreda': e }) }} style={{ width: 250 }}>
                                        {
                                            permissionWoredas.map(woreda => {
                                                // return <Option value={JSON.stringify(region)}>{`${institution.code} - ${institution.name}`}</Option>
                                                return <Option value={JSON.stringify(woreda.name)}>{`${woreda.name}`}</Option>

                                            })
                                        }
                                    </Select>
                                    {user.permission.woreda && `   ${user.permission.woreda}`}

                                </Form.Item>
                            // break;
                            default:
                                return <></>;

                        }
                    })()} */}
        </>
      );
    } else if (user.type === "TEST_CENTER_ADMIN") {
      return (
        <>
          {/* dvfdfvdffvdfv */}
          <Form.Item
            label="Testing Health Facility"
            name="test_center"
            // rules={[{ required: true, message: 'Please select test facility' }]}
          >
            {/* {
                            console.log(user.test_center.name)
                        } */}
            <AutoComplete
              options={
                autompleteText === ""
                  ? testCenters.map((val) => {
                      return { ...val, value: val.name };
                    })
                  : testCenters
                      .filter((val) =>
                        val.name.toLowerCase().includes(autompleteText)
                      )
                      .map((val) => {
                        return { ...val, value: val.name };
                      })
              }
              style={{ width: 250 }}
              onSelect={onSelect}
              onSearch={onSearch}
              onChange={onChange}
              placeholder="Testing Health Facility"
            />
            {user.test_center && `${user.test_center.name}`}
          </Form.Item>

          <Form.Item
            label="Report Permission"
            name="permission"
            // rules={[{ required: true, message: 'Please select permission!' }]}
          >
            <Select
              onChange={(e) => {
                setPermissionType(e);
              }}
              style={{ width: 250 }}
            >
              <Option value={"Federal"}>Federal</Option>
              <Option value={"Region"}>Region</Option>
              <Option value={"Zone"}>Zone</Option>
              <Option value={"Woreda"}>Woreda</Option>
              <Option value={"Facility"}>Facility Level</Option>
            </Select>
            {`   ${user.permission.type}`}
          </Form.Item>
        </>
      );
    } else {
      return <></>;
    }
  };

  const loadregions = async () => {
    let allZones = [];
    let allWoredas = [];
    // setLoadedregions(false)
    // setLoadingregions(true)
    let regionsData = await getregions();
    setregions(regionsData);
    //!get zones from all regions
    for (let i = 0; i < regionsData.length; i++) {
      for (let j = 0; j < regionsData[i].zones.length; j++) {
        allZones.push(regionsData[i].zones[j]);
      }
    }
    //!get woredas from all zones
    for (let k = 0; k < allZones.length; k++) {
      for (let l = 0; l < allZones[k].woredas.length; l++) {
        allWoredas.push(allZones[k].woredas[l]);
      }
    }
    setPermissionRegions(regionsData);
    setPermissionZones(allZones);
    setPermissionWoredas(allWoredas);
  };

  const loadUsers = async () => {
    setLoadedUsers(false);
    setLoadingUsers(true);
    let usersData = await getAllActiveUsers();
    setUsers(usersData);
    setLoadedUsers(true);
    setLoadingUsers(false);
  };

  const loadInstitutions = async () => {
    let institutionsData = await getInstitutions();
    setInstitutions(institutionsData);
  };

  const loadTestCenters = async () => {
    let testCenterData = await getTestCenters();
    setTestCenters(testCenterData);
  };

  const handleAddInstitutionalAdminSubmit = async () => {
    await addInstitutionalAdminFormRef.validateFields();
    setConfirmLoading(true);

    // console.log(perm)

    // setConfirmLoading(false)
    // return

    let phoneNumberExists = await userRegisteredWithPhoneNumber(phone);
    if (phoneNumberExists) {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "Phone number is already registered!"
      );
      setConfirmLoading(false);
      return;
    }

    let perm = {};
    // console.log(permissionType)
    switch (permissionType) {
      case "Zone":
        perm = { type: permissionType, zone: zone.name };
        // console.log(perm)
        break;

      case "Region":
        perm = { type: permissionType, region: region.name };
        // console.log(perm)
        break;

      case "Woreda":
        perm = { type: permissionType, woreda: woreda.name };
        // console.log(perm)
        break;
      case "Federal":
        perm = { type: permissionType };
        // console.log(perm)
        break;
      case "Facility":
        perm = { type: permissionType, facility: institution };
        // console.log(perm)
        break;
      default:
        break;
    }
    // console.log({ name, phone, username: `${username}@kncv.com`, password, institution, institution_id: institution.key, permission: perm })
    // setConfirmLoading(false)
    // return
    let status = await addInstitutionalAdmin({
      name,
      phone,
      username: `${username}@kncv.com`,
      password,
      institution,
      institution_id: institution.key,
      permission: perm,
    });
    // console.log(status)
    if (status.success) {
      setAddInstitutionalModalVisible(false);
      setConfirmLoading(false);
      addInstitutionalAdminFormRef.resetFields();
      loadUsers();
      setPermissionType("");
      setPermission(null);
      openNotificationWithIcon(
        "success",
        "Added New User!",
        "Added New User Successfully!"
      );
    } else {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "Error Adding User. Please Try Again!"
      );
      setConfirmLoading(false);
    }
  };

  const handleAddZonalAdminSubmit = async () => {
    await addZonalAdminFormRef.validateFields();
    setConfirmLoading(true);

    let phoneNumberExists = await userRegisteredWithPhoneNumber(phone);
    if (phoneNumberExists) {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "Phone number is already registered!"
      );
      setConfirmLoading(false);
      return;
    }

    let status = await addZonalAdmin({
      name,
      phone,
      username: `${username}@kncv.com`,
      password,
      region,
      region_id: region.key,
    });
    // console.log(status)
    if (status.success) {
      setAddZonalAdminModalVisible(false);
      setConfirmLoading(false);
      addZonalAdminFormRef.resetFields();
      setPermission(null);
      setPermissionType("");
      loadUsers();
      openNotificationWithIcon(
        "success",
        "Added New User!",
        "Added New User Successfully!"
      );
    } else {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "Error Adding User. Please Try Again!"
      );
      setConfirmLoading(false);
    }
  };

  const handleResetPasswordSubmit = async () => {
    // console.log(usernameToReset, newPassword)

    await resetPasswordFormRef.validateFields();
    setConfirmLoading(true);
    let status = await changePassword(
      usernameToReset,
      passowrdToReset,
      newPassword
    );
    if (status.success) {
      setResetPasswordModalVisisble(false);
      setConfirmLoading(false);
      resetPasswordFormRef.resetFields();
      setPasswordToReset("");
      setUsernameToReset("");
      loadUsers();
      openNotificationWithIcon("success", "Success!", "Changed User password!");
    } else {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "Error Adding User. Please Try Again!"
      );
      setConfirmLoading(false);
    }
  };

  const handleEditUserSubmit = async () => {
    await editUserFormRef.validateFields();
    setConfirmLoading(true);

    let extra = {};

    if (selectedUser.type === "INSTITUTIONAL_ADMIN") {
      extra.institution =
        Object.keys(institution).length > 0
          ? institution
          : selectedUser.institution;
    } else if (selectedUser.type === "COURIER_ADMIN") {
      extra.region =
        Object.keys(region).length > 0 ? region : selectedUser.region;
      extra.zone = Object.keys(zone).length > 0 ? zone : selectedUser.zone;
      extra.woreda =
        Object.keys(woreda).length > 0 ? woreda : selectedUser.woreda;
    } else if (selectedUser.type === "TEST_CENTER_ADMIN") {
      extra.test_center =
        Object.keys(testCenter).length > 0
          ? testCenter
          : selectedUser.test_center;
      extra.test_center_id =
        (Object.keys(testCenter).length > 0 && testCenter && testCenter.id) ||
        selectedUser.test_center_id;
    }

    let perm = {};
    // console.log(permissionType)
    switch (permissionType) {
      case "Zone":
        perm = {
          type: permissionType,
          zone:
            selectedUser.type === "INSTITUTIONAL_ADMIN"
              ? region.zone
              : testCenter.zone && testCenter.zone.name,
        };
        // console.log(perm)
        break;

      case "Region":
        perm = {
          type: permissionType,
          region:
            selectedUser.type === "INSTITUTIONAL_ADMIN"
              ? region.name
              : testCenter.region && testCenter.region.name,
        };
        // console.log(perm)
        break;

      case "Woreda":
        perm = {
          type: permissionType,
          woreda:
            selectedUser.type === "INSTITUTIONAL_ADMIN"
              ? woreda.name
              : testCenter.woreda && testCenter.woreda.name,
        };
        // console.log(perm)
        break;
      case "Federal":
        perm = { type: permissionType };
        // console.log(perm)
        break;
      case "Facility":
        perm = {
          type: permissionType,
          facility:
            selectedUser.type === "INSTITUTIONAL_ADMIN"
              ? institution
              : testCenter,
        };
        // console.log(perm)
        break;
    }
    // console.log({ id: userId, name, phone_number: phone, extra, permission: perm || selectedUser.permission })
    // setConfirmLoading(false)
    // return

    let success = await editUser({
      id: userId,
      name,
      phone_number: phone,
      extra,
      permission:
        Object.keys(perm).length == 0 ? selectedUser.permission : perm,
    });
    if (success) {
      setEditUserModalVisible(false);
      setConfirmLoading(false);
      editUserFormRef.resetFields();
      setPermission(null);
      setPermissionType("");
      loadUsers();
      openNotificationWithIcon(
        "success",
        "Edited!",
        "Edited User Successfully!"
      );
    } else {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "Error Adding User. Please Try Again!"
      );
      setConfirmLoading(false);
    }
  };

  const handleAddInstitutionalAdminCancel = () => {
    // console.log('Clicked cancel button');
    setInstitution({});
    setPermission(null);
    setPermissionType("");
    setAddInstitutionalModalVisible(false);
  };

  const handleResetPasswordCancel = () => {
    // console.log('Clicked cancel button');
    resetPasswordFormRef.resetFields();
    setResetPasswordModalVisisble(false);
    setNewPassword("");
    setUsernameToReset("");
    setPasswordToReset("");
  };

  const handleEditUserCance = () => {
    // console.log('Clicked cancel button');
    setzone({});
    setregion({});
    setworeda({});
    setPermission(null);
    setPermissionType("");
    editUserFormRef.resetFields(["woreda", "zone", "region"]);
    addCourierAdminFormRef.resetFields(["woreda", "zone", "region"]);
    setEditUserModalVisible(false);
  };

  const handleAddCourierSubmit = async () => {
    await addCourierAdminFormRef.validateFields();
    setConfirmLoading(true);

    let phoneNumberExists = await userRegisteredWithPhoneNumber(phone);
    if (phoneNumberExists) {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "Phone number is already registered!"
      );
      setConfirmLoading(false);
      return;
    }

    // console.log(username)
    let status = await addCourierAdmin({
      name,
      phone,
      username: `${username}@kncv.com`,
      password,
      region,
      region_id: region.key,
      zone,
      woreda,
      permission,
    });
    // console.log(status)
    if (status.success) {
      setAddCourierModalVisible(false);
      setConfirmLoading(false);
      addCourierAdminFormRef.resetFields();
      setPermission(null);
      setPermissionType("");
      loadUsers();
      openNotificationWithIcon(
        "success",
        "Added New User!",
        "Added New User Successfully!"
      );
    } else {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "Error Adding User. Please Try Again!"
      );
      setConfirmLoading(false);
    }
  };

  const showEditModal = (record) => {
    // console.log(record)

    if (record.type === "COURIER_ADMIN") {
      editUserFormRef.setFieldsValue({
        name: record.name,
        phone: record.phone,
        password: record.password,
        // region: JSON.stringify(record.region),
        // zone: JSON.stringify(record.zone),
        // woreda: JSON.stringify(record.woreda),
      });
    } else if (record.type === '"INSTITUTIONAL_ADMIN"') {
      setInstitution(record.institution);
      setworeda(record.institution && record.institution.woreda);
      setzone(record.institution && record.institution.zone);
      setregion(record.institution && record.institution.region);
    } else if (record.type === '""TEST_CENTER_ADMIN""') {
      setTestCenter(record.test_center);
    }
    editUserFormRef.setFieldsValue({
      name: record.name,
      phone: record.phone,
      password: record.password,
    });
    // console.log(record)
    setName(record.name);
    setPhone(record.phone);
    setEditUserModalVisible(true);
    setUserId(record.key);
    setSelectedUser(record);
  };

  const confirmDeletion = (record) => {
    Modal.confirm({
      title: `Delete`,
      icon: <ExclamationCircleOutlined />,
      content: "Delete this user?",
      onOk: () => {
        // console.log(record)
        deleteUserFromAuth(record.key, record.username, record.password)
          .then((val) => {
            if (val) {
              openNotificationWithIcon(
                "success",
                "Deleted!",
                "Deleted User Successfully!"
              );
              loadUsers();
            }
          })
          .catch((err) => {
            openNotificationWithIcon(
              "error",
              "Error!",
              "Error Deleting User. Please Try Again!"
            );
          });
      },
      onCancel() {},
    });
  };

  const handleAddTestCenterSubmit = async () => {
    await addTestCenterAdminFormRef.validateFields();
    // console.log(testCenter)
    if (!testCenter) {
      openNotificationWithIcon(
        "warning",
        "Warning!",
        "Please select test center!"
      );
      return;
    }
    setConfirmLoading(true);

    let phoneNumberExists = await userRegisteredWithPhoneNumber(phone);
    if (phoneNumberExists) {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "Phone number is already registered!"
      );
      setConfirmLoading(false);
      return;
    }

    // console.log({ name, phone, username, password, test_center: region, test_center_id: testCenter.key })

    let perm = {};
    // console.log(permissionType)
    // console.log(testCenter);
    switch (permissionType) {
      case "Zone":
        perm = {
          woreda:
            testCenter && testCenter["zone"] && testCenter["zone"]["name"],
          type: "Zone",
        };
        // console.log(perm)
        break;

      case "Region":
        perm = {
          woreda:
            testCenter && testCenter["region"] && testCenter["region"]["name"],
          type: "Region",
        };
        // console.log(perm)
        break;

      case "Woreda":
        perm = {
          woreda:
            testCenter && testCenter["woreda"] && testCenter["woreda"]["name"],
          type: "Woreda",
        };
        // console.log(perm)
        break;
      case "Federal":
        perm = { type: "Federal" };
        break;
      case "Facility":
        perm = { ...permission, facility: testCenter, type: "Facility" };
        break;
      default:
        break;
    }
    if (Object.keys(testCenter).length === 0) {
      openNotificationWithIcon(
        "warning",
        "Warning!",
        "Please select valid test center!"
      );
      setConfirmLoading(false);
      return;
    }
    // console.log({ name, phone, username: `${username}@kncv.com`, password, test_center: testCenter, test_center_id: testCenter.key, permission: permissionType === 'Facility' ? { ...permission, facility: testCenter, type: 'Facility' } : { ...permission } })

    // return
    let status = await addTestCenterAdmin({
      name,
      phone,
      username: `${username}@kncv.com`,
      password,
      test_center: testCenter,
      test_center_id: testCenter.key,
      permission:
        permissionType === "Facility"
          ? { ...permission, facility: testCenter, type: "Facility" }
          : perm,
    });
    // console.log(status)
    if (status.success) {
      setAddTestCenterModalVisible(false);
      setConfirmLoading(false);
      addTestCenterAdminFormRef.resetFields();
      openNotificationWithIcon(
        "success",
        "Added New User!",
        "Added New User Successfully!"
      );
      loadUsers();
      setTestCenter(null);
    } else {
      openNotificationWithIcon(
        "error",
        "Failed!",
        "The username is already taken!"
      );
      setConfirmLoading(false);
    }
  };

  const handleAddCourierCancel = () => {
    // console.log('Clicked cancel button');
    setzone({});
    setregion({});
    setworeda({});
    setAddCourierModalVisible(false);
  };

  const handleAddZonalAdminCancel = () => {
    // console.log('Clicked cancel button');
    setAddZonalAdminModalVisible(false);
  };

  const handleAddTesetCenterCancel = () => {
    setTestCenter({});
    // console.log('Clicked cancel button');
    setAddTestCenterModalVisible(false);
  };
  const getTypeFromUserPermission = (type) => {
    switch (type) {
      case "COURIER_ADMIN":
        return "Courier";
      case "TEST_CENTER_ADMIN":
        return "Testing Health Facility";
      case "INSTITUTIONAL_ADMIN":
        return "Referring Health Facility";
      default:
        return "";
    }
  };

  const handleChangeInstitutionRegionOnAdd = (e) => {
    setregion(JSON.parse(e));
    setzones(JSON.parse(e).zones);
    setzone({});
    setworeda({});
    setworedas([]);

    editUserFormRef.resetFields(["woreda", "zone"]);
    addInstitutionalAdminFormRef.resetFields(["woreda", "zone"]);
    setFilteredInstitutions([]);
  };

  const handleChangeInstitutionZoneOnAdd = (e) => {
    setzone(JSON.parse(e));
    setworedas(JSON.parse(e).woredas);
    setworeda({});

    editUserFormRef.resetFields(["woreda"]);
    addInstitutionalAdminFormRef.resetFields(["woreda"]);
    setFilteredInstitutions([]);
  };

  const handleChangeInstitutionWoredaChange = (e) => {
    setworeda(JSON.parse(e));
  };

  const [autompleteText, setAutoCompleteText] = useState("");

  const onSearch = (searchText) => {
    setAutoCompleteText(searchText);
  };

  const onSelect = (data) => {
    setTestCenter(testCenters.find((tc) => tc.name === data));
  };

  const onChange = (data) => {};

  return (
    <>
      {loadingUsers && <LoadingComponent />}

      {loadedUsers && !loadingUsers && (
        <>
          <Dropdown.Button
            type="primary"
            style={{ marginTop: 20, marginBottom: 20 }}
            size="large"
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setAddInstitutionalModalVisible(true);
                  }}
                  key="1"
                  icon={<FileAddFilled />}
                >
                  Add Referring Health Facility User
                </Menu.Item>

                <Menu.Item
                  onClick={() => {
                    setAddTestCenterModalVisible(true);
                  }}
                  key="2"
                  icon={<FileAddFilled />}
                >
                  Add Test Health Facility User
                </Menu.Item>

                <Menu.Item
                  onClick={() => {
                    setAddCourierModalVisible(true);
                  }}
                  key="3"
                  icon={<FileAddFilled />}
                >
                  Add Courier
                </Menu.Item>
              </Menu>
            }
          >
            Add User
          </Dropdown.Button>
          <Table
            dataSource={users}
            style={{ width: "auto", backgroundColor: "white" }}
          >
            <Column
              
              title="Name"
              dataIndex="name"
              width={150}
              {...getColumnSearchProps('name')}
              key="name"
              sorter={(a,b) => a.name.localeCompare(b.name)}
              defaultSortOrder={"ascend"}
              render={(name) => {
                return <Typography style={{ fontSize: 12 }}>{name}</Typography>;
              }}
            />
            <Column
              title="Username"
              dataIndex="username"
              key="username"
               {...getColumnSearchProps('username')}
              render={(username) => (
                <>
                  {username && (
                    <Tag style={{ fontSize: 12 }} key={username}>
                      {username &&
                        username.split("@") &&
                        username.split("@")[0]}
                    </Tag>
                  )}
                  {!username && <p>-</p>}
                </>
              )}
            />
            <Column
              title="Region"
              dataIndex="region"
              key="region"
               {...getColumnSearchProps('region')}
              render={(region, record, index) => {
                if (record.type === "INSTITUTIONAL_ADMIN") {
                  return (
                    <>
                      {record.institution &&
                        record.institution.region &&
                        record.institution.region.name && (
                          <Tag style={{ fontSize: 12 }} key={region}>
                            {record.institution.region.name}
                          </Tag>
                        )}
                      {/* {!region && <p>-</p>} */}
                    </>
                  );
                } else if (record.type === "TEST_CENTER_ADMIN") {
                  // console.log(record)
                  return (
                    <>
                      {record.test_center &&
                        record.test_center.region &&
                        record.test_center.region.name && (
                          <Tag style={{ fontSize: 12 }} key={region}>
                            {record.test_center.region.name}
                          </Tag>
                        )}
                      {/* {!region && <p>-</p>} */}
                    </>
                  );
                } else {
                  return (
                    <>
                      {record.region && record.region.name && (
                        <Tag style={{ fontSize: 12 }} key={region}>
                          {record.region.name}
                        </Tag>
                      )}
                      {/* {!region && <p>-</p>} */}
                    </>
                  );
                }
              }}
            />
            <Column
              title="Zone"
              dataIndex="zone"
               {...getColumnSearchProps('zone')}
              key="zone"
              render={(region, record, index) => {
                if (record.type === "INSTITUTIONAL_ADMIN") {
                  return (
                    <>
                      {record.institution &&
                        record.institution.zone &&
                        record.institution.zone.name && (
                          <Tag style={{ fontSize: 12 }} key={region}>
                            {record.institution.zone.name}
                          </Tag>
                        )}
                    </>
                  );
                } else if (record.type === "TEST_CENTER_ADMIN") {
                  return (
                    <>
                      {record.test_center &&
                        record.test_center.zone &&
                        record.test_center.zone.name && (
                          <Tag style={{ fontSize: 12 }} key={region}>
                            {record.test_center.zone.name}
                          </Tag>
                        )}
                    </>
                  );
                } else {
                  return (
                    <>
                      {record.zone && record.zone.name && (
                        <Tag style={{ fontSize: 12 }} key={region}>
                          {record.zone.name}
                        </Tag>
                      )}
                    </>
                  );
                }
              }}
            />

            {/* <Column
                        title="Woreda"
                        dataIndex="woreda"
                        key="woreda"
                        render={(region, record, index) => {

                            if (record.type === 'INSTITUTIONAL_ADMIN') {
                                return (

                                    <>
                                        {record.institution && record.institution.woreda && record.institution.woreda.name && <Tag key={region}>
                                            {record.institution.woreda.name}
                                        </Tag>}
                                    </>
                                )
                            }

                            else if (record.type === 'TEST_CENTER_ADMIN') {
                                return (

                                    <>
                                        {record.test_center && record.test_center.woreda && record.test_center.woreda.name && <Tag key={region}>
                                            {record.test_center.woreda.name}
                                        </Tag>}
                                    </>
                                )
                            }
                            else {
                                return (

                                    <>
                                        {record.zone && record.woreda.name && <Tag key={region}>
                                            {record.woreda.name}
                                        </Tag>}
                                    </>
                                )
                            }
                        }
                        }
                    /> */}

            <Column
              title="Testing Facility"
              dataIndex="test_center"
              key="test_center"
              {...getColumnSearchProps('test_center')}
              render={(region) => (
                <>
                  {region && (
                    <Tag style={{ fontSize: 12 }} key={region}>
                      {region.name}
                    </Tag>
                  )}
                  {!region && <p>-</p>}
                </>
              )}
            />
            <Column
              title="Referring Health Facility"
              dataIndex="institution"
              key="institution"
              render={(region) => (
                <>
                  {region && (
                    <Tag style={{ fontSize: 12 }} key={region}>
                      {region.name}
                    </Tag>
                  )}
                  {!region && <p>-</p>}
                </>
              )}
            />
            <Column
              title="Phone Number"
              dataIndex="phone_number"
               {...getColumnSearchProps('phone_number')}
              key="phone_number"
              render={(phone) => {
                return (
                  <Typography style={{ fontSize: 12 }}>{phone}</Typography>
                );
              }}
            />
            {/* <Column title="Report Permission" dataIndex="permission" key="permission" /> */}

            <Column
              title="Type"
              dataIndex="type"
              key="type"
              render={(user_type) => (
                <div>
                  <Tag style={{ fontSize: 10 }} color="green" key={user_type}>
                    {getTypeFromUserPermission(user_type)}
                  </Tag>
                </div>
              )}
            />
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <Space size="small">
                  <Tooltip title="Delete">
                    <Button
                      onClick={() => confirmDeletion(record)}
                      type="primary"
                      shape="round"
                      size="small"
                      danger
                      icon={<DeleteOutlined />}
                    ></Button>
                  </Tooltip>

                  <Tooltip title="Edit">
                    <Button
                      onClick={() => showEditModal(record)}
                      type="primary"
                      shape="round"
                      size="small"
                      icon={<EditOutlined />}
                    ></Button>
                  </Tooltip>

                  <Tooltip title="Change Password">
                    <Button
                      onClick={() => {
                        // console.log(record)
                        // console.log(record.username && record.username.split('@')[0])
                        // console.log(record.password)
                        setResetPasswordModalVisisble(true);
                        setPasswordToReset(record.password);
                        setUsernameToReset(
                          record.username && record.username.split("@")[0]
                        );
                        // changePassword(record.username, record.password, 'newpassword2').then(val => {
                        //     console.log(val)
                        // })
                      }}
                      type="primary"
                      shape="round"
                      size="small"
                      danger
                      icon={<KeyOutlined />}
                    ></Button>
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
          <Modal
            title="Add Referring Health Facility User"
            visible={addInstitutionalModalVisible}
            onOk={handleAddInstitutionalAdminSubmit}
            // onOk={() => {
            //     console.log('permission', permission)
            // }}
            confirmLoading={confirmLoading}
            onCancel={
              !confirmLoading ? handleAddInstitutionalAdminCancel : () => {}
            }
            closable={!confirmLoading}
          >
            <Form
              form={addInstitutionalAdminFormRef}
              colon={false}
              layout="vertical"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: "Please enter username!" }]}
              >
                <Input autoComplete={false} onChange={handleUsernameChanged} />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input onChange={handleNameChanged} />
              </Form.Item>

              <Form.Item
                label="Phone "
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number!",
                    validator: (_, value) => {
                      let a = "";
                      if (
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                          value
                        ) &&
                        value.startsWith("09") &&
                        value.length === 10
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid phone number"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input onChange={handlePhoneChanged} />
              </Form.Item>

              <Form.Item
                label="Password "
                name="password"
                rules={[{ required: true, message: "Please password!" }]}
              >
                <Input
                  autoComplete={false}
                  onChange={handlePasswordChange}
                  type="password"
                />
              </Form.Item>

              <Row style={{ marginBottom: 12 }}>
                <Col>
                  <Form.Item
                    label="Region"
                    name="region"
                    style={{ margin: 3 }}
                    rules={[
                      { required: true, message: "Please select region!" },
                    ]}
                  >
                    <Select
                      onChange={handleChangeInstitutionRegionOnAdd}
                      style={{ width: 150 }}
                    >
                      {regions.map((r) => {
                        return (
                          <Option value={JSON.stringify(r)}>{r.name}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Zone"
                    name="zone"
                    style={{ margin: 3 }}
                    rules={[{ required: true, message: "Please select Zone!" }]}
                  >
                    <Select
                      onChange={handleChangeInstitutionZoneOnAdd}
                      style={{ width: 150 }}
                    >
                      {zones.map((zone) => {
                        return (
                          <Option value={JSON.stringify(zone)}>
                            {zone.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Woreda"
                    name="woreda"
                    style={{ margin: 3 }}
                    rules={[
                      { required: true, message: "Please select woreda!" },
                    ]}
                  >
                    <Select
                      onChange={handleChangeInstitutionWoredaChange}
                      style={{ width: 150 }}
                    >
                      {woredas.map((woreda) => {
                        return (
                          <Option value={JSON.stringify(woreda)}>
                            {woreda.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="Referring Health Facility"
                name="institution"
                rules={[
                  { required: true, message: "Please select institution!" },
                ]}
              >
                <Select
                  onChange={handleChangeInstitution}
                  style={{ width: 250 }}
                >
                  {institutions &&
                    institutions
                      .filter((inst) => {
                        return (
                          inst &&
                          inst.zone &&
                          inst.zone.code === zone.code &&
                          inst &&
                          inst.woreda &&
                          inst.woreda.code === woreda.code &&
                          inst &&
                          inst.region &&
                          inst.region.code === region.code
                        );
                      })
                      .map((institution) => {
                        return (
                          <Option
                            value={JSON.stringify(institution)}
                          >{`${institution.code} - ${institution.name}`}</Option>
                        );
                      })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Report Permission"
                name="permission"
                rules={[
                  { required: true, message: "Please select permission!" },
                ]}
              >
                <Select
                  onChange={(e) => {
                    // console.log(e)
                    setPermissionType(e);
                  }}
                  style={{ width: 250 }}
                >
                  {/* {
                                    institutions.map(institution => {
                                        return <Option value={JSON.stringify(institution)}>{`${institution.code} - ${institution.name}`}</Option>
                                    })
                                } */}
                  <Option value={"Federal"}>Federal</Option>
                  <Option value={"Region"}>Region</Option>
                  <Option value={"Zone"}>Zone</Option>
                  <Option value={"Woreda"}>Woreda</Option>
                  <Option value={"Facility"}>Facility Level</Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Add Courier"
            visible={addCourierModalVisible}
            onOk={handleAddCourierSubmit}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? handleAddCourierCancel : () => {}}
            closable={!confirmLoading}
          >
            <Form form={addCourierAdminFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: "Please enter username!" }]}
              >
                <Input autoComplete={false} onChange={handleUsernameChanged} />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input onChange={handleNameChanged} />
              </Form.Item>

              <Form.Item
                label="Phone "
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number!",
                    validator: (_, value) => {
                      let a = "";
                      if (
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                          value
                        ) &&
                        value.startsWith("09") &&
                        value.length === 10
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid phone number"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input onChange={handlePhoneChanged} />
              </Form.Item>

              <Form.Item
                label="Password "
                name="password"
                rules={[{ required: true, message: "Please password!" }]}
              >
                <Input
                  autoComplete={false}
                  onChange={handlePasswordChange}
                  type="password"
                />
              </Form.Item>

              <Row style={{ marginBottom: 15 }}>
                <Col style={{ marginRight: 5 }}>
                  <Form.Item
                    label="Region"
                    name="region"
                    rules={[
                      { required: true, message: "Please select region!" },
                    ]}
                  >
                    <Select
                      onChange={handleChangeUserRegionOnAdd}
                      style={{ width: 150 }}
                    >
                      {regions.map((region) => {
                        return (
                          <Option value={JSON.stringify(region)}>
                            {region.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ marginRight: 5 }}>
                  <Form.Item
                    label="Zone"
                    name="zone"
                    rules={[{ required: true, message: "Please select Zone!" }]}
                  >
                    <Select
                      onChange={handleChangeUserZoneOnAdd}
                      style={{ width: 150 }}
                    >
                      {zones.map((zone) => {
                        return (
                          <Option value={JSON.stringify(zone)}>
                            {zone.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col style={{ marginRight: 5 }}>
                  <Form.Item
                    label="Woreda"
                    name="woreda"
                    rules={[
                      { required: true, message: "Please select woreda!" },
                    ]}
                  >
                    <Select
                      onChange={handleChangeUserWoredaChange}
                      style={{ width: 150 }}
                    >
                      {woredas.map((woreda) => {
                        return (
                          <Option value={JSON.stringify(woreda)}>
                            {woreda.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>

          <Modal
            title="Add Testing Health Facility User"
            visible={addTestCenterModalVisible}
            onOk={handleAddTestCenterSubmit}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? handleAddTesetCenterCancel : () => {}}
            closable={!confirmLoading}
          >
            <Form
              form={addTestCenterAdminFormRef}
              colon={false}
              layout="vertical"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: "Please enter username!" }]}
              >
                <Input autoComplete={false} onChange={handleUsernameChanged} />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input onChange={handleNameChanged} />
              </Form.Item>

              <Form.Item
                label="Phone "
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number!",
                    validator: (_, value) => {
                      let a = "";
                      if (
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                          value
                        ) &&
                        value.startsWith("09") &&
                        value.length === 10
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid phone number"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input onChange={handlePhoneChanged} />
              </Form.Item>

              <Form.Item
                label="Password "
                name="password"
                rules={[{ required: true, message: "Please password!" }]}
              >
                <Input
                  autoComplete={false}
                  onChange={handlePasswordChange}
                  type="password"
                />
              </Form.Item>


              <Row style={{ marginBottom: 12 }}>
                <Col>
                  <Form.Item
                    label="Region"
                    name="region"
                    style={{ margin: 3 }}
                    rules={[
                      { required: true, message: "Please select region!" },
                    ]}
                  >
                    <Select
                      onChange={handleChangeInstitutionRegionOnAdd}
                      style={{ width: 150 }}
                    >
                      {regions.map((r) => {
                        return (
                          <Option value={JSON.stringify(r)}>{r.name}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Zone"
                    name="zone"
                    style={{ margin: 3 }}
                    rules={[{ required: true, message: "Please select Zone!" }]}
                  >
                    <Select
                      onChange={handleChangeInstitutionZoneOnAdd}
                      style={{ width: 150 }}
                    >
                      {zones.map((zone) => {
                        return (
                          <Option value={JSON.stringify(zone)}>
                            {zone.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Woreda"
                    name="woreda"
                    style={{ margin: 3 }}
                    rules={[
                      { required: true, message: "Please select woreda!" },
                    ]}
                  >
                    <Select
                      onChange={handleChangeInstitutionWoredaChange}
                      style={{ width: 150 }}
                    >
                      {woredas.map((woreda) => {
                        return (
                          <Option value={JSON.stringify(woreda)}>
                            {woreda.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="Testing Health Facility"
                name="test_center"
                rules={[
                  { required: true, message: "Please select test facility" },
                ]}
              >
                <AutoComplete
                 allowClear={true}
                  options={testCenters &&
                    testCenters
                      .filter((test_center) => {
                        return (
                          test_center &&
                          test_center.zone &&
                          test_center.zone.code === zone.code &&
                          test_center &&
                          test_center.woreda &&
                          test_center.woreda.code === woreda.code &&
                          test_center &&
                          test_center.region &&
                          test_center.region.code === region.code
                        );
                      })
                      .map((test_center) => {
                        return { ...test_center, value: test_center.name }
                      })}
                  style={{ width: 250 }}
                  onSelect={onSelect}
                  onSearch={onSearch}
                  onChange={onChange}
                  placeholder="Testing Health Facility"
                />
              </Form.Item>

              <Form.Item
                label="Report Permission"
                name="permission"
                rules={[
                  { required: true, message: "Please select permission!" },
                ]}
              >
                <Select
                  onChange={(e) => {
                    if (e !== "Federal") {
                      setPermissionType(e);
                      switch (e) {
                        case "Woreda":
                          setPermission({
                            type: e,
                            woreda: testCenter && testCenter.woreda,
                          });
                          break;

                        case "Region":
                          setPermission({
                            type: e,
                            region: testCenter && testCenter.region,
                          });
                          break;

                        case "Zone":
                          setPermission({
                            type: e,
                            zone: testCenter && testCenter.zone,
                          });
                          break;
                        default:
                          break;
                      }
                    } else {
                      setPermission({ type: e });
                      setPermissionType(e);
                    }
                  }}
                  style={{ width: 250 }}
                >
                  {/* {
                                    institutions.map(institution => {
                                        return <Option value={JSON.stringify(institution)}>{`${institution.code} - ${institution.name}`}</Option>
                                    })
                                } */}
                  <Option value={"Federal"}>Federal</Option>
                  <Option value={"Region"}>Region</Option>
                  <Option value={"Zone"}>Zone</Option>
                  <Option value={"Woreda"}>Woreda</Option>
                  <Option value={"Facility"}>Facility Level</Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Add Zonal User"
            visible={addZonalAdminModalVisible}
            onOk={handleAddZonalAdminSubmit}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? handleAddZonalAdminCancel : () => {}}
            closable={!confirmLoading}
          >
            <Form form={addZonalAdminFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: "Please enter username!" }]}
              >
                <Input onChange={handleUsernameChanged} />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input onChange={handleNameChanged} />
              </Form.Item>

              <Form.Item
                label="Phone "
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number!",
                    validator: (_, value) => {
                      if (
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                          value
                        )
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid phone number"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input onChange={handlePhoneChanged} />
              </Form.Item>

              <Form.Item
                label="Password "
                name="password"
                rules={[{ required: true, message: "Please password!" }]}
              >
                <Input
                  autoComplete={false}
                  onChange={handlePasswordChange}
                  type="password"
                />
              </Form.Item>

              <Form.Item
                label="region"
                name="region"
                rules={[{ required: true, message: "Please select region!" }]}
              >
                <Select
                  onChange={handleChangeUserRegionOnAdd}
                  style={{ width: 150 }}
                >
                  {regions.map((region) => {
                    return (
                      <Option value={JSON.stringify(region)}>
                        {region.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Edit User"
            visible={editUserModalVisible}
            onOk={handleEditUserSubmit}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? handleEditUserCance : () => {}}
            closable={!confirmLoading}
          >
            <Form form={editUserFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input onChange={handleNameChanged} />
              </Form.Item>

              <Form.Item
                label="Phone "
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number!",
                    validator: (_, value) => {
                      if (
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                          value
                        )
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid phone number"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input onChange={handlePhoneChanged} />
              </Form.Item>

              {/* <Form.Item
                            label="Password "
                            name="password"

                        >
                            <Input.Password readOnly={true} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item> */}

              {getFormFromSelecteddUser(selectedUser)}
            </Form>
          </Modal>

          <Modal
            title="Reset Password"
            visible={resetPasswordModalVisisble}
            onOk={handleResetPasswordSubmit}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? handleResetPasswordCancel : () => {}}
            closable={!confirmLoading}
          >
            <Form form={resetPasswordFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Password "
                name="password"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (value.length >= 6) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Password must be at least 8 chars."
                        );
                      }
                    },
                    message: "Password must be at least 6 charsacters.",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  type="password"
                  placeholder="Enter password"
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password "
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (value.length >= 6 && value == newPassword) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Password did not match.");
                      }
                    },
                    message: "Password did not match.",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  type="password"
                  placeholder="Confirm password"
                />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};

export default Users;
