import React from "react";
import LoginPage from "./screens/Login";
import { Routes, Route } from 'react-router-dom';
import HomePage from "./screens/HomePage";
import TesterHomePage from "./screens/TesterHomePage";
import InstitutionHomepage from "./screens/InstitutionHomePage";
import PageNotFound from "./components/page_not_found";
import { FLUTTER_WEB_APP_URL, MAIN_DOMAIN, SESSTION_EXPIRATION_TIME, USER_LOCAL_STORAGE_KEY, USER_LOGGED_IN_TIME } from "./config/AppConfig";
import moment from "moment";
import FlutterWebApp from "./screens/FlutterWebApp";
function App() {
  const currentUser = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
  const userLoggedInTime = localStorage.getItem(USER_LOGGED_IN_TIME);
  const isExpired = Math.abs(moment.duration(moment(userLoggedInTime).diff(moment().format("YYYY-MM-DD HH:mm"))).hours()) < SESSTION_EXPIRATION_TIME ? false : true;
  if(window.location.href == MAIN_DOMAIN + "webapp"){
    window.open(FLUTTER_WEB_APP_URL, '_  blank');
    window.location.href = MAIN_DOMAIN;
  }
  return (  
    <div className="App" >
      {
         (currentUser !== null && !isExpired ) ? (
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/tester" element={<TesterHomePage />} />
        <Route path="/health-facility" element={<InstitutionHomepage />} />
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
         ) : <Routes><Route path="*" element={<LoginPage/>}/></Routes> }
    </div>
  );
}
export default App;