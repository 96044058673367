import {
  addDoc,
  getDocs,
  collection,
  doc,
  deleteDoc,
  setDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { firestoreDatabase } from "../firebase";

const db = firestoreDatabase;
/*
Description : adds new region to the database 
@params [code, name]
*/
export const addregion = async (name, code) => {
  code = code.toUpperCase();
  const q = query(
    collection(firestoreDatabase, "regions"),
    where("code", "==", code)
  );

  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length !== 0) {
    return { success: false, message: "Region Code already registerd!" };
  }

  try {
    await addDoc(collection(db, "regions"), {
      code: code,
      zones: [],
      name: name,
    });
    return { success: true };
  } catch {
    return { success: false, message: "Error on adding region!" };
  }
};

/*
Description : adds new zone to a specific region
@params [region_id, zone_name and zone_code]
*/
export const addzoneToregion = async (region, name, code) => {
  code = code.toUpperCase();
  try {
    const regionRef = doc(firestoreDatabase, "regions", region.key);
    let zoneExistsWithId = region && region.zones.find((z) => z.code === code);
    if (zoneExistsWithId) {
      return { success: false, message: "Zone Code already registerd!" };
    }
    await setDoc(
      regionRef,
      { zones: [...region.zones, { name, code, woredas: [] }] },
      { merge: true }
    );
    return { success: true };
  } catch (error) {
    return { success: false, message: "Error on Adding zone!" };
  }
};

/*
Description : delelte region
@params [region_id]
*/
export const deleteregion = async (region_id) => {
  try {
    await deleteDoc(doc(firestoreDatabase, "regions", region_id));
    return true;
  } catch (error) {
    return false;
  }
};

/*
Description : gets all added regions
*/
export const getregions = async () => {
  const querySnapshot = await getDocs(collection(db, "regions"));
  let regions = [];
  querySnapshot.forEach((doc) => {
    regions = [...regions, { ...doc.data(), key: doc.id }];
  });
  return regions;
};

/*
Description : adds new woreda to a specific region and woreda 
@params [region_id, zone_id, woreda_name and woreda_id]
*/
export const addworedaToregion = async (region, zone_code, name, code) => {
  code = code.toUpperCase();
  try {
    let allWoredas = [];
    region.zones.forEach((zone) => {
      allWoredas = [...allWoredas, ...zone.woredas];
    });

    let woredaExists = allWoredas.find((z) => z.code === code);
    if (woredaExists) {
      return { success: false, message: "Woreda Code already registerd!" };
    }

    const regionRef = doc(firestoreDatabase, "regions", region.key);
    let updatedzones =
      region &&
      region.zones &&
      region.zones.map((zone) => {
        if (zone.code === zone_code) {
          // let updatedzone = zone.woredas = [...zone.woredas, { name, code }]
          let updatedzone = {
            ...zone,
            woredas: [...zone.woredas, { name, code }],
          };
          return updatedzone;
        }
        return zone;
      });

    // console.log(updatedzones)
    await setDoc(regionRef, { zones: [...updatedzones] }, { merge: true });
    return { success: true };
  } catch (err) {
    // console.log(err)
    return { success: false, message: "Error addinf woreda!" };
  }
}; /*
Description : edit specific region
@params [xone_name and region_code]
*/
export const editregion = async (id, name, code) => {
  //Todo check if the zone id does not exist before
  try {
    const regionRef = doc(firestoreDatabase, "regions", id);
    await setDoc(regionRef, { code, name }, { merge: true });
    return true;
  } catch (error) {
    return false;
  }
};

export const editzone = async (regionId, zones) => {
  // console.log(regionId, zones)
  // return true
  //Todo check if the zone id does not exist before
  try {
    const regionRef = doc(firestoreDatabase, "regions", regionId);
    await updateDoc(regionRef, { zones });
    return true;
  } catch (error) {
    return false;
  }
};
