import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Menu,
  Dropdown,
  Select,
  Row,
  Col,
  Typography,
  List,
  Collapse,
} from "antd";
import {
  FileAddOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  addworedaToregion,
  addzoneToregion,
  addregion,
  deleteregion,
  editregion,
  getregions,
  editzone,
} from "../controllers/location";
import LoadingComponent from "../components/Loading";
import openNotificationWithIcon from "../notification";
const { Column } = Table;
const { Option } = Select;
// const { DirectoryTree } = Tree

const Locations = () => {
  const [addregionModalVisible, setAddModalVisible] = useState(false);
  const [editWoredaModalVisible, setEditedWoredaModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [addworedaModalVisible, setAddworedaModalVisible] = useState(false);
  const [addzoneModalVisible, setAddzoneModalVisible] = useState(false);
  const [editZoneModalVisible, setEditZoneModalVisible] = useState(false);

  const [selectedzones, setSelectedzones] = useState([]);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [regions, setregions] = useState([]);

  const [loadingregions, setLoadingregions] = useState(true);
  const [loadedregions, setLoadedregions] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const [newregionCode, setNewregionCode] = useState("");
  const [newregionName, setNewregionName] = useState("");

  const [newworedaCode, setNewworedaCode] = useState("");
  const [newworedaName, setNewworedaName] = useState("");

  const [newzoneName, setNewzoneName] = useState("");
  const [newzoneCode, setNewzoneCode] = useState("");

  const [editedregionName, setEditedregionName] = useState("");
  const [editedregionCode, setEditedregionCode] = useState("");

  const [editedZoneName, setEditedZoneName] = useState("");
  const [editedZoneCode, setEditedZoneCode] = useState("");
  const [editedWoredaName, setEditedWoredaName] = useState("");
  const [editedWoredaCode, setEditedWoredaCode] = useState("");

  const [selectedzoneId, setSelectedzoneId] = useState("");

  const [regionToBeEdited, setregionToBeEdited] = useState(null);
  const [zoneToBeEdited, setZoneToBeEdited] = useState(null);
  const [zoneToBeEditedIndex, setZoneToBeEditedIndex] = useState(null);
  const [woredaToBeEditedIndex, setWoredaToBeEditedIndex] = useState(null);
  // const [selectedZonesToBeEdited, setSelectedZonesToBeEdited] = useState([])
  const [selectedregionToAddzone, setSelectedregionToAddzone] = useState(null);
  const [selectedregionToAddworeda, setSelectedregionToAddworeda] =
    useState(null);

  const [addregionFormRef] = Form.useForm();
  const [editRegionFormRef] = Form.useForm();
  const [editZoneFormRef] = Form.useForm();
  const [editWoredaFormRef] = Form.useForm();
  const [addworedaFormRef] = Form.useForm();
  const [addzoneFormRef] = Form.useForm();

  useEffect(() => {
    loadregions();
    return () => {
      // console.log('clean up')
    };
  }, []);

  const loadregions = () => {
    setLoadedregions(false);
    setLoadingregions(true);
    setErrorLoading(false);
    getregions()
      .then((regionsData) => {
        setregions(regionsData);
        setLoadedregions(true);
        setLoadingregions(false);
      })
      .catch((err) => {
        setErrorLoading(true);
        setLoadingregions(false);
      });
  };

  const confirmDeletion = (record) => {
    Modal.confirm({
      title: `Delete`,
      icon: <ExclamationCircleOutlined />,
      content: "Zones and Woredas related to this region will be deleted.",
      onOk: () => {
        deleteregion(record.key)
          .then((val) => {
            if (val) {
              openNotificationWithIcon(
                "success",
                "Deleted!",
                "Deleted region Successfuly!"
              );
              loadregions();
            }
          })
          .catch((err) => {
            openNotificationWithIcon(
              "error",
              "Error!",
              "Error deleting region!"
            );
          });
      },
      onCancel() {},
    });
  };

  const showAddregionModal = () => {
    setAddModalVisible(true);
  };

  const showAddzoneModal = (id) => {
    setSelectedregionToAddzone(id);
    setAddzoneModalVisible(true);
  };

  const showAddworedaModal = (region) => {
    setSelectedregionToAddworeda(region);
    const options = region.zones.map((zone) => {
      return { name: zone.name, value: zone.code };
    });

    setSelectedzones(options);

    setAddworedaModalVisible(true);
  };

  const menu = (region) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          showAddzoneModal(region);
        }}
        key="1"
        icon={<FileAddFilled />}
      >
        Add Zone
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          showAddworedaModal(region);
        }}
        key="2"
        icon={<FileAddFilled />}
      >
        Add Woreda
      </Menu.Item>
    </Menu>
  );

  const showEditModal = (record) => {
    setregionToBeEdited(record);
    editRegionFormRef.setFieldsValue({
      name: record.name,
      code: record.code,
    });
    setEditedregionName(record.name);
    setEditedregionCode(record.code);
    setEditModalVisible(true);
  };

  const showEditZoneModal = (record, region, index) => {
    // console.log('Region ==> ', region)
    setregionToBeEdited(region);
    setZoneToBeEdited(record);
    setZoneToBeEditedIndex(index);
    editZoneFormRef.setFieldsValue({
      name: record.name,
      code: record.code,
    });
    setEditedZoneName(record.name);
    setEditedZoneCode(record.code);
    setEditZoneModalVisible(true);
  };

  const showEditWoredaModal = (woreda, record, region, woredaIndex) => {
    // console.log(woreda);
    // console.log(record);
    // console.log(region);
    // console.log(woredaIndex);
    // console.log("Region ==> ", region);
    setregionToBeEdited(region);
    setZoneToBeEdited(record);
    setZoneToBeEditedIndex(region.zones.indexOf(record));
    setWoredaToBeEditedIndex(record.woredas.indexOf(woreda));
    editWoredaFormRef.setFieldsValue({
      name: woreda.name,
      code: woreda.code,
    });
    setEditedWoredaName(woreda.name);
    setEditedWoredaCode(woreda.code);
    setEditedWoredaModalVisible(true);
  };

  const handleSubmitAddregion = async () => {
    await addregionFormRef.validateFields();
    setConfirmLoading(true);
    let result = await addregion(newregionName, newregionCode);
    if (result.success) {

      setAddModalVisible(false);
      setConfirmLoading(false);
      addregionFormRef.resetFields();
      loadregions();
    } else {
      setConfirmLoading(false);
      openNotificationWithIcon("warning", "Cant't add Region!", result.message);
      // console.log(result.message);
    }
  };

  const handleSubmitAddzone = async () => {
    await addzoneFormRef.validateFields();
    setConfirmLoading(true);
    let response = await addzoneToregion(
      selectedregionToAddzone,
      newzoneName,
      newzoneCode
    );
    if (response.success) {
      setAddzoneModalVisible(false);
      setConfirmLoading(false);
      addzoneFormRef.resetFields(); 
      loadregions();
      openNotificationWithIcon("success", "Added New zone!", "Added New zone!");
    } else {
      setConfirmLoading(false);
      openNotificationWithIcon("warning", "Can't add Zone!", response.message);
    }
  };

  const handleSubmitAddworeda = async () => {
    await addworedaFormRef.validateFields();
    setConfirmLoading(true);
    let response = await addworedaToregion(
      selectedregionToAddworeda,
      selectedzoneId,
      newworedaName,
      newworedaCode
    );
    if (response.success) {
      setAddworedaModalVisible(false);
      setConfirmLoading(false);
      addworedaFormRef.resetFields();
      openNotificationWithIcon(
        "success",
        "Added New woreda!",
        "Added New woreda!"
      );
      loadregions();
    } else {
      setConfirmLoading(false);
      openNotificationWithIcon(
        "warning",
        "Can't add woreda!",
        response.message
      );
    }
  };

  const cancelAddzone = () => {
    addzoneFormRef.resetFields();
    setAddzoneModalVisible(false);
  };

  const cancelAddworeda = () => {
    addworedaFormRef.resetFields();
    setAddworedaModalVisible(false);
  };

  const handleSubmitEditregion = async () => {
    await editRegionFormRef.validateFields();
    setConfirmLoading(true);
    // console.log(regionToBeEdited.key, editedregionName, editedregionCode)
    if (
      regions.some(
        (region) => region.code.toUpperCase() === editedregionCode.toUpperCase()
      ) &&
      regionToBeEdited.code.toUpperCase() !== editedregionCode.toUpperCase()
    ) {
      setConfirmLoading(false);
      openNotificationWithIcon(
        "warning",
        "Cant edit region!",
        "Region with the same code is registered"
      );
      return;
    }
    // return;
    let success = await editregion(
      regionToBeEdited.key,
      editedregionName,
      editedregionCode
    );
    // console.log(success)
    if (success) {
      setEditModalVisible(false);
      setConfirmLoading(false);
      editRegionFormRef.resetFields();
      openNotificationWithIcon(
        "success",
        "Edited!",
        "Edited region Successfuly!"
      );
      loadregions();
    } else {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Error on Editing region. Pleaset try Again!"
      );
    }
  };

  const handleSubmitEditZone = async (zones) => {
    // console.log(zones)
    await editZoneFormRef.validateFields();
    setConfirmLoading(true);
    // console.log(regionToBeEdited)
    // console.log(regionToBeEdited.key, editedregionName, editedregionCode)
    let success = await editzone(regionToBeEdited.key, zones);
    // console.log(success)
    if (success) {
      setEditZoneModalVisible(false);
      setConfirmLoading(false);
      editZoneFormRef.resetFields();
      openNotificationWithIcon(
        "success",
        "Edited!",
        "Edited zone Successfuly!"
      );
      setEditedWoredaModalVisible(false);
      setEditZoneModalVisible(false);
      loadregions();
    } else {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Error on Editing zone. Pleaset try Again!"
      );
    }
  };

  const deleteZone = async (zones, region) => {
    // console.log(zones)
    await editZoneFormRef.validateFields();
    setConfirmLoading(true);
    // console.log(regionToBeEdited)
    // console.log(regionToBeEdited.key, editedregionName, editedregionCode)
    let success = await editzone(region.key, zones);
    // console.log(success)
    if (success) {
      setEditZoneModalVisible(false);
      setConfirmLoading(false);
      editZoneFormRef.resetFields();
      openNotificationWithIcon(
        "success",
        "Deleted!",
        "Deleted zone Successfuly!"
      );
      loadregions();
    } else {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Error on Deleted zone. Pleaset try Again!"
      );
    }
  };

  const deleteWoreda = async (zones, region) => {
    // console.log(zones)
    await editZoneFormRef.validateFields();
    setConfirmLoading(true);
    // console.log(regionToBeEdited)
    // console.log(regionToBeEdited.key, editedregionName, editedregionCode)
    let success = await editzone(region.key, zones);
    // console.log(success)
    if (success) {
      setEditZoneModalVisible(false);
      setConfirmLoading(false);
      editZoneFormRef.resetFields();
      openNotificationWithIcon(
        "success",
        "Deleted!",
        "Deleted woreda Successfuly!"
      );
      loadregions();
    } else {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Error on Deleted woreda. Pleaset try Again!"
      );
    }
  };

  const cancelAddregion = () => {
    addregionFormRef.resetFields();
    setAddModalVisible(false);
  };

  const cancelEditregion = () => {
    // console.log('Clicked cancel button');
    editRegionFormRef.resetFields();
    setEditModalVisible(false);
  };

  const cancelEditWoreda = () => {
    // console.log('Clicked cancel button');
    setZoneToBeEdited(null);
    setZoneToBeEditedIndex(null);
    setWoredaToBeEditedIndex(null);
    editWoredaFormRef.resetFields();
    setEditedWoredaModalVisible(false);
  };

  const cancelEditZone = () => {
    // console.log('Clicked cancel button');
    editZoneFormRef.resetFields();
    setEditZoneModalVisible(false);
    setZoneToBeEdited(null);
    setZoneToBeEditedIndex(null);
  };

  const handleregionNameChangeOnAdd = (e) => {
    setNewregionName(e.target.value);
  };
  const handleregionCodeChangeOnAdd = (e) => {
    setNewregionCode(e.target.value);
  };

  const handleworedaNameChangeOnAdd = (e) => {
    setNewworedaName(e.target.value);
  };
  const handleworedaCodeChangeOnAdd = (e) => {
    setNewworedaCode(e.target.value);
  };

  const handlezonenNameChangedOnAdd = (e) => {
    setNewzoneName(e.target.value);
  };
  const handlezonenCodeChangedOnAdd = (e) => {
    setNewzoneCode(e.target.value);
  };

  const handlezoneIdChangeOnAdd = (e) => {
    setSelectedzoneId(e);
  };

  const handleEditedregionName = (e) => {
    setEditedregionName(e.target.value);
  };
  const handleEditedregionCode = (e) => {
    setEditedregionCode(e.target.value);
  };

  const handleEditedZoneName = (e) => {
    setEditedZoneName(e.target.value);
    // (e.target.value)
  };
  const handleEditedZoneCode = (e) => {
    // setEditedregionCode(e.target.value)
    setEditedZoneCode(e.target.value);
  };

  const handleEditedWoredaName = (e) => {
    setEditedWoredaName(e.target.value);
    // (e.target.value)
  };
  const handleEditedWoredaCode = (e) => {
    // setEditedregionCode(e.target.value)
    setEditedWoredaCode(e.target.value);
  };

  const zonesTestData = (zones) => {
    // console.log(zones)
    return zones.map((zone) => {
      return {
        title: zone.name,
        key: zone.code,
        children: zone.woredas
          ? zone.woredas.map((woreda) => {
              return {
                title: woreda.name,
                key: woreda.code,
                children: [],
              };
            })
          : [],
      };
    });
  };

  const treeData = [
    {
      title: "parent 0",
      key: "0-0",
      children: [
        {
          title: "leaf 0-0",
          key: "0-0-0",
          isLeaf: true,
        },
        {
          title: "leaf 0-1",
          key: "0-0-1",
          isLeaf: true,
        },
      ],
    },
    {
      title: "parent 1",
      key: "0-1",
      children: [
        {
          title: "leaf 1-0",
          key: "0-1-0",
          isLeaf: true,
        },
        {
          title: "leaf 1-1",
          key: "0-1-1",
          isLeaf: true,
        },
      ],
    },
  ];

  return (
    <>
      {errorLoading && <p>Error Loading...</p>}
      {loadingregions && <LoadingComponent />}
      {loadedregions && !loadingregions && (
        <>
          <Button
            onClick={showAddregionModal}
            className="add_btn"
            type="primary"
            size="large"
            shape="round"
            icon={<FileAddOutlined />}
          >
            Add region
          </Button>

          <Table
            dataSource={regions}
            scroll={{ x: 1000, y: 500 }}
            style={{marginRight : 10}}
            expandable={{
              expandedRowRender: (record) => {
                if (record.zones.length > 0) {
                  // let a = []

                  return (
                    <>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          marginLeft: 15,
                          marginBottom: 5,
                        }}
                      >
                        Zones under {record.name} Region
                      </Typography>
                      <Collapse>
                        {record.zones.map((zone, zoneIndex, allZones) => (
                          <Collapse.Panel
                            header={
                              <>
                                <Row
                                  style={{ width: "100%" }}
                                  align="middle"
                                  justify="space-between"
                                >
                                  <Col>
                                    <Typography.Text style={{ fontSize: 12 }}>
                                      {zone.name}
                                    </Typography.Text>
                                  </Col>
                                  <Col>
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <Modal
                                        title="Edit Zone"
                                        open={editZoneModalVisible}
                                        onOk={(e) => {
                                          // console.log(allZones)
                                          let editedZones = allZones.map(
                                            (val) => val
                                          );

                                          if (
                                            record.zones.some(
                                              (zone) =>
                                                zone.code.toUpperCase() ===
                                                editedZoneCode.toUpperCase()
                                            ) &&
                                            zoneToBeEdited.code.toUpperCase() !==
                                              editedZoneCode.toUpperCase()
                                          ) {
                                            setConfirmLoading(false);
                                            openNotificationWithIcon(
                                              "warning",
                                              "Cant edit zone!",
                                              "Zone with the same code is registered"
                                            );
                                            return;
                                          }
                                          //   return;
                                          //   console.log(record);
                                          editedZones[zoneToBeEditedIndex] = {
                                            ...zoneToBeEdited,
                                            name: editedZoneName,
                                            code: editedZoneCode,
                                          };
                                          // console.log(editedZones)
                                          //todo change allzone[currentIndex] to be the edited zone
                                          handleSubmitEditZone(editedZones);
                                        }}
                                        confirmLoading={confirmLoading}
                                        onCancel={
                                          !confirmLoading
                                            ? cancelEditZone
                                            : () => {}
                                        }
                                        closable={!confirmLoading}
                                      >
                                        <Form form={editZoneFormRef}>
                                          <Form.Item
                                            label="Code"
                                            name="code"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please enter zone code!",
                                              },
                                            ]}
                                          >
                                            <Input
                                              onChange={handleEditedZoneCode}
                                            />
                                          </Form.Item>

                                          <Form.Item
                                            label="Name"
                                            name="name"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please enter zone name!",
                                              },
                                            ]}
                                          >
                                            <Input
                                              onChange={handleEditedZoneName}
                                            />
                                          </Form.Item>
                                        </Form>
                                      </Modal>
                                    </div>
                                    <Button
                                      icon={<EditOutlined />}
                                      onClick={(e) => {
                                        // e.stopPropagation();
                                        // console.log(record.zones.indexOf(zone));
                                        e.stopPropagation();
                                        showEditZoneModal(
                                          zone,
                                          record,
                                          record.zones.indexOf(zone)
                                        );
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (
                                          zone.woredas &&
                                          zone.woredas.length !== 0
                                        ) {
                                          openNotificationWithIcon(
                                            "warning",
                                            "Can't Delete!",
                                            "There are woredas under this zone."
                                          );
                                          return;
                                        }

                                        Modal.confirm({
                                          title: `Delete`,
                                          icon: <ExclamationCircleOutlined />,
                                          content:
                                            "Woredas related to this zone will be deleted.",
                                          onOk: () => {
                                            let editedZones = allZones.filter(
                                              (val) => val.code !== zone.code
                                            );
                                            deleteZone(editedZones, record);
                                          },
                                          onCancel() {},
                                        });
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            }
                            key={zone.code}
                          >
                            <>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 15,
                                  marginLeft: 15,
                                  marginBottom: 5,
                                }}
                              >
                                Woredas under {zone.name} Zone
                              </Typography>

                              <List
                                bordered
                                dataSource={zone.woredas}
                                renderItem={(woreda, woredaIndex) => (
                                  <Row align="middle" justify="space-between">
                                    <Col>
                                      <List.Item>
                                        <Typography.Text
                                          style={{ fontSize: 12 }}
                                        >
                                          {woreda.name}
                                        </Typography.Text>
                                      </List.Item>
                                    </Col>
                                    <Col>
                                      <div onClick={(e) => e.stopPropagation()}>
                                        <Modal
                                          title="Edit Woreda"
                                          open={editWoredaModalVisible}
                                          onOk={(e) => {
                                            // console.log(allZones)
                                            let editedZones = allZones.map(
                                              (val) => val
                                            );

                                            // console.log(
                                            //   editedWoredaCode.toUpperCase()
                                            // );
                                            // return;

                                            if (
                                              editedZones[
                                                zoneToBeEditedIndex
                                              ].woredas.some(
                                                (woreda) =>
                                                  woreda.code.toUpperCase() ===
                                                  editedWoredaCode.toUpperCase()
                                              ) &&
                                              editedZones[
                                                zoneToBeEditedIndex
                                              ].woredas[
                                                woredaToBeEditedIndex
                                              ].code.toUpperCase() !==
                                                editedWoredaCode.toUpperCase()
                                            ) {
                                              setConfirmLoading(false);
                                              openNotificationWithIcon(
                                                "warning",
                                                "Cant edit woreda!",
                                                "Woreda with the same code is registered"
                                              );
                                              return;
                                            }

                                            editedZones[
                                              zoneToBeEditedIndex
                                            ].woredas[woredaToBeEditedIndex] = {
                                              ...zone.woredas[
                                                woredaToBeEditedIndex
                                              ],
                                              name: editedWoredaName,
                                              code: editedWoredaCode,
                                            };
                                            handleSubmitEditZone(editedZones);
                                          }}
                                          confirmLoading={confirmLoading}
                                          onCancel={
                                            !confirmLoading
                                              ? cancelEditWoreda
                                              : () => {}
                                          }
                                          closable={!confirmLoading}
                                        >
                                          <Form form={editWoredaFormRef}>
                                            <Form.Item
                                              label="Code"
                                              name="code"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please enter woreda code!",
                                                },
                                              ]}
                                            >
                                              <Input
                                                onChange={
                                                  handleEditedWoredaCode
                                                }
                                              />
                                            </Form.Item>

                                            <Form.Item
                                              label="Name"
                                              name="name"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please enter woreda name!",
                                                },
                                              ]}
                                            >
                                              <Input
                                                onChange={
                                                  handleEditedWoredaName
                                                }
                                              />
                                            </Form.Item>
                                          </Form>
                                        </Modal>
                                      </div>

                                      <Button
                                        icon={<EditOutlined />}
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          showEditWoredaModal(
                                            woreda,
                                            zone,
                                            record,
                                            zone.woredas.indexOf(woreda)
                                          );
                                        }}
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        icon={<DeleteOutlined />}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          Modal.confirm({
                                            title: `Delete`,
                                            icon: <ExclamationCircleOutlined />,
                                            content:
                                              "Are you sure you want to delete this woreda?",
                                            onOk: () => {
                                              // let editedZones = allZones.filter(val => val.code !== zone.code)
                                              // deleteZone(editedZones, record)

                                              let editedZones = allZones.map(
                                                (val) => val
                                              );
                                              editedZones[zoneIndex].woredas =
                                                editedZones[
                                                  zoneIndex
                                                ].woredas.filter(
                                                  (wor) =>
                                                    wor.code !== woreda.code
                                                );
                                              deleteWoreda(editedZones, record);
                                            },
                                            onCancel() {},
                                          });
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </Col>
                                  </Row>
                                )}
                              />
                            </>
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    </>
                  );
                } else {
                  return <p>No zones are added in this region...</p>;
                }

                // return <DirectoryTree
                //     multiple
                //     defaultExpandAll={false}
                //     onSelect={() => { console.log('selected') }}
                //     onExpand={() => { console.log('selected') }}
                //     selectable={false}
                //     children={<Typography>Please</Typography>}
                //     treeData={
                //         record.zones.map(zone => {
                //             return {
                //                 title: zone.name,
                //                 key: zone.code,
                //                 children: zone.woredas ? zone.woredas.map(woreda => {
                //                     return {
                //                         title: woreda.name,
                //                         key: woreda.code,
                //                         children: []
                //                     }
                //                 }) : []
                //             }
                //         })
                //     }
                // />
              },
            }}
            bordered
          >
            <Column
              title="Code"
              dataIndex="code"
              key="key"
              render={(code) => {
                return <Typography style={{ fontSize: 12 }}>{code}</Typography>;
              }}
            />
            <Column
              title="Name"
              dataIndex="name"
              sorter= {(a, b) => a.name.localeCompare(b.name)}
              defaultSortOrder={'ascend'}
              key="key"
              render={(regionName) => {
                return <Typography style={{ fontSize: 12 }}>{regionName}</Typography>;
              }}
            />
            <Column
              title="Action"
              key="key"
              render={(val, record, index) => (
                <Space size="middle">
                  <Button
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      if (record.zones && record.zones.length !== 0) {
                        openNotificationWithIcon(
                          "warning",
                          "Can't Delete!",
                          "There are zones under this region."
                        );
                        return;
                      }
                      confirmDeletion(record);
                    }}
                    type="primary"
                    shape="round"
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                  >
                    Delete
                  </Button>

                  <Button
                    style={{ fontSize: 12 }}
                    onClick={() => showEditModal(record)}
                    type="primary"
                    shape="round"
                    size="small"
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Button>

                  <Dropdown.Button
                    style={{ fontSize: 12 }}
                    type="primary"
                    size="small"
                    overlay={menu(record)}
                  >
                    More
                  </Dropdown.Button>
                </Space>
              )}
            />
          </Table>

          <Modal
            title="Add region"
            open={addregionModalVisible}
            onOk={handleSubmitAddregion}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? cancelAddregion : () => {}}
            closable={!confirmLoading}
          >
            <Form form={addregionFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Code"
                name="code"
                rules={[
                  { required: true, message: "Please enter region code!" },
                ]}
              >
                <Input
                  value={newregionCode}
                  onChange={handleregionCodeChangeOnAdd}
                />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter region name!" },
                ]}
              >
                <Input
                  value={newregionName}
                  onChange={handleregionNameChangeOnAdd}
                />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Edit Region"
            open={editModalVisible}
            onOk={handleSubmitEditregion}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? cancelEditregion : () => {}}
            closable={!confirmLoading}
          >
            <Form form={editRegionFormRef}>
              <Form.Item
                label="Code"
                name="code"
                rules={[
                  { required: true, message: "Please enter region code!" },
                ]}
              >
                <Input onChange={handleEditedregionCode} />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter region name!" },
                ]}
              >
                <Input onChange={handleEditedregionName} />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Add zone"
            open={addzoneModalVisible}
            onOk={handleSubmitAddzone}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? cancelAddzone : () => {}}
            closable={!confirmLoading}
          >
            <Form form={addzoneFormRef} colon={false} layout="vertical">
              <Form.Item
                label="Code"
                name="code"
                rules={[
                  { required: true, message: "Please enter region code!" },
                ]}
              >
                <Input onChange={handlezonenCodeChangedOnAdd} />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter region name!" },
                ]}
              >
                <Input onChange={handlezonenNameChangedOnAdd} />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Add woreda"
            open={addworedaModalVisible}
            onOk={handleSubmitAddworeda}
            confirmLoading={confirmLoading}
            onCancel={!confirmLoading ? cancelAddworeda : () => {}}
            closable={!confirmLoading}
          >
            <Form form={addworedaFormRef} colon={false} layout="vertical">
              <Form.Item
                label="zone"
                name="zone"
                rules={[{ required: true, message: "Please select zone!" }]}
              >
                <Select
                  style={{ width: 150 }}
                  onChange={handlezoneIdChangeOnAdd}
                >
                  {selectedzones.map((zone) => {
                    return <Option value={zone.value}>{zone.name}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Code"
                name="code"
                rules={[
                  { required: true, message: "Please enter woreda code!" },
                ]}
              >
                <Input onChange={handleworedaCodeChangeOnAdd} />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter woreda name!" },
                ]}
              >
                <Input onChange={handleworedaNameChangeOnAdd} />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};
export default Locations;
