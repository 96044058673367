import { collection, getDocs, query, where } from "firebase/firestore";
import { getTimeDifference } from "../config/getMinuteDifference";
import { auth, firestoreDatabase } from "../firebase";

export const numberOfSpecimentReferredTester = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Waiting for Confirmation',
        'Confirmed',
        'Picked Up',
        'Arrived',
        'Confirmed',
        'Received',
        'Delivered',
        'Accepted',
        'Inspected',
        'Delivered',
    ]), where('tester_id', '==', await getTestCenterByAdminUID()));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimensCount = patient.specimens != null ? patient.specimens.length : 0
            count += specimensCount
        })
    })

    return count
}

export const numberOfSpecimenConfirmedTester = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Picked Up',
        'Confirmed',
        'Arrived',
        'Confirmed',
        'Received',
        'Delivered',
        'Tested',
        'Accepted',
        'Inspected',
        'Delivered',
    ]), where('user_id', '==', await getTestCenterByAdminUID()));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimensCount = patient.specimens != null ? patient.specimens.length : 0
            count += specimensCount
        })
    })

    return count
}

export const convertToMMHH = (MINUTES) => {

    let m = MINUTES % 60;

    let h = (MINUTES - m) / 60;

    m = Math.floor(m)
    h = Math.floor(h)

    return h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
}

export const numberOfSpecimentReceivedTester = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Received',
        'Delivered',
        'Tested',
        'Accepted',
        'Inspected',
        'Delivered',
    ]), where('user_id', '==', await getTestCenterByAdminUID()));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimensCount = patient.specimens != null ? patient.specimens.length : 0
            count += specimensCount
        })
    })

    return count
}

export const numberOfSpecimensTestedTester = async () => {
    // let orders = []
    const q = query(collection(firestoreDatabase, "orders"), where("status", "in", [
        'Received',
        'Delivered',
        'Tested',
        'Accepted',
        'Inspected',
        'Delivered',
    ]), where('user_id', '==', await getTestCenterByAdminUID()));

    const querySnapshot = await getDocs(q);
    let count = 0
    querySnapshot.forEach(doc => {
        let data = doc.data()
        let patients = data['patients'] || []
        patients.forEach(patient => {
            let specimens = patient.specimens || []
            specimens.forEach(specimen => {
                if (specimen['result']) {
                    count += 1
                }
            })
        })
    })

    return count
}



// const getAgeCategory = (data) => {
//     if (data['age'] >= 0 && data['age'] < 6) {
//         return '0-6'
//     }
//     else if (data['age'] >= 6 && data['age'] < 12) {
//         return '6-12'
//     }
//     else if (data['age'] >= 12 && data['age'] < 24) {
//         return '12-24'
//     }
//     else if (data['age'] >= 24 && data['age'] < 48) {
//         return '24-48'
//     }
//     else if (data['age'] >= 48) {
//         return '24-48'
//     }
//     return ''
// }


export const overallData = (data) => {

    if (data) {
        let maleSputum = data.filter(d => d['sex'] === 'Male' && d['type'] === 'Sputum').length
        let femaleSputum = data.filter(d => d['sex'] === 'Female' && d['type'] === 'Sputum').length

        let maleStool = data.filter(d => d['sex'] === 'Male' && d['type'] === 'Stool').length
        let femaleStool = data.filter(d => d['sex'] === 'Female' && d['type'] === 'Stool').length

        var mtbNotDetected = 0

        var rifResDetected = 0
        var rifiResNotDetected = 0

        var rifResIndeterminate = 0

        let stoolUnder15 = data.filter(d => d['age'] <= 15 && d['type'] === 'Stool' && d['status'] == 'Tested').length
        let stoolAbove15 = data.filter(d => d['age'] > 15 && d['type'] === 'Stool' && d['status'] == 'Tested').length
        let sputumUnder15 = data.filter(d => d['age'] <= 15 && d['type'] === 'Sputum' && d['status'] == 'Tested').length
        let sputumAbove15 = data.filter(d => d['age'] > 15 && d['type'] === 'Sputum' && d['status'] == 'Tested').length
        let urineUnder15 = data.filter(d => d['age'] <= 15 && d['type'] === 'Urine' && d['status'] == 'Tested').length
        let urineAbove15 = data.filter(d => d['age'] > 15 && d['type'] === 'Urine' && d['status'] == 'Tested').length
        let bloodUnder15 = data.filter(d => d['age'] <= 15 && d['type'] === 'Blood' && d['status'] == 'Tested').length
        let bloodAbove15 = data.filter(d => d['age'] > 15 && d['type'] === 'Blood' && d['status'] == 'Tested').length

        // let zeroToSixStool = data.filter(d => d['age'] > 15 && d['type'] === 'Blood').length

        let zeroToSix = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }
        let sixToTwelve = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }
        let twelveToTwentyFour = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }
        let twentyFourToFourtyEight = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }
        let aboveFourtyEight = { 'Sputum': 0, 'Stool': 0, 'Blood': 0, 'Urine': 0 }

        const getTimeCategory = (val) => {
            if (val >= 0 && val < 6) {
                return zeroToSix
            }
            else if (val >= 6 && val < 12) {
                return sixToTwelve
            }
            else if (val >= 12 && val < 24) {
                return twelveToTwentyFour
            }
            else if (val >= 24 && val < 48) {
                return twentyFourToFourtyEight
            }
            else if (data['age'] >= 48) {
                return aboveFourtyEight
            }
            return {}
        }

        data.forEach(d => {
            // console.log('Test Result Added At', d['testResultAddedAt'])
            let specimenCreated = d['order_created'].toDate()
            if (d['testResultAddedAt']) {
                let specimenTested = new Date(d['testResultAddedAt'])
                let turnArundTime = getTimeDifference(specimenCreated, specimenTested)
                getTimeCategory(turnArundTime)[d['type']] += 1
            }
        })


        let results = []

        data.forEach(ordersData => {
            results = [...results, ...ordersData['specimens']]
        })

        results = [...new Set(results)]
        results = results.map(s => s['result']).filter(val => val)

        results.forEach(result => {

            switch (result['mtb_result']) {
                case 'MTB Detected':
                    if (result['result_rr'] === 'Rif Res Detected') {
                        // console.log(1)
                        rifResDetected += 1
                    }

                    else if (result['result_rr'] === 'Rif Res Not Detected') {
                        rifiResNotDetected += 1
                        // console.log(1)
                    }



                    else if (result['result_rr'] === 'Rif Res Indeterminate') {
                        rifResIndeterminate += 1
                        // console.log(1)
                    }
                    break

                case 'MTB Not Detected':
                    mtbNotDetected += 1
                    break
                default:
                    break
            }
        })

        return {
            maleSputum, femaleSputum, maleStool, femaleStool,
            mtbNotDetected,
            rifResDetected,
            rifiResNotDetected,
            rifResIndeterminate,
            numberOfSpecimens: results.length,

            stoolUnder15, stoolAbove15, sputumUnder15, sputumAbove15, urineAbove15, urineUnder15, bloodUnder15, bloodAbove15,
            zeroToSix, sixToTwelve, twelveToTwentyFour, twentyFourToFourtyEight: twentyFourToFourtyEight, aboveFourtyEight
        }
    }
}

const getTestCenterByAdminUID = async () => {
    let id = (auth.currentUser && auth.currentUser.uid) || ''
    const q = query(collection(firestoreDatabase, "users"), where("user_id", "==", id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
        let testCenterId = querySnapshot.docs && querySnapshot.docs[0] && querySnapshot.docs[0].data() && querySnapshot.docs[0].data()
        return testCenterId
    }
    return null
}
